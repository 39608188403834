import styled from 'styled-components';

const LinkSpan = styled.span`
  color: #fca92a;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;

  :hover {
    color: #fafafa;
  }
`;

const EmailInvalidToast = ({ goToLogin }: { goToLogin: () => void }) => (
  <div>
    Sorry, this email is not available. Try{' '}
    <LinkSpan onClick={goToLogin}>logging in</LinkSpan> instead.
  </div>
);

export default EmailInvalidToast;
