import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import FullScreenLoader from '../components/Loader/FullScreenLoader';
import useAuth from '../hooks/use-auth';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default function AuthCallback() {
  const { handleAuthCallback, getReturnToFromResult, isAuthenticated } =
    useAuth();
  const history = useHistory();

  const finishLogin = React.useCallback(async () => {
    if (isAuthenticated) {
      history.push('/');
      return;
    }

    try {
      const result = await handleAuthCallback();
      const returnTo = getReturnToFromResult(result);
      history.push(returnTo);
    } catch (error) {
      toast.error("Sorry, we can't find your account.");
      history.push('/auth');
      console.error(error);
    }
  }, [handleAuthCallback, history, isAuthenticated, getReturnToFromResult]);

  React.useEffect(() => {
    finishLogin();
  }, [finishLogin]);

  return (
    <Wrapper>
      <FullScreenLoader />
    </Wrapper>
  );
}
