import React from 'react';
import {
  ACTIVITY_ACTION,
  ActivityContext,
  ActivityContextQuestionAnswer,
  ActivityProvider,
} from '../../contexts/activity';
import { ActivityPayload, ActivitySubmission } from '../../hydra';
import { getActivityPageTitle } from '../../routing';
import Navbar, { NAVBAR_HEIGHT } from '../Navbar';
import PageWithAsyncData from '../PageWithAsyncData';
import ActivityNavigation from './SideNavigation';
import ActivityContent, { ACTIVITY_STEP } from './Content';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import autoAnimate from '@formkit/auto-animate';
import { AIWorkflow } from '../../contexts/ai-workflow';
import AIChat from '../AIChat';
import ActionBar from './BottomActionBar';
import { useBeforeUnload } from 'react-use';
import { Prompt } from 'react-router-dom';
import useActivityDetector from '../../hooks/use-activity-detector';
import { toast } from 'react-toastify';
import parseSubmission from '../../contexts/activity/helpers/parse-submission-payload';

export const ACTIVITY_PAGE_TYPE = {
  PREVIEW: 'PREVIEW',
  PROSUMER: 'PROSUMER',
  TEAMS: 'TEAMS',
};

export type ActivityPageProps = {
  activityState: {
    loading: boolean;
    error: Error | unknown | null;
    value: ActivityPayload | null | undefined;
    retry: () => void;
  };
  isLearnModeProsumer?: boolean;
  dashboardUrl: string;
  startDiscussion: ({
    questionText,
    contentText,
  }: {
    questionText: string;
    contentText: string;
  }) => Promise<void>;
  saveSubmission: (sub: ActivitySubmission) => Promise<void>;
  goToNextActivity: () => void;
  getActivity: () => Promise<ActivityPayload>;
  type: typeof ACTIVITY_PAGE_TYPE[keyof typeof ACTIVITY_PAGE_TYPE];
  createAIReview: (data: {
    activitySlug: string;
    submissionId: number;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  }) => Promise<void>;
};

const StyledAIChat = styled(AIChat)`
  height: calc(100vh - 2 * ${NAVBAR_HEIGHT}px - 32px);
  bottom: calc(${NAVBAR_HEIGHT}px + 16px);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
`;

const PageWrapper = styled(PageWithAsyncData)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  @media screen and (max-width: 980px) {
    min-width: 980px;
  }
`;

const StyledNavbar = styled(Navbar)`
  position: static;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: calc(100vh - 2 * ${NAVBAR_HEIGHT}px);
`;

export default function ActivityPage(props: ActivityPageProps) {
  const { activityState } = props;
  const { title } = activityState?.value ?? {};

  const { hasAIAccess } = React.useContext(AIWorkflow);
  const activity = activityState.value;
  const breadCrumbs = [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: activityState.value?.content_title || 'Module',
      url: props.dashboardUrl,
    },
    {
      label: activityState.value?.title || 'Activity',
      url: null,
    },
  ];

  return (
    <PageWrapper
      isLoading={activityState.loading}
      error={activityState.error}
      retry={activityState.retry}
      className={''}
    >
      {activity && (
        <>
          <Helmet>
            <title>{getActivityPageTitle(title ?? '')}</title>
          </Helmet>
          <ActivityProvider activity={activity}>
            <StyledNavbar
              isPreview={activity.type === ACTIVITY_PAGE_TYPE.PREVIEW}
              breadCrumbs={breadCrumbs}
            />
            {hasAIAccess && activity && (
              <div>
                <StyledAIChat
                  contentId={activity.content_id}
                  missionId={activity.mission_id}
                  activityId={activity.id}
                  projectId={null}
                  discussionContext={'activity'}
                  shouldShowChatIcon={false}
                />
              </div>
            )}
            <ActivityLayout {...props} />
          </ActivityProvider>
        </>
      )}
    </PageWrapper>
  );
}

const ToggleButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 64px;
  z-index: 1;
`;
const ToggleButton = styled.i.attrs({
  className: 'fa-solid fa-chevrons-right',
})`
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 16px;
  padding: 24px;
  transition: all 300ms ease-in-out;
  position: sticky;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.textPrimary};
  }
`;

export function ActivityLayout({
  type,
  goToNextActivity,
  saveSubmission,
  getActivity,
  startDiscussion,
  createAIReview,
}: ActivityPageProps) {
  const parent = React.useRef(null);
  const [activity, dispatch] = React.useContext(ActivityContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const isIdle = useActivityDetector();

  React.useEffect(() => {
    dispatch({
      type: ACTIVITY_ACTION.RECORD_VISIBILITY_CHANGE,
      payload: {
        isIdle,
      },
    });
  }, [dispatch, isIdle]);

  const [isNavigationOpen, setIsNavigationOpen] = React.useState(true);
  const openNavigation = () => setIsNavigationOpen(true);
  const closeNavigation = () => setIsNavigationOpen(false);

  React.useEffect(() => {
    parent.current &&
      autoAnimate(parent.current, {
        duration: 500,
        easing: 'ease-in-out',
      });
  }, [parent]);

  useBeforeUnload(
    activity.hasUnsavedWork,
    'You have unsaved answers! Are you sure you want to leave and lose your work?'
  );

  const refreshSubmission = async () => {
    try {
      const activity = await getActivity();
      dispatch({
        type: ACTIVITY_ACTION.REFRESH_SUBMISSION,
        payload: {
          latestSubmission: activity.latest_submission,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleSaveSubmission = async () => {
    if (!activity.hasUnsavedWork) {
      return;
    }
    setIsSubmitting(true);
    await saveSubmission(parseSubmission(activity));
    await refreshSubmission();
    setIsSubmitting(false);
  };

  const handleSaveSubmissionAndContinue = async () => {
    if (activity.hasUnsavedWork) {
      setIsSubmitting(true);
      await saveSubmission(parseSubmission(activity));
      await refreshSubmission();
      setIsSubmitting(false);
    }
    goToNextActivity();
  };

  const submitAndMoveToReview = async () => {
    try {
      await handleSaveSubmission();
      return dispatch({
        type: ACTIVITY_ACTION.SET_PAGE_STEP,
        payload: {
          step: {
            type: ACTIVITY_STEP.FEEDBACK,
          },
        },
      });
    } catch (e) {
      console.error(e);
      toast.error("Sorry, we couldn't save your answers");
      setIsSubmitting(false);
    }
  };

  const submitAndMoveToQuestion = async ({
    questionIndex,
    questionSlug,
  }: {
    questionIndex: number;
    questionSlug: string;
  }) => {
    try {
      await handleSaveSubmission();
      return dispatch({
        type: ACTIVITY_ACTION.SET_PAGE_STEP,
        payload: {
          step: {
            type: ACTIVITY_STEP.QUESTION,
            questionIndex,
            questionSlug,
          },
        },
      });
    } catch (e) {
      console.error(e);
      toast.error("Sorry, we couldn't save your answers");
      setIsSubmitting(false);
    }
  };

  const submitAndMoveToNextLesson = async () => {
    try {
      handleSaveSubmissionAndContinue();
    } catch (e) {
      console.error(e);
      toast.error("Sorry, we couldn't save your answers");
      setIsSubmitting(false);
    }
  };

  const submitAndMoveToLesson = async () => {
    try {
      await handleSaveSubmission();
      return dispatch({
        type: ACTIVITY_ACTION.SET_PAGE_STEP,
        payload: {
          step: {
            type: ACTIVITY_STEP.LESSON,
          },
        },
      });
    } catch (e) {
      console.error(e);
      toast.error("Sorry, we couldn't save your answers");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Prompt
        when={activity.hasUnsavedWork}
        message="You have unsaved answers! Are you sure you want to leave and lose your work?"
      />

      <ContentWrapper ref={parent}>
        {isNavigationOpen ? (
          <ActivityNavigation
            onClose={closeNavigation}
            submitAndMoveToQuestion={submitAndMoveToQuestion}
            submitAndMoveToReview={submitAndMoveToReview}
            submitAndMoveToLesson={submitAndMoveToLesson}
            isSubmitting={isSubmitting}
          />
        ) : (
          <ToggleButtonWrapper>
            <ToggleButton onClick={openNavigation} />
          </ToggleButtonWrapper>
        )}
        <ActivityContent
          isNavigationOpen={isNavigationOpen}
          getActivity={getActivity}
          createAIReview={createAIReview}
          activityType={type}
        />
      </ContentWrapper>
      <ActionBar
        type={type}
        startDiscussion={startDiscussion}
        submitAndMoveToReview={submitAndMoveToReview}
        submitAndMoveToQuestion={submitAndMoveToQuestion}
        submitAndMoveToNextLesson={submitAndMoveToNextLesson}
        submitAndMoveToLesson={submitAndMoveToLesson}
        isSubmitting={isSubmitting}
      />
    </>
  );
}
