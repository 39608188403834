import styled, { useTheme } from 'styled-components';
import Button from '../components/elements/Button';
const ButtonOverlayWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1;
  button > span {
    text-align: left;
  }
`;
export default function PlaygroundPromoBtn() {
  const theme = useTheme();

  return (
    <ButtonOverlayWrapper>
      <Button
        label="Learn to code 10x faster with Enki AI. Join now!"
        color={theme.colors.secondary}
        type="primary"
        backgroundColor={theme.colors.bgPage}
        onClick={() => {
          window.open('https://learn.enki.com/join', '_blank');
        }}
        faIcon="fa-duotone fa-solid fa-code"
        showIconBeforeLabel
        style={{ textWrap: 'wrap' }}
      />
    </ButtonOverlayWrapper>
  );
}
