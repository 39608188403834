import { SelectOption, ColumnQuestionWrapper, OptionsWrapper } from './common';
import { H3, P } from '../../elements/Text';
import Button from '../../elements/Button';

export default function SingleSelectQuestion({
  question: { id, title, subtitle, answer, isAnswered, options },
  index,
  onNext,
  onAnswerQuestion,
  isLastQuestion,
}) {
  const toggleItem = ({ itemIndex }) => {
    onAnswerQuestion({
      index,
      answer: answer.map((item) => {
        if (item.index === itemIndex) {
          return {
            index: item.index,
            option: item.option,
            isSelected: true,
          };
        }
        return {
          ...item,
          isSelected: false,
        };
      }),
    });
    onNext();
  };
  return (
    <ColumnQuestionWrapper key={id}>
      <H3>{title}</H3>
      {subtitle && <P>{subtitle}</P>}
      <OptionsWrapper>
        {options.map((option, index) => {
          const step = answer.find((option) => option.index === index);

          return (
            <SelectOption
              key={`single-select-${option}`}
              isSelected={step.isSelected}
              index={index}
              onClick={() => toggleItem({ itemIndex: index })}
            >
              {option}
            </SelectOption>
          );
        })}
      </OptionsWrapper>
      <Button
        style={{ width: 'fit-content' }}
        label={isLastQuestion ? 'Submit' : 'Next'}
        isDisabled={!isAnswered}
        onClick={onNext}
        faIcon={isLastQuestion ? 'fas fa-check' : 'fas fa-arrow-right'}
      />
    </ColumnQuestionWrapper>
  );
}
