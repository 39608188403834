import Button from '../elements/Button';
import styled, { useTheme } from 'styled-components';

const StyledButton = styled(Button)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 2;

  border-radius: 50%;
  height: 80px;
  width: 80px;

  i {
    font-size: 30px;
  }
`;

export default function FloatingButton({ onOpen }) {
  const theme = useTheme();
  return (
    <StyledButton
      type="secondary"
      onClick={onOpen}
      faIcon={'fa-duotone fa-user-astronaut'}
      color={theme.colors.adasda}
      backgroundColor={theme.colors.bgPage}
    />
  );
}
