import React from 'react';
import isEmpty from 'lodash.isempty';

export default function useSQLRunner({ id, setupSQL }) {
  const dbInstanceRef = React.useRef(null);

  const [isReady, setIsReady] = React.useState(false);
  const [isRunning, setIsRunning] = React.useState(false);
  const [schemas, setSchemas] = React.useState([]);
  const [error, setError] = React.useState(null);

  const runQuery = React.useCallback((query) => {
    if (!dbInstanceRef.current) {
      console.error(`Cannot run a query on DB because DB does not exist`);
      return;
    }

    setIsRunning(true);

    try {
      const queryResult = dbInstanceRef.current.exec(query);
      setSchemas(extractSchemas(dbInstanceRef.current));
      setIsRunning(false);

      return { type: 'QUERY', value: queryResult };
    } catch (err) {
      setSchemas(extractSchemas(dbInstanceRef.current));
      setIsRunning(false);
      return { type: 'ERROR', value: err.message };
    }
  }, []);

  const closeDb = React.useCallback(() => {
    dbInstanceRef.current.close();
    dbInstanceRef.current = null;
  }, []);

  const initDb = React.useCallback(() => {
    try {
      dbInstanceRef.current = new window.SQL.Database();
      if (setupSQL) {
        dbInstanceRef.current.exec(setupSQL);
      }
      setIsReady(true);
      setSchemas(extractSchemas(dbInstanceRef.current));
    } catch (err) {
      setError(err);
      setIsReady(true);
      setSchemas([]);
    }
  }, [setupSQL]);

  const resetDb = React.useCallback(() => {
    closeDb();
    initDb();
  }, [closeDb, initDb]);

  React.useEffect(() => {
    setIsReady(false);
    if (dbInstanceRef.current) {
      closeDb();
    }
    initDb();
  }, [id, initDb, closeDb]);

  return {
    isLoading: !isReady,
    isRunning,
    runQuery,
    resetDb,
    schemas,
    error,
  };
}

export function extractSchemas(instance) {
  const tables = instance.exec(
    `SELECT * FROM sqlite_master WHERE type="table"`
  );

  if (isEmpty(tables)) {
    return [];
  }

  const [{ columns, values }] = tables;
  const indexOfTableName = columns.indexOf('name');

  const tableNamesToSkip = ['sqlite_sequence'];

  return values
    .map((row) => row[indexOfTableName])
    .filter((tableName) => !tableNamesToSkip.includes(tableName))
    .map((tableName) => {
      const [schema = { columns: [], values: [] }] = instance.exec(
        `PRAGMA table_info("${tableName}")`
      );
      return { name: tableName, schema };
    });
}
