import { Location } from 'history';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function useHistoryListener({
  onBack,
  onForward,
}: {
  onBack?: () => void;
  onForward?: () => void;
}) {
  const history = useHistory();

  const [locationKeys, setLocationKeys] = React.useState<string[]>([]);

  const onHistoryChange = React.useCallback(
    (location: Location) => {
      if (!location.key) {
        return;
      }

      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys?.[1] === location.key) {
          setLocationKeys(([, ...keys]) => keys);
          if (onForward) {
            onForward();
          }
        } else {
          setLocationKeys((keys) => [location.key || '', ...keys]);
          if (onBack) {
            onBack();
          }
        }
      }
    },
    [history.action, locationKeys, onBack, onForward]
  );

  React.useEffect(() => {
    const unlisten = history.listen(onHistoryChange);

    return () => {
      unlisten();
    };
  }, [history, onHistoryChange]);

  return null;
}
