import { useParams } from 'react-router-dom';
import ActivityPage, {
  ACTIVITY_PAGE_TYPE,
} from '../../components/ActivityPage';
import { usePreviewActivity } from '../../hooks/use-hydra';
import { toast } from 'react-toastify';
import { getPreviewDashboardUrl } from '../../routing';
import { getPreviewActivity } from '../../hydra';
import useAuth from '../../hooks/use-auth';

export default function Activity() {
  const { getTokenSilently } = useAuth();
  const { missionSlug, activitySlug } =
    useParams<{
      missionSlug: string;
      activitySlug: string;
    }>();

  const activityState = usePreviewActivity(missionSlug, activitySlug);

  const startDiscussion = async () => {
    toast.warn('PREVIEW MODE - skipped starting the discussion');
  };

  const noopSubmit = async () => {
    toast.warn('PREVIEW MODE - skipped saving the submission');
  };

  const noopGoToNextActivity = async () => {
    toast.warn('PREVIEW MODE - skipped going to the next activity');
  };

  const getActivity = async () => {
    const token = await getTokenSilently();
    return getPreviewActivity(token, {
      missionSlug,
      activitySlug,
    });
  };

  const createAIReview = async () => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  };

  return (
    <ActivityPage
      activityState={activityState}
      startDiscussion={startDiscussion}
      goToNextActivity={noopGoToNextActivity}
      saveSubmission={noopSubmit}
      dashboardUrl={getPreviewDashboardUrl(
        activityState?.value?.content_slug ?? ''
      )}
      type={ACTIVITY_PAGE_TYPE.PREVIEW}
      getActivity={getActivity}
      createAIReview={createAIReview}
    />
  );
}
