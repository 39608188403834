import { useActor } from '@xstate/react';
import Loader from '../../Loader';
import SoloOnboardingContext from '../context';
import React from 'react';
import * as Analytics from '../../../analytics';
import { CenteredContainer } from './common';

export default function AuthCallback() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_SIGNUP_CALLBACK,
    state.context
  );

  return (
    <CenteredContainer>
      <Loader />
    </CenteredContainer>
  );
}
