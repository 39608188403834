import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { mixColors } from '../../utils';

const ModalUnderlay = styled.div`
  height: 100%;
  width: 100%;
  z-index: 3; /* This is because our AI Chat widget has z-index 2 */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
`;

const ModalWrapper = styled.div`
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
  padding: 0 32px;
  background-color: ${(props) =>
    mixColors(props.theme.colors.bgPage, props.theme.colors.white, 0.1)};
  /* border: 1px solid ${prop('theme.colors.sectionBorder')}; */
  border-radius: ${prop('theme.dimensions.sectionBorderRadius')};
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 3em;

  z-index: 3;
`;

const AnimWrapper = styled.div`
  height: 100%;
  width: 100%;
  animation: fadeIn 1s ease-in-out forwards;
`;

const ContentWrapper = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  // display: inline-block;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FadeInWrapper = ({ children }) => {
  const [animClass, setAnimClass] = React.useState('fade-in');

  React.useEffect(() => {
    setAnimClass('fade-in');
    return () => {
      setAnimClass('fade-out');
    };
  }, []);

  return (
    <AnimWrapper>
      <ContentWrapper className={animClass}>{children}</ContentWrapper>
    </AnimWrapper>
  );
};

const modalRoot = document.getElementById('root');

const Modal = ({ children, style, className = '' }) => {
  const modalMountRef = React.useRef(null);

  if (!modalMountRef.current) {
    modalMountRef.current = document.createElement('div');
  }

  React.useEffect(() => {
    if (modalRoot && modalMountRef.current) {
      modalRoot.prepend(modalMountRef.current);
    }
    return () => {
      if (modalRoot && modalMountRef.current) {
        modalRoot.removeChild(modalMountRef.current);
      }
    };
  }, []);

  return createPortal(
    <ModalUnderlay>
      <FadeInWrapper>
        <ModalWrapper style={style} className={className}>
          {children}
        </ModalWrapper>
      </FadeInWrapper>
    </ModalUnderlay>,
    modalMountRef.current
  );
};

export default Modal;
