import axios from 'axios';
import { OnboardQuestion } from './components/SoloOnboarding/state-machine';
import { AIReview, ActivityContextQuestionAnswer } from './contexts/activity';

export type ActivityPayload = {
  id: number;
  updated_at: string;
  created_at: string;
  is_core: boolean | null;
  title: string;
  summary: string | null;
  external_url: string | null;
  type: 'EXTERNAL' | 'EXERCISE'; // TODO: could probably remove as no content has type 'EXTERNAL'
  mission_id: number | null;
  estimate_seconds: number;
  index_in_mission: number;
  exercise_id: number | null;
  is_teams_only: boolean;
  slug: string;
} & {
  activity_session_id: number;
  activity_session_instructor_review: string | null;
  activity_session_instructor_score: number | null;
  activity_session_instructor_question_comments: string[];
} & {
  latest_submission: LatestSubmissionPayload;
  exercise: {
    id: number;
    updated_at: string;
    created_at: string;
    description: string;
    lesson: string;
    difficulty: number;
    activity_id: number | null;
    slug: string;
  } & {
    questions: Array<QuestionPayload>;
  };
  content_id: number;
  content_slug: string;
  content_title: string;
  next_activity_slug: string | null;
  next_mission_slug: string | null;
  has_mentor: boolean;
};
export type LatestSubmissionPayload = null | {
  id: number;
  updated_at: string;
  created_at: string;
  difficulty_rating: number | null;
  feedback: string | null;
  seconds_spent: number | null;
  activity_session_id: number;
  ai_review: AIReview | null;
  answer_states: Record<string, AnswerState> | null;
};
export type QuestionPayload = {
  id: number;
  updated_at: string;
  created_at: string;
  type:
    | 'FREE_TEXT'
    | 'FREE_TEXT_PLAYGROUND'
    | 'PLAYGROUND'
    | 'PYTHON_PLAYGROUND'
    | 'GOOGLE_SHEETS_EXTERNAL'
    | 'FREE_TEXT_GOOGLE_SHEETS_EXTERNAL'
    | 'LOOKER_EXTERNAL'
    | 'FREE_TEXT_LOOKER_EMBED'
    | 'TABLEAU_EXTERNAL'
    | 'FREE_TEXT_TABLEAU_EMBED'
    | 'FREE_TEXT_POWERBI_EXTERNAL'
    | 'FREE_TEXT_SQL_CODE'
    | 'PYTHON_MULTIPLE_CHOICE'
    | 'SQL_MULTIPLE_CHOICE';
  text: string;
  hints: Array<string> | null;
  exercise_id: number | null;
  slug: string;
  setup_sql: string | null; // creationDDL + creationDML
  answer_dql: string | null; // for checking dql answers
  verification_sql: string | null; // for checking ddl + dml answers
  index_in_exercise: number;
  looker_link: string | null;
  tableau_link: string | null;
  google_sheets_link: string | null;
  model_answer: string | null;
  notes: string | null;
  powerbi_csv: string | null;
  initial_py: string | null;
  output_regexes_py: Array<string> | null;
  input_regexes_py: Array<string> | null;
  intro_lesson_text: string;
  setup_py: string | null;
  multiple_choice_answers: {
    correct_answer: string;
    distractors: Array<string>;
  } | null;
};

export type UserPayload = {
  id: number;
  created_at: string;
  updated_at: string;
  email: string | null;
  company: string | null;
  name: string | null;
  auth0_ids: string[];
  roles: Array<string>;
  slack_member_id: number | null;
  has_ai_access: boolean;
  isSignUp: boolean;
  isAppUserOnly: boolean;
};

export type ActivitySubmission = {
  difficulty_rating: number | null;
  feedback: string | null;
  activity_session_id: number;
  seconds_spent: number;
  activity_id: number;
  answer_states: Record<string, AnswerState>;
};

export type AnswerState = {
  value: string;
  is_correct: boolean | null;
  is_submitted: boolean;
  submitted_at: Date | null;
  working_code?: string;
};

export type DashboardPayload = {
  missions: Array<{
    slug: string;
    title: string;
    index: number;
    sent_at: Date | null;
    content: {
      id: number;
      slug: string;
      title: string;
    };
    week: {
      id: number;
      slug: string;
      title: string;
      index: number;
    };
    activities: Array<{
      slug: string;
      isCore: boolean;
      title: string;
      instructorReview: string | null;
      instructorScore: number | null;
      isSubmitted: boolean;
      isSent: boolean;
    }>;
  }>;
  links: Array<{
    title: string;
    path: string;
  }>;
};

export type AppliedProjectStat = {
  slug: string;
  name: string;
  description: string;
  image_url: string;
  topic: string;
  is_submitted: boolean;
  is_joined?: boolean;
  question_count: number;
  completedQuestions: number | null;
  tags: string[];
};

export type ActiveTeamsTrack = {
  id: number;
  name: string;
  slug: string;
  content: string;
  topic: string;
  lessonsCount: number;
  startedAt: Date;
  endedAt: Date;
  content_id: number;
  imageUrl: string;
  description: string;
};

export type ActiveTeamsTrackWithProgress = ActiveTeamsTrack & {
  has_submission: boolean;
  next_mission_slug: string;
  next_activity_slug: string;
  next_activity_name: string;
};

export type OnboardingTrack = ActiveTeamsTrack & {
  onboarding: {
    survey: Array<{
      name: string;
      questions: Array<{
        id: string;
        title: string;
        subtitle?: string;
        type:
          | 'input'
          | 'multi-select'
          | 'single-select'
          | 'input-time-timezone';
        options?: string[];
      }>;
      isCompleted: boolean;
    }>;
    slack: {
      isCompleted: boolean;
    };
  };
};
export type HomePayload = {
  tracks: Array<ActiveTeamsTrackWithProgress>;
  onboardingTracks: Array<OnboardingTrack>;
};

export type ProjectExamplesPayload = {
  example: Array<{
    contentTags: Array<'SQL' | 'TABLEAU' | 'LOOKER'>;
    companyType:
      | 'Fintech'
      | 'Marketplace'
      | 'E-commerce'
      | 'B2B'
      | 'Consumer'
      | 'Other';
    roleType:
      | 'Marketing'
      | 'Sales'
      | 'Operations'
      | 'Product'
      | 'Customer Support'
      | 'Engineering'
      | 'Finance'
      | 'Other';
    title: string;
    description: string;
    audienceType: 'Everyone' | 'LendingHome' | 'Fairmarkit';
  }>;
};

export type RetroSurveyPayload = {
  answeredAt: Date | null;
  questions: Array<
    | {
        text: string;
        type: 'RATING';
        answer?: number;
        required: boolean;
      }
    | {
        text: string;
        type: 'FREE_TEXT';
        answer?: string;
        required: boolean;
      }
    | {
        text: string;
        type: 'MULTI_SELECT';
        options: Array<string>;
        answer?: Array<string>;
        required: boolean;
      }
  >;
};

export type ProjectInfoPayload = {
  shouldSubmitProject: boolean;
  shouldAnswerRetroSurvey: boolean;
  shouldUploadFiles: boolean;
};

export type ProsumerProfilePayload = {
  hasProfile: boolean;
  profile: {
    user_id: number;
    pro_until: Date | null;
    utc_notification_time: string | null;
    onboarding_steps: Array<Record<string, unknown>> | null;
    onboarding_content_id: number | null;
  };
  learnMode: {
    hasAccess: boolean;
    customerPortalUrl: string;
    subscriptionId?: string;
    willRenew: boolean;
    cycleEndDate: Date;
    shouldGetTrial: boolean;
  };
  contentModules: Array<ProsumerContentModule>;
};

export type ProsumerContentModule = {
  prosumer_content_session_id: number;
  content_id: number;
  content_title: string;
  content_slug: string;
  content_image_url: string;
  content_description: string;
  content_topic: string;
  is_joined: boolean;
  joined_at: Date;
  next_mission_slug: string;
  next_activity_slug: string;
  next_activity_name: string;
  has_submission: boolean;
  lessons_count: number;
};

type StreakData = {
  streakDays: number;
  maxStreak: number;
  exercises: number;
  aiMessages: number;
  dailyStats: {
    day: string;
    status: 'complete' | 'passed' | 'today';
  }[];
};

export const websoketURL = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'ws://localhost:1235';
    case 'staging':
      return 'wss://staging.desktop-ws.enki.com';
    case 'production':
      return 'wss://desktop-ws.enki.com';
    default:
      return 'ws://localhost:1235';
  }
})();

export const apiURL = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return process.env.REACT_APP_HTTPS
        ? 'https://local.desktop.enki.com:1234'
        : 'http://localhost:1234';
    case 'staging':
      return 'https://staging.desktop.enki.com';
    case 'production':
      return 'https://desktop.enki.com';
    default:
      return 'http://localhost:1234';
  }
})();

axios.defaults.baseURL = apiURL;

export async function getTeamsActivity(
  token: string,
  {
    teamSlug,
    missionSlug,
    activitySlug,
  }: { teamSlug: string; missionSlug: string; activitySlug: string }
): Promise<ActivityPayload> {
  const response = await axios<null, ActivityPayload>({
    method: 'get',
    url: `/tracks/${teamSlug}/missions/${missionSlug}/activities/${activitySlug}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
export async function getAppliedProject(
  token: string,
  { projectSlug }: { projectSlug: string }
): Promise<ActivityPayload> {
  const response = await axios<null, ActivityPayload>({
    method: 'get',
    url: `/appliedprojects/${projectSlug}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getAppliedProjectPreview(
  token: string,
  { projectSlug }: { projectSlug: string }
): Promise<ActivityPayload> {
  const response = await axios<null, ActivityPayload>({
    method: 'get',
    url: `preview/appliedprojects/${projectSlug}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
export async function getPreviewActivity(
  token: string,
  { missionSlug, activitySlug }: { missionSlug: string; activitySlug: string }
): Promise<ActivityPayload> {
  const response = await axios<null, ActivityPayload>({
    method: 'get',
    url: `/preview/missions/${missionSlug}/activities/${activitySlug}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getProsumerActivity(
  token: string,
  { missionSlug, activitySlug }: { missionSlug: string; activitySlug: string }
): Promise<ActivityPayload> {
  const response = await axios<null, ActivityPayload>({
    method: 'get',
    url: `/prosumer/missions/${missionSlug}/activities/${activitySlug}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getTeamsDashboard(
  token: string,
  { trackSlug }: { trackSlug: string }
): Promise<DashboardPayload> {
  const response = await axios<null, DashboardPayload>({
    method: 'get',
    url: `/tracks/${trackSlug}/stats`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getPreviewDashboard(
  token: string,
  { contentSlug }: { contentSlug: string }
): Promise<DashboardPayload> {
  const response = await axios<null, DashboardPayload>({
    method: 'get',
    url: `/preview/${contentSlug}/stats`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getProsumerDashboard(
  token: string,
  { contentSlug }: { contentSlug: string }
): Promise<DashboardPayload> {
  const response = await axios<null, DashboardPayload>({
    method: 'get',
    url: `/prosumer/${contentSlug}/stats`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function saveTeamsActivity(
  token: string,
  { teamSlug, submission }: { teamSlug: string; submission: ActivitySubmission }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/tracks/${teamSlug}/activities/submit`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: submission,
  });
  return;
}

export async function saveProsumerActivity(
  token: string,
  {
    contentSlug,
    submission,
  }: { contentSlug: string; submission: ActivitySubmission }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/prosumer/${contentSlug}/activities/submit`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: submission,
  });
  return;
}

export async function saveAppliedProject(
  token: string,
  { submission }: { submission: ActivitySubmission }
) {
  await axios({
    method: 'post',
    url: `/appliedprojects/submit`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: submission,
  });
}
export async function startTeamsMentorDiscussion(
  token: string,
  {
    teamSlug,
    missionSlug,
    activitySlug,
    question,
  }: {
    teamSlug: string;
    missionSlug: string;
    activitySlug: string;
    question: {
      questionText: string;
      contentText: string;
    };
  }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/tracks/${teamSlug}/missions/${missionSlug}/activities/${activitySlug}/discuss`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: question,
  });
  return;
}

export async function verifyAuthUser(token: string): Promise<UserPayload> {
  const response = await axios.get<UserPayload>('/verify-user', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function getHome(token: string): Promise<HomePayload> {
  const response = await axios<null, HomePayload>({
    method: 'get',
    url: `/home/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function getProjectsDashboard(
  token: string
): Promise<Array<AppliedProjectStat>> {
  const response = await axios<null, Array<AppliedProjectStat>>({
    method: 'get',
    url: '/appliedprojects',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function getStreakStats(token: string): Promise<StreakData> {
  const response = await axios<null, StreakData>({
    method: 'get',
    url: `/streak-stats`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function updateOnboardingAnswer(
  token: string,
  { trackId, surveyStep }: { trackId: number; surveyStep: number }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/camp/onboarding/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      trackId,
      surveyStep,
    },
  });
  return;
}

export async function updateOnboardingSlackJoin(
  token: string,
  { trackId, didJoinSlack }: { trackId: number; didJoinSlack: boolean }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/camp/onboarding/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      trackId,
      didJoinSlack,
    },
  });
  return;
}
export async function getProjectExamples(
  token: string
): Promise<ProjectExamplesPayload> {
  const response = await axios<null, ProjectExamplesPayload>({
    method: 'get',
    url: '/project-examples',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function joinEnkiCommunity(
  token: string,
  { source }: { source: string }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/community/invite?source=${source}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return;
}

export async function getProjectInfo(
  token: string,
  { trackSlug }: { trackSlug: string }
): Promise<ProjectInfoPayload> {
  const response = await axios<null, ProjectInfoPayload>({
    method: 'get',
    url: `/tracks/${trackSlug}/project`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postProjectSubmission(
  token: string,
  { formData, trackSlug }: { formData: FormData; trackSlug: string }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/tracks/${trackSlug}/project`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
  return;
}

export async function getRetroSurvey(
  token: string,
  { teamSlug }: { teamSlug: string }
): Promise<RetroSurveyPayload> {
  const response = await axios<null, RetroSurveyPayload>({
    method: 'get',
    url: `/tracks/${teamSlug}/retro-survey`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export async function postRetroSurvey(
  token: string,
  {
    survey,
    teamSlug,
  }: {
    survey: RetroSurveyPayload & {
      answeredAt: Date;
      questions: Array<RetroSurveyPayload['questions'][0] & { answer: string }>;
    };
    teamSlug: string;
  }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/tracks/${teamSlug}/retro-survey`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { survey },
  });
  return;
}

export async function saveSoloOnboarding({
  onboardingSteps,
  email,
  partnerSignupCode,
  moduleSlug,
}: {
  onboardingSteps: OnboardQuestion[];
  email: string;
  partnerSignupCode: string | null;
  moduleSlug: string;
}): Promise<{
  hasPartnerCode: boolean;
  partnerCode: string;
  partnerWelcomeMessage: string;
  shouldSkipPayment: boolean;
  isAppUser: boolean;
}> {
  const response = await axios<
    null,
    {
      hasPartnerCode: boolean;
      partnerCode: string;
      partnerWelcomeMessage: string;
      shouldSkipPayment: boolean;
      isAppUser: boolean;
    }
  >({
    method: 'post',
    url: `/prosumer/onboarding/save`,
    data: {
      onboardingSteps,
      email,
      partnerSignupCode,
      moduleSlug,
    },
  });
  return response.data;
}

export async function finishSoloOnboarding(token: string): Promise<{
  partnerSignupCode: string;
  partnerWelcomeMessage: string;
  hasPartnerCode: boolean;
}> {
  const response = await axios<
    null,
    {
      partnerSignupCode: string;
      partnerWelcomeMessage: string;
      hasPartnerCode: boolean;
    }
  >({
    method: 'post',
    url: `/prosumer/onboarding/finish`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function getProsumerProfile(
  token: string
): Promise<ProsumerProfilePayload> {
  const response = await axios<null, ProsumerProfilePayload>({
    method: 'get',
    url: `/prosumer/profile`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
  return response.data;
}

export async function joinProsumerContent(
  token: string,
  { contentId }: { contentId: number }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/prosumer/content`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      contentId,
    },
  });
  return;
}

export async function joinAppliedProject(
  token: string,
  { projectSlug }: { projectSlug: string }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/appliedprojects/join/${projectSlug}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createProsumerLearnModePaymentIntent({
  userEmail,
  isMonthlySubscription,
}: {
  userEmail: string;
  isMonthlySubscription: boolean;
}): Promise<{
  paymentIntentClientSecret: string;
  discountPercentage?: number;
}> {
  const response = await axios<
    null,
    { paymentIntentClientSecret: string; discountPercentage?: number }
  >({
    method: 'post',
    url: `prosumer/learn-mode/payment-setup`,
    data: {
      userEmail,
      isMonthlySubscription,
    },
    withCredentials: true,
  });

  return response.data;
}

export async function postTeamsInterestForm({
  workEmail,
  company,
  teamSize,
  extraInfo,
}: {
  workEmail: string;
  company: string;
  teamSize: string;
  extraInfo: string;
}): Promise<void> {
  await axios({
    method: 'post',
    url: `learner/post-teams-interest-form`,
    data: {
      workEmail,
      company,
      teamSize,
      extraInfo,
    },
  });
  return;
}

type CreateLearnModeSubscriptionPayload = {
  email: string;
  isTrial: boolean;
  value: number;
  currency: string;
  metaEventId: string;
};

export async function createProsumerLearnModeSubscription({
  paymentIntentId,
}: {
  paymentIntentId: string;
}): Promise<CreateLearnModeSubscriptionPayload> {
  const response = await axios<null, CreateLearnModeSubscriptionPayload>({
    method: 'post',
    url: `prosumer/learn-mode/create-subscription`,
    data: {
      paymentIntentId,
    },
    withCredentials: true,
  });
  return response.data;
}

export async function createAppliedProjectAIReview(
  token: string,
  {
    sessionId,
    projectSlug,
  }: {
    sessionId: number;
    projectSlug: string;
  }
) {
  await axios({
    method: 'post',
    url: `/appliedprojects/aireview`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      sessionId,
      projectSlug,
    },
  });
}

export async function createProsumerAIReview(
  token: string,
  {
    activitySlug,
    submissionId,
    questionSlugToAnswer,
  }: {
    activitySlug: string;
    submissionId: number;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/prosumer/aireview`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      activitySlug,
      submissionId,
      questionSlugToAnswer,
    },
  });
}

export async function createTeamsAIReview(
  token: string,
  {
    activitySlug,
    submissionId,
    questionSlugToAnswer,
  }: {
    activitySlug: string;
    submissionId: number;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  }
): Promise<void> {
  await axios({
    method: 'post',
    url: `/learner/aireview`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      activitySlug,
      submissionId,
      questionSlugToAnswer,
    },
  });
}
export async function unsubscribeProsumerPreferences({
  emailToken,
}: {
  emailToken: string;
}): Promise<void> {
  await axios({
    method: 'post',
    url: `/prosumer/unsubscribe`,
    data: {
      emailToken,
    },
  });
}
