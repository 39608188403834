import { ActivityContextType } from '..';
import isSubmissionDifferent from './is-submission-different';

export default function updateQuestion({
  activity,
  question,
  answerPart,
}: {
  activity: ActivityContextType;
  question: ActivityContextType['exercise']['questions'][0];
  answerPart: Record<string, unknown>;
}) {
  const answer = activity.submission.questionSlugToAnswer[question.slug];

  const submission = {
    ...activity.submission,
    questionSlugToAnswer: {
      ...activity.submission.questionSlugToAnswer,
      [question.slug]: {
        ...answer,
        ...answerPart,
      },
    },
  };

  return {
    ...activity,
    submission,
    didInteract: true,
    hasUnsavedWork: isSubmissionDifferent(
      submission,
      activity.latestSavedSubmission,
      activity.exercise.questions
    ),
  };
}
