import { useHistory } from 'react-router-dom';
import { AppUserPage } from '../components/SoloOnboarding/Screens/AppUser';

export default function AppUserUpsell() {
  const history = useHistory();
  return (
    <AppUserPage
      onContinue={() => {
        history.push('/join');
      }}
    />
  );
}
