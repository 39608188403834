import PageWrapper from '../PageWrapper';
import Alert from '.';
import Button from '../elements/Button';
import { getHomeUrl } from '../../routing';

const FullScreenAlert = ({
  text,
  onRetry,
}: {
  text: string;
  onRetry?: () => void;
}) => {
  return (
    <PageWrapper
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Alert type="error" text={text} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
        }}
      >
        {typeof onRetry === 'function' && (
          <Button
            type="secondary"
            label="Try again"
            onClick={onRetry}
            faIcon={'fas fa-fw fa-sync'}
          />
        )}
        <Button
          label="Go Home"
          type="secondary"
          onClick={() => {
            // not using useLocation because this component is also used as the error boundary
            // i.e. it may not be rendered within a Router
            window.location.href = getHomeUrl();
          }}
          faIcon={'fas fa-fw fa-home'}
        />
      </div>
    </PageWrapper>
  );
};

export default FullScreenAlert;
