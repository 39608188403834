import styled from 'styled-components';
import { getTrackDurationLabel } from './helpers';
import { getActivityUrl, getTeamsDashboardUrl } from '../../routing';
import ContentCard, {
  CardIcon,
  CardLabelUppercase,
  CardHeader,
  CardPSmall,
} from '../ContentCard';
import { ActiveTeamsTrackWithProgress, OnboardingTrack } from '../../hydra';

const CardRow = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  flex-direction: row;
  display: flex;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
export const Badge = styled(CardLabelUppercase)`
  background-color: ${({ theme }) => theme.colors.bgActive};
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  margin: 0;
`;

export function ActiveTrackCard({
  track,
}: {
  track: ActiveTeamsTrackWithProgress;
}) {
  const durationLabel = getTrackDurationLabel({
    startedAt: track.startedAt,
    endedAt: track.endedAt,
  });

  function getButtonsProps(track: ActiveTeamsTrackWithProgress) {
    const nextMissionSlug = track.next_mission_slug;
    const nextActivitySlug = track.next_activity_slug;

    return [
      ...(nextActivitySlug && nextMissionSlug
        ? [
            {
              label: track.has_submission ? 'Continue' : 'Start',
              href: getActivityUrl(
                track.slug,
                nextMissionSlug,
                nextActivitySlug
              ),
            },
          ]
        : []),
      {
        label: 'Dashboard',
        href: getTeamsDashboardUrl(track.slug),
      },
    ];
  }

  return (
    <TrackCard
      imageUrl={track.imageUrl}
      topic={track.topic}
      content={track.content}
      durationLabel={durationLabel}
      lessonsCount={track.lessonsCount}
      buttonProps={getButtonsProps(track)}
    />
  );
}
export function OnboardedTrackCard({ track }: { track: OnboardingTrack }) {
  const durationLabel = getTrackDurationLabel({
    startedAt: track.startedAt,
    endedAt: track.endedAt,
  });

  return (
    <TrackCard
      topic={track.topic}
      imageUrl={track.imageUrl}
      content={track.content}
      durationLabel={durationLabel}
      lessonsCount={track.lessonsCount}
      buttonProps={[
        {
          label: 'Onboarded!',
          isDisabled: true,
          onClick: () => {},
        },
      ]}
    />
  );
}

const TrackCard = ({
  imageUrl,
  topic,
  content,
  durationLabel,
  lessonsCount,
  buttonProps,
}: {
  imageUrl: string;
  topic: string;
  content: string;
  durationLabel: string;
  lessonsCount: number;
  buttonProps:
    | {
        label: string;
        isDisabled: boolean;
        onClick: () => void;
      }[]
    | {
        label: string;
        href: string;
      }[];
}) => {
  return (
    <ContentCard buttonProps={buttonProps}>
      <Row>
        <CardIcon url={imageUrl} title={`${topic} Track`} />
        <Badge>TEAMS</Badge>
      </Row>
      <CardLabelUppercase>module</CardLabelUppercase>
      <CardHeader>{content}</CardHeader>
      <CardRow>
        <CardPSmall>{durationLabel}</CardPSmall>
        <CardPSmall>{lessonsCount} missions</CardPSmall>
      </CardRow>
    </ContentCard>
  );
};
