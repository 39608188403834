import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  Header,
  ButtonsWrapper,
  CenteredContainer,
  PillButton,
  ProgressBar,
  ReadableContainer,
  CTAButton,
} from './common';
import { TOPICS_DATA, updateModuleKnowledge } from '../config';
import { LabelUppercase } from '../../elements/Text';
import * as Analytics from '../../../analytics';

export default function AskModuleKnowledge() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const topic = state?.context?.topic;
  const placementTest = topic ? TOPICS_DATA[topic]?.placementTest : null;

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_MODULE_KNOWLEDGE,
    state.context
  );

  if (!placementTest) {
    return null;
  }

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ProgressBar currentStep={3} />
        <LabelUppercase>Step 3</LabelUppercase>
        <Header>{placementTest.header}</Header>
        <ButtonsWrapper>
          {placementTest.options.map((option) => (
            <PillButton
              key={option.value}
              isSelected={state?.context?.moduleKnowledge?.includes(
                option.value
              )}
              onClick={() =>
                send({
                  type: EVENTS.SELECT_MODULE_KNOWLEDGE,
                  moduleKnowledge: updateModuleKnowledge({
                    selectedOptions: state?.context?.moduleKnowledge,
                    optionValue: option.value,
                  }),
                })
              }
              label={option.text}
            />
          ))}
        </ButtonsWrapper>
        <CTAButton
          isDisabled={!state?.context?.moduleKnowledge?.length}
          onClick={() => send(EVENTS.CONTINUE_ASK_MODULE_KNOWLEDGE)}
          label="Continue"
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
