import { H1 } from '../../elements/Text';
import { QuestionProps } from '.';
import React, { useEffect } from 'react';
import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import PythonPlayground from '../../PythonPlayground';
import { BottomSpacer, SplitScreenQuestion, getQuestionText } from './common';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import MentorReview from '../MentorComment';
import { toast } from 'react-toastify';
import IncorrectAnswerToast from '../../Toasts/TryAgain';
import {
  AIWorkflow,
  MESSAGE_CONTEXT_TYPES,
} from '../../../contexts/ai-workflow';
import { usePreviousDistinct } from 'react-use';

export default function PythonPlaygroundQuestion({
  question,
  answer,
}: QuestionProps) {
  const { setMessageContext } = React.useContext(AIWorkflow);
  const [activity, dispatch] = React.useContext(ActivityContext);

  const prevAnswer = usePreviousDistinct(answer);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const onCodeChange = React.useCallback(
    (value: string) => {
      dispatch({
        type: ACTIVITY_ACTION.CHANGE_VALUE_PYTHON_PLAYGROUND_QUESTION,
        payload: {
          question,
          value,
        },
      });
    },
    [dispatch, question]
  );

  const onHint = React.useCallback(() => {
    setMessageContext({
      context_type: MESSAGE_CONTEXT_TYPES.QUESTION_HINT,
      context_content: {
        question_index: qIndex,
        attempted_answer: answer.value ?? '',
        is_correct: answer.isCorrect,
      },
    });
  }, [answer, qIndex, setMessageContext]);

  useEffect(() => {
    if (prevAnswer?.questionSlug === answer.questionSlug && answer.isRan) {
      if (answer.isCorrect) {
        toast.success('Correct!');
      } else if (answer.isCorrect === false) {
        toast.warn(() => <IncorrectAnswerToast onHint={onHint} />);
      }
    }
  }, [answer, onHint, prevAnswer]);

  const onResult = React.useCallback(
    (result: string) => {
      dispatch({
        type: ACTIVITY_ACTION.SET_RESULT_PYTHON_PLAYGROUND_QUESTION,
        payload: {
          question,
          result,
        },
      });
    },
    [dispatch, question]
  );

  const onResetCode = React.useCallback(() => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_PYTHON_PLAYGROUND_QUESTION,
      payload: {
        question,
        value: question.initial_py ?? '',
      },
    });
  }, [dispatch, question]);

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <SplitScreenQuestion>
      <>
        <ReadableContainer>
          <H1>Exercise {qIndex + 1}</H1>
          {!!mentorComment && <MentorReview comment={mentorComment} />}
        </ReadableContainer>
        <ReadableMarkdown md={getQuestionText(question)} />
        <BottomSpacer />
      </>
      <PythonPlayground
        setupPy={question.setup_py ?? ''}
        code={answer.value}
        onCodeChange={onCodeChange}
        onResult={onResult}
        onResetCode={onResetCode}
      />
    </SplitScreenQuestion>
  );
}
