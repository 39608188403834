import { ACTIVITY_ACTION, ActivityContextType } from '..';
import updateQuestion from '../helpers/update-question';

export type ChangeQueryFreeTextSqlPlaygroundQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_QUERY_FREE_TEXT_SQL_PLAYGROUND_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    query: string;
  };
};
export default function sqlFreeTextPlaygroundQueryChangeReducer(
  state: ActivityContextType,
  action: ChangeQueryFreeTextSqlPlaygroundQuestionActionType
) {
  const { question, query } = action.payload;

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      working_code: query,
    },
  });
}
