import React from 'react';

export function getVisibleParagraphTextFromSelection(selection: Selection) {
  if (!selection.rangeCount) return '';

  const range = selection.getRangeAt(0);
  const commonAncestor = range.commonAncestorContainer;
  let textContent = '';

  function isVisible(element: Node) {
    if (element.nodeType === Node.ELEMENT_NODE) {
      const style = window.getComputedStyle(element as Element);
      return style.visibility !== 'hidden' && style.display !== 'none';
    }
    return true;
  }

  function getVisibleText(node: Node) {
    if (node.nodeType === Node.TEXT_NODE && node.textContent) {
      if (isVisible(node.parentNode as Node)) {
        textContent += node.textContent.trim();
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      if (isVisible(node)) {
        for (const child of node.childNodes) {
          getVisibleText(child);
        }
      }
    }
  }

  getVisibleText(commonAncestor);

  return textContent;
}

type CustomSelection = {
  selection: string | null;
  section: string | null;
};

export default function useSelection() {
  const [windowSelection, setWindowSelection] = React.useState<CustomSelection>(
    {
      selection: null,
      section: null,
    }
  );
  const [editorSelection, setEditorSelection] = React.useState<CustomSelection>(
    {
      selection: null,
      section: null,
    }
  );
  const [terminalSelection, setTerminalSelection] =
    React.useState<CustomSelection>({
      selection: null,
      section: null,
    });

  const handleWindowSelection = () => {
    const selection = window.getSelection();

    if (selection?.rangeCount && selection.toString()) {
      setWindowSelection({
        selection: selection.toString().trim(),
        section: getVisibleParagraphTextFromSelection(selection),
      });
      return;
    }
    setWindowSelection({
      selection: null,
      section: null,
    });
  };

  const handleTerminalSelection = (e: CustomEvent) => {
    if ((e?.detail?.selection ?? '').trim()) {
      setTerminalSelection({
        selection: e.detail.selection,
        section: e.detail.section,
      });
      return;
    }
    setTerminalSelection({
      selection: null,
      section: null,
    });
  };

  const handleEditorSelection = (e: CustomEvent) => {
    if ((e?.detail?.selection ?? '').trim()) {
      setEditorSelection({
        selection: e.detail.selection,
        section: e.detail.section,
      });
      return;
    }
    setEditorSelection({
      selection: null,
      section: null,
    });
  };

  React.useEffect(() => {
    document.addEventListener('selectionchange', handleWindowSelection);
    document.addEventListener(
      'editorselectionchange',
      handleEditorSelection as EventListener
    );
    document.addEventListener(
      'terminalselectionchange',
      handleTerminalSelection as EventListener
    );

    return () => {
      document.removeEventListener('selectionchange', handleWindowSelection);
      document.removeEventListener(
        'editorselectionchange',
        handleEditorSelection as EventListener
      );
      document.removeEventListener(
        'terminalselectionchange',
        handleTerminalSelection as EventListener
      );
    };
  }, []);

  return {
    selection:
      windowSelection.selection ||
      editorSelection.selection ||
      terminalSelection.selection,
    section:
      windowSelection.section ||
      editorSelection.section ||
      terminalSelection.section,
  };
}
