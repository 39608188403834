import { ACTIVITY_ACTION, ActivityContextType } from '..';
import isSQLPlaygroundQuestionCorrect, {
  SQLResult,
} from '../helpers/is-sql-playground-question-correct';
import updateQuestion from '../helpers/update-question';

export type SetResultSqlPlaygroundQuestionActionType = {
  type: typeof ACTIVITY_ACTION.SET_RESULT_SQL_PLAYGROUND_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    result: SQLResult;
    verificationResult: SQLResult;
  };
};
export default function sqlPlaygroundResultChangeReducer(
  state: ActivityContextType,
  action: SetResultSqlPlaygroundQuestionActionType
) {
  const { question, result, verificationResult } = action.payload;

  const isCorrect = isSQLPlaygroundQuestionCorrect({
    isDQLQuery: !question.verification_sql,
    result,
    verificationResult,
  });
  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      result,
      verificationResult,
      isAnswered: true,
      isCorrect,
      isRan: true,
    },
  });
}
