import React from 'react';
import styled from 'styled-components';

const InnerWrapper = styled.div`
  padding: 0 32px;
  margin: 0 auto;
  @media screen and (max-width: 1600px) {
    margin: 0 32px;
  }
  @media screen and (max-width: 1279px) {
    margin: 0;
  }
  max-width: 1800px;

  background-color: '#09090B'; // hardcoding this because it's used by the error bounduary, outside the theme provider

  animation: fadeIn 0.5s ease-in-out forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const PageWrapper = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
  }
>(({ children, style = {}, className = '' }, ref) => {
  return (
    <div id="wrapper">
      <div className="wrapper">
        <InnerWrapper ref={ref} className={`inner ${className}`} style={style}>
          {children}
        </InnerWrapper>
      </div>
    </div>
  );
});

PageWrapper.displayName = 'PageWrapper';

export default PageWrapper;
