import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { H1, PExtraSmall } from '../../elements/Text';
import React from 'react';
import { ACTIVITY_STEP } from '../Content';
import { QuestionProps } from '.';
import {
  BottomSpacer,
  EmptyContainer,
  FullScreenQuestion,
  Header,
  HeaderAction,
  SplitScreenQuestion,
  getQuestionText,
} from './common';
import { NAVBAR_HEIGHT } from '../../Navbar';
import {
  LookerIframe,
  isBrowserSupporting3rdPartyCookies,
} from '../../Embeds/LookerEmbed';
import Loader from '../../Loader';
import Button from '../../elements/Button';
import { MultiLineInput } from '../../elements/Input';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import MentorReview from '../MentorComment';

export default function FreeTextLookerEmbedQuestion({
  question,
  answer,
}: QuestionProps) {
  const [activity, dispatch] = React.useContext(ActivityContext);
  const [cookieState, setCookieState] = React.useState('LOADING');

  React.useEffect(() => {
    async function checkCookiesAsyncWrapper() {
      const supported = await isBrowserSupporting3rdPartyCookies(question.id);
      setCookieState(supported ? 'SUPPORTED' : 'NOT_SUPPORTED');
    }
    checkCookiesAsyncWrapper();
  }, [question.id]);

  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const onFreeTextLookerEmbedQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_QUESTION_FREE_TEXT_LOOKER_EMBED,
      payload: {
        question,
        value,
      },
    });
  };

  if (cookieState === 'LOADING') {
    return (
      <FullScreenQuestion
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </FullScreenQuestion>
    );
  }

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  if (cookieState === 'NOT_SUPPORTED') {
    return (
      <FullScreenQuestion>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}

        <PExtraSmall>
          Heads up! It seems that your browser doesn't let us display this
          Looker playground as it is blocking third party cookies.
        </PExtraSmall>
        <PExtraSmall>
          Instead, you can open the playground in a new tab using the button on
          the left. Alternatively, check your browser settings or try using a
          different one.
        </PExtraSmall>
        <Button
          type="secondary"
          faIcon="fas fa-chart-pie"
          showIconBeforeLabel
          onClick={() => window.open(question.looker_link ?? '', '_blank')}
          label=" Open Looker Playground"
        />
        <ReadableMarkdown md={getQuestionText(question)} />
        <MultiLineInput
          maxRows={10}
          value={answer.value ?? ''}
          placeholder="Type your answer here"
          onChange={(e) =>
            onFreeTextLookerEmbedQuestionTextChange(e.target.value)
          }
        />
        <BottomSpacer />
      </FullScreenQuestion>
    );
  }

  const openLookerInNewTab = () =>
    window.open(question.looker_link ?? '', '_blank');

  return (
    <SplitScreenQuestion>
      <>
        <ReadableContainer>
          <H1>Exercise {qIndex + 1}</H1>
          {!!mentorComment && <MentorReview comment={mentorComment} />}
        </ReadableContainer>
        <ReadableMarkdown md={getQuestionText(question)} />
        <MultiLineInput
          maxRows={10}
          value={answer.value ?? ''}
          placeholder="Type your answer here"
          onChange={(e) =>
            onFreeTextLookerEmbedQuestionTextChange(e.target.value)
          }
        />
        <BottomSpacer />
      </>
      <EmptyContainer>
        <Header text="Use the Looker embed to answer the question">
          <>
            <HeaderAction onClick={openLookerInNewTab}>
              Open in a new tab
            </HeaderAction>
          </>
        </Header>
        <LookerIframe
          style={{ borderRadius: 0 }}
          src={question.looker_link ?? ''}
          height={`calc(100vh - 3 * ${NAVBAR_HEIGHT}px - 4px) `}
          width={'100%'}
          title="Looker Embed"
          // @ts-expect-error - LookerIframe is not typed properly
          isClickable
        />
      </EmptyContainer>
    </SplitScreenQuestion>
  );
}
