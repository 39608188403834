import { H1, PExtraSmall } from '../../elements/Text';
import { QuestionProps } from '.';
import React from 'react';
import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import {
  BottomSpacer,
  ButtonsRow,
  FullScreenQuestion,
  getQuestionText,
} from './common';
import { toast } from 'react-toastify';
import Button from '../../elements/Button';
import { MultiLineInput } from '../../elements/Input';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import MentorReview from '../MentorComment';

export default function FreeTextQuestion({ question, answer }: QuestionProps) {
  const [activity, dispatch] = React.useContext(ActivityContext);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const onPowerBIExternalQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_POWERBI_EXTERNAL_QUESTION,
      payload: {
        question,
        value,
      },
    });
  };

  const onCopyCSVData = async () => {
    if (!question.powerbi_csv) {
      toast.error('No CSV data to copy. Please get in touch.');
    } else {
      await navigator.clipboard.writeText(question.powerbi_csv);
      toast.success('CSV data copied to clipboard. Import it in PowerBI.');
    }
  };

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <FullScreenQuestion>
      <ReadableContainer>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}
      </ReadableContainer>
      <ReadableMarkdown md={getQuestionText(question)} />
      <ReadableContainer>
        <PExtraSmall>
          Start by <b>copying the data from the CSV file</b>. Then,{' '}
          <b>access the PowerBI Service</b>. Paste the copied data there, and
          import it. Finally, analyze the data to answer the question.
        </PExtraSmall>
        <ButtonsRow>
          <Button
            type="secondary"
            label="Copy CSV Data"
            faIcon="fas fa-copy"
            onClick={onCopyCSVData}
          />
          <Button
            type="default"
            label="Open Power BI Service"
            faIcon="fas fa-chart-pie"
            onClick={() =>
              window.open(
                'https://app.powerbi.com/groups/me/quickcreate?experience=power-bi',
                '_blank'
              )
            }
          />
        </ButtonsRow>

        <MultiLineInput
          maxRows={10}
          value={answer.value ?? ''}
          placeholder="Type your answer here"
          onChange={(e) => onPowerBIExternalQuestionTextChange(e.target.value)}
        />
        <BottomSpacer />
      </ReadableContainer>
    </FullScreenQuestion>
  );
}
