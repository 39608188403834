import { ACTIVITY_ACTION, ActivityContextType } from '..';
import updateQuestion from '../helpers/update-question';

export type ChangeQuerySqlPlaygroundQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_QUERY_SQL_PLAYGROUND_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    query: string;
  };
};

export default function sqlPlaygroundQueryChangeReducer(
  state: ActivityContextType,
  action: ChangeQuerySqlPlaygroundQuestionActionType
) {
  const { question, query } = action.payload;
  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      query,
      value: query,
      isRan: false,
    },
  });
}
