import styled from 'styled-components';

const LinkSpan = styled.span`
  color: #fca92a;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;

  :hover {
    color: #fafafa;
  }
`;

const IncorrectAnswerToast = ({ onHint }: { onHint: () => void }) => (
  <div>
    Not quite! <LinkSpan onClick={onHint}>Ask for a hint</LinkSpan>
  </div>
);

export default IncorrectAnswerToast;
