/* eslint-disable no-case-declarations */
import React from 'react';
import { addActionBreadcrumb } from './helpers';

export const QUESTION_TYPES = {
  INPUT: 'input',
  MULTI_SELECT: 'multi-select',
  SINGLE_SELECT: 'single-select',
  INPUT_TIME_TIMEZONE: 'input-time-timezone',
};

export const initialStateQuestionTypeAnswer = (question) => {
  switch (question.type) {
    case QUESTION_TYPES.INPUT:
      return '';
    case QUESTION_TYPES.MULTI_SELECT:
      return question.options.map((option, index) => ({
        option,
        index,
        isSelected: false,
      }));
    case QUESTION_TYPES.SINGLE_SELECT:
      return question.options.map((option, index) => ({
        option,
        index,
        isSelected: false,
      }));
    case QUESTION_TYPES.INPUT_TIME_TIMEZONE:
      return {
        timezone: null,
        utcOffset: null,
        utcHour: null,
        localHour: null,
        isAnswerValid: false,
      };
    default:
      return null;
  }
};

const initialOnboardingState = (onboardingTask) => ({
  track: {
    ...(onboardingTask.task === 'track-onboarding' && {
      trackId: onboardingTask.track.id,
      survey: onboardingTask.track.onboarding.survey.map(
        ({ questions, name, isCompleted }) => ({
          isCompleted,
          currentIndex: 0,
          name,
          questions: questions.map((question) => ({
            ...question,
            isAnswered: isCompleted,
            answer: initialStateQuestionTypeAnswer(question),
          })),
        })
      ),
      slack: {
        isCompleted: onboardingTask.track.onboarding.slack.isCompleted,
      },
    }),
  },
  community: {
    ...(onboardingTask.task === 'community-onboarding' &&
      onboardingTask.community),
  },
});

export const OnboardingContext = React.createContext();

export const ONBOARDING_ACTION = {
  TRACK: {
    SUBMIT_SURVEY_STEP: 'TRACK_SUBMIT_SURVEY_STEP',
    CONFIRM_JOIN_SLACK: 'TRACK_CONFIRM_JOIN_SLACK',
    ANSWER_SURVEY_QUESTION: 'TRACK_ANSWER_QUESTION_QUESTION',
    INCREMENT_SURVEY_STEP: 'TRACK_INCREMENT_SURVEY_STEP',
    DECREMENT_SURVEY_STEP: 'TRACK_DECREMENT_SURVEY_STEP',
  },
  COMMUNITY: {
    UPDATE_STEP: 'UPDATE_STEP',
  },
};

function onboardingReducer(state, action) {
  addActionBreadcrumb({ context: 'onboarding', actionType: action.type });
  switch (action.type) {
    case ONBOARDING_ACTION.TRACK.SUBMIT_SURVEY_STEP:
      return {
        ...state,
        track: {
          ...state.track,
          survey: state.track.survey.map((step, index) =>
            index !== action.payload.stepIndex
              ? step
              : {
                  ...step,
                  isCompleted: true,
                }
          ),
        },
      };
    case ONBOARDING_ACTION.TRACK.CONFIRM_JOIN_SLACK:
      return {
        ...state,
        track: {
          ...state.track,
          slack: {
            ...state.track.slack,
            isCompleted: true,
          },
        },
      };
    case ONBOARDING_ACTION.TRACK.INCREMENT_SURVEY_STEP:
      const surveyStepIndexIncrement = action.payload.stepIndex;
      if (
        state.track.survey[surveyStepIndexIncrement].questions.length <=
        state.track.survey[surveyStepIndexIncrement].currentIndex + 1
      ) {
        throw Error('Cannot increment survey step after last question');
      }
      return {
        ...state,
        track: {
          ...state.track,
          survey: state.track.survey.map((step, index) =>
            surveyStepIndexIncrement !== index
              ? step
              : {
                  ...state.track.survey[surveyStepIndexIncrement],
                  currentIndex:
                    state.track.survey[surveyStepIndexIncrement].currentIndex +
                    1,
                }
          ),
        },
      };
    case ONBOARDING_ACTION.TRACK.DECREMENT_SURVEY_STEP:
      const surveyStepIndexDecrement = action.payload.stepIndex;
      if (state.track.survey[surveyStepIndexDecrement].currentIndex === 0) {
        throw Error('Cannot decrement survey step on first question');
      }
      return {
        ...state,
        track: {
          ...state.track,
          survey: state.track.survey.map((step, index) =>
            surveyStepIndexDecrement !== index
              ? step
              : {
                  ...state.track.survey[surveyStepIndexDecrement],
                  currentIndex:
                    state.track.survey[surveyStepIndexDecrement].currentIndex -
                    1,
                }
          ),
        },
      };
    case ONBOARDING_ACTION.TRACK.ANSWER_SURVEY_QUESTION:
      const surveyStepIndexAnswer = action.payload.stepIndex;

      return {
        ...state,
        track: {
          ...state.track,
          survey: state.track.survey.map((step, index) =>
            surveyStepIndexAnswer !== index
              ? step
              : {
                  ...step,
                  questions: state.track.survey[
                    surveyStepIndexAnswer
                  ].questions.map((question, index) =>
                    index === action.payload.questionIndex
                      ? {
                          ...question,
                          answer: action.payload.answer,
                          isAnswered: isQuestionAnswered({
                            type: question.type,
                            answer: action.payload.answer,
                          }),
                        }
                      : question
                  ),
                }
          ),
        },
      };
    case ONBOARDING_ACTION.COMMUNITY.UPDATE_STEP:
      const { stepId, ...metadata } = action.payload;
      return {
        ...state,
        community: {
          ...state.community,
          steps: state.community.steps.map((step) =>
            step.id === stepId ? { ...step, ...metadata } : step
          ),
        },
      };
    default:
      throw Error(`Invalid action type "${action.type}"`);
  }
}

function isQuestionAnswered({ type, answer }) {
  switch (type) {
    case QUESTION_TYPES.INPUT:
      return !!answer.trim();
    case QUESTION_TYPES.MULTI_SELECT:
      return answer.filter((item) => item.isSelected).length > 0;
    case QUESTION_TYPES.SINGLE_SELECT:
      return answer.filter((item) => item.isSelected).length > 0;
    case QUESTION_TYPES.INPUT_TIME_TIMEZONE:
      const { localHour, isAnswerValid } = answer;
      return (
        typeof localHour === 'number' &&
        Number.isFinite(localHour) &&
        !Number.isNaN(localHour) &&
        isAnswerValid
      );
    default:
      throw Error(`Invalid question type "${type}"`);
  }
}

export function OnboardingProvider({ onboardingTask, children }) {

  const initialState = onboardingTask
    ? initialOnboardingState(onboardingTask)
    : null;

  const [state, dispatch] = React.useReducer(onboardingReducer, initialState);

  return (
    <OnboardingContext.Provider value={[state, dispatch]}>
      {children}
    </OnboardingContext.Provider>
  );
}
