import { ACTIVITY_ACTION, ActivityContextType } from '..';
import updateQuestion from '../helpers/update-question';

export type ChangeValuePythonPlaygroundQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_VALUE_PYTHON_PLAYGROUND_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    value: string;
  };
};
export default function pythonPlaygroundChangeValueReducer(
  state: ActivityContextType,
  action: ChangeValuePythonPlaygroundQuestionActionType
) {
  const { question, value } = action.payload;

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      value,
      isRan: false,
    },
  });
}
