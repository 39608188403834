import LookerEmbed from '../Embeds/LookerEmbed';
import TableauEmbed from '../Embeds/TableauEmbed';
import EmbedPlotlyChartStudio from '../Embeds/PlotlyChartStudioEmbed';
import GoogleSheetsEmbed from '../Embeds/GoogleSheetsEmbed';
import EmbeddedYoutubePlayer from '../Embeds/YoutubePlayerEmbed';
import VideoEmbed from '../Embeds/VideoEmbed';

function shouldEmbbedYoutubeLink(node) {
  const recognisedDomains = ['https://youtu.be/', 'https://youtube.com/'];
  return recognisedDomains.some((domain) => node.href.startsWith(domain));
}

const LOOKER_EMBED_REGEX = /https:\/\/.*\.looker.com\/embed.*/;
const TABLEAU_EMBED_REGEX = /https:\/\/public\.tableau\.com\/views.*/;
const SHEETS_EMBED_REGEX = /https:\/\/docs.google.com\/spreadsheets\/d.*/;

function shouldEmbedPlotlyChartStudioLink(node) {
  const recognisedDomains = ['https://chart-studio.plotly.com'];
  return recognisedDomains.some((domain) => node.href.startsWith(domain));
}

function shouldEmbedLookerLink(node) {
  return node.title === 'embed' && node.href.match(LOOKER_EMBED_REGEX); // links of form: [Title](lookerlink "embed")
}

function shouldEmbedTableauLink(node) {
  return node.title === 'embed' && node.href.match(TABLEAU_EMBED_REGEX); // links of form: [Title](tableaulink "embed")
}

function shouldEmbedVideoLink(node) {
  return node.title === 'video'; // links of form: [Title](videolink "video")
}

function shouldEmbedGoogleSheetsLink(node) {
  return node.title === 'embed' && node.href.match(SHEETS_EMBED_REGEX);
}

export function shouldEmbed(node) {
  return (
    shouldEmbbedYoutubeLink(node) ||
    shouldEmbedVideoLink(node) ||
    shouldEmbedLookerLink(node) ||
    shouldEmbedTableauLink(node) ||
    shouldEmbedPlotlyChartStudioLink(node) ||
    shouldEmbedGoogleSheetsLink(node)
  );
}

export default function LinkRenderer(node) {
  if (shouldEmbedPlotlyChartStudioLink(node)) {
    return <EmbedPlotlyChartStudio url={node.href} />;
  }
  if (shouldEmbbedYoutubeLink(node)) {
    return <EmbeddedYoutubePlayer node={node} />;
  }
  if (shouldEmbedTableauLink(node)) {
    return <TableauEmbed withReset url={node.href} />;
  }
  if (shouldEmbedLookerLink(node)) {
    return (
      <LookerEmbed url={node.href} withReset id={`looker-link-${node.href}`} />
    );
  }
  if (shouldEmbedGoogleSheetsLink(node)) {
    return (
      <GoogleSheetsEmbed url={node.href} id={`looker-link-${node.href}`} />
    );
  }

  if (shouldEmbedVideoLink(node)) {
    return <VideoEmbed node={node} />;
  }

  return (
    <a href={node.href} target="_blank" rel="noopener noreferrer">
      {node.children}
    </a>
  );
}
