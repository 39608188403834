import styled from 'styled-components';
import { CardIcon, CardPSmall } from '../ContentCard';
import {
  HomePayload,
  OnboardingTrack,
  ProsumerProfilePayload,
} from '../../hydra';
import day from 'dayjs';
import { H4, P } from '../elements/Text';
import theme from '../../theme';
import { useHistory } from 'react-router-dom';

const Card = styled.div`
  grid-column: span 2;
  border-radius: 8px;
  background-color: ${theme.colors.bgActive};
  transition: all 0.3s ease-in-out;
  border: 1px solid ${theme.colors.bgActive};
  padding: 20px;
`;

const ActivityCard = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.bgActive};
  width: 242px;
  display: flex;
  padding: 0;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: 664px) {
    width: 100%;
    margin-top: 20px;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  @media screen and (max-width: 664px) {
    flex-direction: column;
  }
`;

const HeaderRow = styled(Row)`
  gap: 16px;
  justify-content: flex-start;
`;

const HeaderText = styled(H4)`
  margin: 0;
  padding: 0;
  font-weight: 700;
`;

const InfoSection = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (max-width: 664px) {
    width: 100%;
  }

  justify-content: space-between;
`;

const PrimaryButtonContainer = styled.div<{ shouldRoundBottom: boolean }>`
  border-radius: 8px 8px
    ${({ shouldRoundBottom }) => (shouldRoundBottom ? '8px 8px' : '0px 0px')};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.bgActive};
  transition: all 300ms ease-in-out;
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.05);
  }
  padding: 10px 20px 0px 20px;
  cursor: pointer;
`;
const ActivityLabel = styled(P)`
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 25px;
  color: ${theme.colors.textActive};
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  line-height: 33px;
`;

const PrimaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0px;
  margin-top: 6px;
  height: 45px;
`;

const SecondaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0px;
  padding: 0px 20px 0px 20px;
  cursor: pointer;
  height: 46px;
  transition: all 300ms ease-in-out;
  border-radius: 0px 0px 8px 8px;

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.05);
  }
`;

const ButtonLabel = styled(P)`
  color: ${({ theme }) => theme.colors.textRegular};
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
`;
const ButtonIcon = styled.i`
  color: ${({ theme }) => theme.colors.textRegular};
  font-size: 16px;
`;

const TopicSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const MissionCountLink = styled.a`
  justify-self: end;
  cursor: pointer;
  text-decoration: none;
`;

type CTAData = null | {
  trackName: string;
  imageUrl: string;
  trackDescription: string;
  dashboardUrl: string | null;
  lessonsCount: number;
  nextActivityName: string;
  primaryButton: {
    ctaLabel: string;
    onCTAClick: () => void;
  };
  secondaryButton: null | {
    ctaLabel: string;
    onCTAClick: () => void;
  };
};

export default function CTACard({
  prosumerProfile,
  teamsProfile,
  setOpenTeamsOnboarding,
}: {
  prosumerProfile: ProsumerProfilePayload | null | undefined;
  teamsProfile: HomePayload | null | undefined;
  setOpenTeamsOnboarding: (value: {
    task: string;
    track: OnboardingTrack;
  }) => void;
}) {
  const history = useHistory();

  function getCTAData(): CTAData {
    if (teamsProfile) {
      const [nextOnboard] = teamsProfile.onboardingTracks
        .filter((track) => track.endedAt)
        .sort((a, b) =>
          day.utc(a.endedAt).isBefore(day.utc(b.endedAt)) ? 1 : -1
        );
      if (nextOnboard) {
        const trackToOnboard = teamsProfile.onboardingTracks[0];
        const ctaLabel = `Onboard`;
        const onCTAClick = () =>
          setOpenTeamsOnboarding({
            task: 'track-onboarding',
            track: trackToOnboard,
          });
        return {
          trackName: trackToOnboard.name,
          imageUrl: trackToOnboard.imageUrl,
          trackDescription: trackToOnboard.description,
          lessonsCount: trackToOnboard.lessonsCount,
          nextActivityName: `Onboard to ${trackToOnboard.name}`,
          dashboardUrl: null,
          primaryButton: {
            ctaLabel,
            onCTAClick,
          },
          secondaryButton: null,
        };
      }

      const [nextTrack] = teamsProfile.tracks
        .filter((track) => day.utc(track.endedAt).isAfter())
        .sort((a, b) =>
          day.utc(a.endedAt).isBefore(day.utc(b.endedAt)) ? 1 : -1
        );
      if (nextTrack) {
        const ctaLabel = nextTrack.has_submission ? `Continue` : `Start`;

        if (!nextTrack.next_activity_slug) {
          return {
            trackName: nextTrack.topic,
            trackDescription: nextTrack.description,
            imageUrl: nextTrack.imageUrl,
            nextActivityName: 'Completed!',
            lessonsCount: nextTrack.lessonsCount,
            dashboardUrl: `/${nextTrack.slug}/dashboard`,
            primaryButton: {
              ctaLabel: 'Dashboard',
              onCTAClick: () => history.push(`/${nextTrack.slug}/dashboard`),
            },
            secondaryButton: null,
          };
        }

        const onCTAClick = () =>
          history.push(
            `/${nextTrack.slug}/missions/${nextTrack.next_mission_slug}/activities/${nextTrack.next_activity_slug}`
          );

        return {
          trackName: nextTrack.topic,
          trackDescription: nextTrack.description,
          imageUrl: nextTrack.imageUrl,
          nextActivityName: nextTrack.next_activity_name,
          lessonsCount: nextTrack.lessonsCount,
          dashboardUrl: `/${nextTrack.slug}/dashboard`,
          primaryButton: {
            ctaLabel,
            onCTAClick,
          },
          secondaryButton: {
            ctaLabel: 'Dashboard',
            onCTAClick: () => history.push(`/${nextTrack.slug}/dashboard`),
          },
        };
      }
    }

    if (prosumerProfile) {
      const [nextModule] = prosumerProfile.contentModules
        .filter((module) => module.is_joined)
        .sort((a, b) =>
          day.utc(a.joined_at).isBefore(day.utc(b.joined_at)) ? 1 : -1
        );
      if (nextModule) {
        if (!nextModule.next_activity_slug) {
          return {
            trackName: nextModule.content_title,
            trackDescription: nextModule.content_description,
            imageUrl: nextModule.content_image_url,
            nextActivityName: 'Completed!',
            lessonsCount: nextModule.lessons_count,
            dashboardUrl: `/prosumer/${nextModule.content_slug}/dashboard`,
            primaryButton: {
              ctaLabel: 'Dashboard',
              onCTAClick: () =>
                history.push(`/prosumer/${nextModule.content_slug}/dashboard`),
            },
            secondaryButton: null,
          };
        }

        const ctaLabel = nextModule.has_submission ? `Continue` : `Start`;
        const onCTAClick = () =>
          history.push(
            `/prosumer/missions/${nextModule.next_mission_slug}/activities/${nextModule.next_activity_slug}`
          );

        return {
          trackName: nextModule.content_title,
          trackDescription: nextModule.content_description,
          imageUrl: nextModule.content_image_url,
          nextActivityName: nextModule.next_activity_name,
          lessonsCount: nextModule.lessons_count,
          dashboardUrl: `/prosumer/${nextModule.content_slug}/dashboard`,
          primaryButton: {
            ctaLabel,
            onCTAClick,
          },
          secondaryButton: {
            ctaLabel: 'Dashboard',
            onCTAClick: () =>
              history.push(`/prosumer/${nextModule.content_slug}/dashboard`),
          },
        };
      }
    }

    return null;
  }

  const ctaData = getCTAData();

  if (!ctaData) {
    return <></>;
  }

  return (
    <Card>
      <Row>
        <InfoSection style={{ flex: 1 }}>
          <TopicSection>
            <HeaderRow>
              <CardIcon
                url={ctaData.imageUrl}
                title={`${ctaData.trackName} Track`}
              />
              <HeaderText>{ctaData.trackName}</HeaderText>
            </HeaderRow>
            <CardPSmall style={{ marginTop: 23 }}>
              {ctaData.trackDescription}
            </CardPSmall>
          </TopicSection>
          {ctaData.dashboardUrl ? (
            <MissionCountLink href={ctaData.dashboardUrl}>
              <CardPSmall>{ctaData.lessonsCount} missions</CardPSmall>
            </MissionCountLink>
          ) : (
            <CardPSmall style={{ justifySelf: 'flex-end' }}>
              {ctaData.lessonsCount} missions
            </CardPSmall>
          )}
        </InfoSection>
        <ActivityCard>
          <PrimaryButtonContainer
            onClick={ctaData.primaryButton.onCTAClick}
            shouldRoundBottom={ctaData.secondaryButton ? false : true}
          >
            <ActivityLabel>{ctaData.nextActivityName}</ActivityLabel>
            <PrimaryButton>
              <ButtonLabel>{ctaData.primaryButton.ctaLabel}</ButtonLabel>
              <ButtonIcon className="fa-solid fa-forward" />
            </PrimaryButton>
          </PrimaryButtonContainer>

          {ctaData.secondaryButton && (
            <SecondaryButton onClick={ctaData.secondaryButton.onCTAClick}>
              <ButtonLabel>{ctaData.secondaryButton.ctaLabel}</ButtonLabel>
              <ButtonIcon className="fa-solid fa-calendar-days" />
            </SecondaryButton>
          )}
        </ActivityCard>
      </Row>
    </Card>
  );
}
