import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { H1 } from '../../elements/Text';
import React from 'react';
import { ACTIVITY_STEP } from '../Content';
import SQLPlayground from '../../SQLPlayground';
import useSQLRunner from '../../SQLPlayground/use-sql-runner';
import { SQLResult } from '../../../contexts/activity/helpers/is-sql-playground-question-correct';
import { QuestionProps } from '.';
import { BottomSpacer, SplitScreenQuestion, getQuestionText } from './common';
import { MultiLineInput } from '../../elements/Input';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import MentorReview from '../MentorComment';

export default function FreeTextSQLPlaygroundQuestion({
  question,
  answer,
}: QuestionProps) {
  const [activity, dispatch] = React.useContext(ActivityContext);

  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const { runQuery, resetDb, schemas, isLoading, error } = useSQLRunner({
    id: question.id,
    setupSQL: question.setup_sql,
  });

  const onQueryChange = (query: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_QUERY_FREE_TEXT_SQL_PLAYGROUND_QUESTION,
      payload: {
        question,
        query,
      },
    });
  };

  const onReset = () => {
    resetDb();
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_QUERY_SQL_PLAYGROUND_QUESTION,
      payload: {
        question,
        query: '',
      },
    });
  };

  const onRunQuery = (query: string) => {
    const result = runQuery(query) as SQLResult;
    dispatch({
      type: ACTIVITY_ACTION.SET_RESULT_FREE_TEXT_SQL_PLAYGROUND_QUESTION,
      payload: {
        question,
        result,
      },
    });
  };

  const onTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_SQL_PLAYGROUND_QUESTION,
      payload: {
        question,
        value,
      },
    });
  };

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <SplitScreenQuestion>
      <>
        <ReadableContainer>
          <H1>Exercise {qIndex + 1}</H1>
          {!!mentorComment && <MentorReview comment={mentorComment} />}
        </ReadableContainer>
        <ReadableMarkdown md={getQuestionText(question)} />
        <ReadableContainer>
          <MultiLineInput
            value={answer.value ?? ''}
            placeholder="Type your answer here"
            onChange={(e) => onTextChange(e.target.value)}
          />
        </ReadableContainer>
        <BottomSpacer />
      </>

      <SQLPlayground
        query={answer.working_code ?? ''}
        schemas={schemas}
        onQueryChange={onQueryChange}
        onRunQuery={onRunQuery}
        onReset={onReset}
        isLoading={isLoading}
        result={answer.result as SQLResult}
        error={error as unknown as Error}
      />
    </SplitScreenQuestion>
  );
}
