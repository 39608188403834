import React from 'react';
import styled, { useTheme } from 'styled-components';
import Modal from '../Modal/Modal';
import { H4, P } from '../elements/Text';
import Button from '../elements/Button';
import { MultiLineInput } from '../elements/Input';
import { CloseIcon } from '../Icons';
import { toast } from 'react-toastify';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;

  > * {
    margin: 0;
  }
`;

export default function ActivityDiscussModal({
  contentText,
  onStartDiscussion,
  onClose,
}) {
  const theme = useTheme();
  const [questionText, setQuestionText] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState();

  const onSubmit = async () => {
    try {
      setError(null);
      setIsLoading(true);
      await onStartDiscussion({
        contentText,
        questionText,
      });
      toast.success('Message sent to your mentor');
    } catch (err) {
      setError(err);
      console.error(error);
      toast.error("Sorry, we couldn't send your message");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      style={{ width: '100%', maxWidth: theme.dimensions.readableColumnWidth }}
    >
      <Row>
        <H4>Discuss</H4>
        <CloseIcon
          style={{ padding: 0, alignSelf: 'flex-start' }}
          onClick={onClose}
        />
      </Row>
      <P>What you've highlighted</P>
      <MultiLineInput readOnly value={contentText.trim()} />
      <P>Your question or comment</P>
      <MultiLineInput
        value={questionText}
        placeholder="Ask a question about this..."
        onChange={(e) => {
          setQuestionText(e.target.value);
        }}
      />
      <Button
        type="secondary"
        isDisabled={!questionText.trim() || isLoading}
        faIcon={'fas fa-fw fa-paper-plane'}
        isLoading={isLoading}
        onClick={onSubmit}
        label={error ? 'Try again' : 'Submit'}
      />
      <div height="52px" style={{ marginBottom: '52px' }} />
    </Modal>
  );
}
