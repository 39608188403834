import React from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import isNil from 'lodash.isnil';
import { ColumnQuestionWrapper } from './common';

import axios from 'axios';
import theme from '../../../theme';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { H3, P, PExtraSmall } from '../../elements/Text';
import Button from '../../elements/Button';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const TimeTimezone = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function InputTimeTimezone({
  question: { id, title, subtitle, isAnswered },
  index,
  onNext,
  onAnswerQuestion,
  isLastQuestion,
}) {
  const [notificationData, setNotificationData] = React.useState({
    utcOffset: null,
    localHour: null,
    utcHour: null,
    timezone: null,
    isAnswerValid: false,
    meridiemHour: null,
  });

  // e.g., Europe/London
  const guessedTimezone = dayjs.tz.guess();
  const [timezone, setTimezone] = React.useState();
  const [timezones, setTimezones] = React.useState();

  React.useEffect(() => {
    async function fetchTimezones() {
      const { status, data } = await axios({
        method: 'get',
        url: 'https://assets.enki.com/jsons/iana-timezones.json',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (status === 200) {
        const _timezones = data
          .map((timezone) => ({
            value: timezone,
            label: getTimezoneLabel(timezone),
            utcOffset: dayjs().tz(timezone).utcOffset(),
          }))
          .sort((a, b) => a.utcOffset - b.utcOffset);

        setTimezones(_timezones);

        const _timezone = _timezones.find(
          ({ value }) => value === guessedTimezone
        );

        if (_timezone) {
          setTimezone(_timezone);

          setNotificationData((currentValue) => ({
            ...currentValue,
            utcOffset: _timezone.utcOffset,
            timezone: _timezone.value,
          }));
        }
      }
    }
    if (!timezones) {
      fetchTimezones();
    }
  }, [timezones, guessedTimezone]);

  return (
    <ColumnQuestionWrapper key={id}>
      <H3>{title}</H3>
      {subtitle && <P>{subtitle}</P>}
      <PExtraSmall>
        We selected your timezone for you, but feel free to change it.
      </PExtraSmall>
      <TimeTimezone>
        <TimezoneSelect
          timezone={timezone}
          setTimezone={setTimezone}
          timezones={timezones}
          notificationData={notificationData}
          setNotificationData={setNotificationData}
          questionIndex={index}
          onAnswerQuestion={onAnswerQuestion}
        />
        <NotificationTimeInput
          notificationData={notificationData}
          setNotificationData={setNotificationData}
          questionIndex={index}
          onAnswerQuestion={onAnswerQuestion}
        />
      </TimeTimezone>
      <Button
        style={{ width: 'fit-content' }}
        label={isLastQuestion ? 'Submit' : 'Next'}
        isDisabled={!isAnswered}
        onClick={onNext}
        faIcon={isLastQuestion ? 'fas fa-check' : 'fas fa-arrow-right'}
      />
    </ColumnQuestionWrapper>
  );
}

const TimezoneWrapper = styled.div`
  width: 100%;
  // height: 26px;
  margin-top: 32px;
  padding-bottom: 4px;

  input {
    margin: 0;
  }
`;

const StyledSelect = styled(Select)``;

const customStyles = {
  menu: (provided) => ({
    ...provided,
    transition: 'all 0.3s ease-in-out',
    backgroundColor: theme.colors.bgDefault,
  }),

  control: (provided, { isFocused }) => ({
    ...provided,
    transition: 'all 0.3s ease-in-out',
    borderRadius: '8px',
    backgroundColor: theme.colors.transparentBg,
    borderColor: theme.colors.transparentBg,
    opacity: isFocused ? 1 : 0.6,
    padding: '0px 20px',
    boxShadow: 'none',
    outline: 'none',
    '&:hover': {
      opacity: 1,
    },
  }),

  option: (provided, { isSelected, isFocused }) => ({
    ...provided,

    backgroundColor:
      isSelected || isFocused ? theme.colors.navigation : 'transparent',
    color:
      isSelected || isFocused ? theme.colors.text : theme.colors.textDefault,
    cursor: isSelected ? 'not-allowed' : 'default',
  }),

  singleValue: (provided) => ({
    ...provided,
    color: theme.colors.textSecondary,
    paddingLeft: 20,
    paddingRight: 20,
  }),

  input: (provided) => ({
    ...provided,
    color: theme.colors.textSecondary,
    padding: 0,
    'input[type="text"]:focus': {
      boxShadow: 'none',
    },
  }),
};

function TimezoneSelect({
  timezone,
  setTimezone,
  timezones,
  notificationData,
  setNotificationData,
  questionIndex,
  onAnswerQuestion,
}) {
  const onTimezoneChange = (tz) => {
    setTimezone(tz);

    const { utcOffset, value: timezone } = tz;

    setNotificationData({
      ...notificationData,
      utcOffset,
      timezone,
    });

    const { localHour, utcHour } = notificationData;

    const isAnswerValid = !isNil(utcOffset) & !isNil(localHour);

    onAnswerQuestion({
      index: questionIndex,
      answer: { utcOffset, localHour, isAnswerValid, utcHour, timezone },
    });
  };
  return (
    <TimezoneWrapper>
      <StyledSelect
        styles={customStyles}
        options={timezones}
        value={timezone}
        onChange={onTimezoneChange}
      />
    </TimezoneWrapper>
  );
}

const NotificationTimeInputWrapper = styled.div`
  margin: 10px 0px 10px 0px;
  display: flex;
  flex-direction: row;
  width: 51.5%;
  min-width: 350px;
  @media screen and (max-width: 768px) {
    width: 100%;
    min-width: 0px;
    flex-direction: column;
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Hour = styled.input`
  margin: auto;
  font-size: 20px;
  text-align: center;
  width: 80px;
  background-color: transparent;
  padding: 17.5px 20px 17.5px 20px;
  border-radius: 0px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-color: ${theme.colors.textSecondary};
  outline: none;
  :focus {
    border-color: ${theme.colors.primaryBorder};
  }
  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;
const Minute = styled(Hour)`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border-color: ${theme.colors.sectionBorder};
      color: ${theme.colors.sectionBorder};
    `}
  @media screen and (max-width: 768px) {
    margin-right: auto;
    margin-left: 0px;
  }
`;

const StyledTimeSeparator = styled.p`
  margin: auto 5px auto 5px;
  font-size: 40px;
  :hover {
    cursor: default;
  }
`;

const AmPmWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 0px;
  margin-left: 2em;
  @media screen and (max-width: 768px) {
    margin: auto;
  }
`;

const StyledTextButton = styled.div`
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;
  pointer-event: cursor;
`;

const StyledParagraph = styled(P)`
  margin: 0;
  transition: all 0.3s ease-in-out;
  color: ${({ isAm, isPm }) =>
    isAm || isPm ? theme.colors.textSecondary : theme.colors.textDefault};
  :hover {
    color: ${theme.colors.text};
  }
`;

function NotificationTimeInput({
  notificationData,
  setNotificationData,
  questionIndex,
  onAnswerQuestion,
}) {
  const [isAm, setIsAm] = React.useState(true);

  const meridiemToUtc = (hour, _isAm) => {
    return dayjs(
      `2021-01-01 ${hour}:00 ${_isAm ? 'am' : 'pm'}`,
      'YYYY-MM-DD h:m a'
    ).hour();
  };

  const onInvalidHourChange = (event) => {
    event.target.setCustomValidity('');
    setNotificationData({
      ...notificationData,
      localHour: null,
      utcHour: null,
      meridiemHour: null,
      isAnswerValid: false,
    });
    onAnswerQuestion({
      index: questionIndex,
      answer: {
        timezone: notificationData.timezone,
        utcOffset: notificationData.utcOffset,
        localHour: null,
        utcHour: null,
        isAnswerValid: false,
      },
    });
  };

  const onValidHourChange = (event, value) => {
    event.target.setCustomValidity('');
    const hour = parseInt(value);
    const utcHour = dayjs()
      .tz(notificationData.timezone)
      .hour(hour)
      .utc()
      .hour();
    const isAnswerValid = !isNil(hour) && !isNil(notificationData.utcOffset);
    setNotificationData({
      ...notificationData,
      utcHour,
      localHour: meridiemToUtc(hour, isAm),
      meridiemHour: hour,
      isAnswerValid,
    });
    onAnswerQuestion({
      index: questionIndex,
      answer: {
        timezone: notificationData.timezone,
        utcOffset: notificationData.utcOffset,
        localHour: meridiemToUtc(hour, isAm),
        utcHour,
        isAnswerValid,
      },
    });
  };

  return (
    <NotificationTimeInputWrapper>
      <TimeWrapper>
        <Hour
          title="Please enter a number between 1 and 12."
          value={
            notificationData.meridiemHour ? notificationData.meridiemHour : ''
          }
          required
          onChange={(event) => {
            const { value } = event.target;

            if (!value) {
              onInvalidHourChange(event);
            } else if (isNaN(value) || value < 1 || value > 12) {
              event.target.setCustomValidity(
                'Please enter a number between 1 and 12.'
              );
            } else {
              onValidHourChange(event, value);
            }
            event.target.reportValidity();
          }}
        />
        <StyledTimeSeparator>:</StyledTimeSeparator>
        <Minute value="00" disabled />
      </TimeWrapper>
      <AmPmWrapper>
        <StyledTextButton
          onClick={() => {
            if (!isAm) {
              setIsAm(true);
              const { utcOffset, isAnswerValid, meridiemHour } =
                notificationData;
              const localHour = meridiemToUtc(meridiemHour, true);
              const utcHour = dayjs()
                .tz(notificationData.timezone)
                .hour(localHour)
                .utc()
                .hour();
              onAnswerQuestion({
                index: questionIndex,
                answer: {
                  utcOffset,
                  localHour,
                  utcHour,
                  timezone: notificationData.timezone,
                  isAnswerValid,
                },
              });
            }
          }}
        >
          <StyledParagraph isAm={isAm}>AM</StyledParagraph>
        </StyledTextButton>
        <StyledTimeSeparator>/</StyledTimeSeparator>
        <StyledTextButton
          onClick={() => {
            if (isAm) {
              setIsAm(false);
              const { meridiemHour, isAnswerValid, utcOffset } =
                notificationData;
              const localHour = meridiemToUtc(meridiemHour, false);
              const utcHour = dayjs()
                .tz(notificationData.timezone)
                .hour(localHour)
                .utc()
                .hour();
              onAnswerQuestion({
                index: questionIndex,
                answer: {
                  utcOffset,
                  localHour,
                  utcHour,
                  timezone: notificationData.timezone,
                  isAnswerValid,
                },
              });
            }
          }}
        >
          <StyledParagraph isPm={!isAm}>PM</StyledParagraph>
        </StyledTextButton>
      </AmPmWrapper>
    </NotificationTimeInputWrapper>
  );
}

// takes DST into account
// only works with IANA timezone names
// e.g., Europe/London
// returns
// (UTC+00:00) Europe/London
// (UTC+01:00) Europe/London <for DST>
function getTimezoneLabel(timezone) {
  const labelTimezone = timezone.replaceAll('_', ' ');
  const utcOffset = dayjs().tz(timezone).utcOffset();
  const hours = Math.floor(Math.abs(utcOffset) / 60);
  const minutes = utcOffset % 60 === 0 ? '00' : Math.abs(utcOffset % 60);
  const prefix = utcOffset < 0 ? '-' : '+';

  return `(UTC${prefix}${hours
    .toString()
    .padStart(2, 0)}:${minutes}) ${labelTimezone}`;
}
