import EmbedVideo from 'react-player/youtube';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.dimensions.spacing.default.top};
  padding-bottom: ${({ theme }) => theme.dimensions.spacing.default.bottom};
  position: relative;

  iframe {
    border-radius: 8px;
    padding-bottom: 0;
    margin-top: 0;
  }
`;

export default function EmbeddedYoutubePlayer({ node }) {
  return (
    <Wrapper>
      <EmbedVideo url={node.href} controls width="100%" />
    </Wrapper>
  );
}
