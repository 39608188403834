import { useParams } from 'react-router-dom';
import * as Analytics from '../../analytics';
import { EVENTS } from '../../analytics';
import DashboardPage from '../../components/DashboardPage';
import useAuth from '../../hooks/use-auth';
import { useProsumerDashboard } from '../../hooks/use-hydra';
import { getProsumerActivityUrl } from '../../routing';
import day from 'dayjs';
import utc from 'dayjs/plugin/utc';

day.extend(utc);

export default function Dashboard() {
  const { contentSlug } =
    useParams<{
      contentSlug: string;
    }>();

  const trackStats = useProsumerDashboard(contentSlug);
  const { user } = useAuth();

  const getActivityUrl = (
    mission: {
      slug: string;
    },
    activity: {
      slug: string;
    }
  ) => getProsumerActivityUrl(mission.slug, activity.slug);

  Analytics.useTrackViewOnMount(EVENTS.PROSUMER.DASHBOARD.VIEW, {
    contentSlug,
  });

  return (
    <DashboardPage
      user={user}
      statsState={trackStats}
      getActivityUrl={getActivityUrl}
      projectInfo={{
        shouldSubmitProject: false,
        shouldUploadFiles: false,
        shouldAnswerRetroSurvey: false,
      }}
      isProsumer
    />
  );
}
