import React from 'react';
import useAuth from '../../hooks/use-auth';
import { joinProsumerContent } from '../../hydra';
import { toast } from 'react-toastify';
import { getProsumerDashboardUrl, getProsumerActivityUrl } from '../../routing';
import ContentCard, {
  CardIcon,
  CardLabelUppercase,
  CardHeader,
  CardPSmall,
} from '../ContentCard';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export default function ProsumerContentModuleCard({ module }) {
  const { getTokenSilently } = useAuth();
  const [isJoining, setIsJoining] = React.useState(false);
  const history = useHistory();
  const onJoinModule = async (module) => {
    setIsJoining(true);
    try {
      const token = await getTokenSilently();
      await joinProsumerContent(token, {
        contentId: module.content_id,
      });
      history.push(getProsumerDashboardUrl(module.content_slug));
    } catch (err) {
      toast.error('Something went wrong');
    }
    setIsJoining(false);
  };

  function getButtonsProps(module) {
    if (!module.is_joined) {
      return [
        {
          label: 'Enroll',
          isLoading: isJoining,
          onClick: () => onJoinModule(module),
        },
      ];
    }

    return [
      ...(module.next_activity_slug
        ? [
            {
              label: module.has_submission ? 'Continue' : 'Start',
              href: getProsumerActivityUrl(
                module.next_mission_slug,
                module.next_activity_slug
              ),
            },
          ]
        : []),
      {
        label: 'Dashboard',
        href: getProsumerDashboardUrl(module.content_slug),
      },
    ];
  }

  return (
    <ContentCard
      buttonProps={getButtonsProps(module)}
      color={module.is_joined ? null : 'transparent'}
    >
      <Row>
        <CardIcon
          url={module.image_url}
          title={`${module.content_topic} Track`}
        />
      </Row>
      <CardLabelUppercase>module</CardLabelUppercase>
      <CardHeader>{module.content_title}</CardHeader>
      <CardPSmall>{module.content_description}</CardPSmall>
    </ContentCard>
  );
}
