import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  CenteredContainer,
  Header,
  ReadableContainer,
  HighlightSpan,
  SubHeader,
  CTAButton,
  Label,
  MainImage,
} from './common';
import { PROFILES, TOPICS } from '../config';
import * as Analytics from '../../../analytics';

const HighlightedHeader = ({ topic }: { topic: string }) => {
  if (topic === TOPICS.PYTHON) {
    return (
      <Header>
        Learning to code with <HighlightSpan>Python</HighlightSpan> is a great
        choice!
      </Header>
    );
  }

  if (topic === TOPICS.SHEETS) {
    return (
      <Header>
        Learning <HighlightSpan>Spreadsheets</HighlightSpan> is a great choice!
      </Header>
    );
  }

  if (topic === TOPICS.SQL) {
    return (
      <Header>
        Learning <HighlightSpan>SQL</HighlightSpan> is a great choice!
      </Header>
    );
  }

  if (topic === TOPICS.LOOKER) {
    return (
      <Header>
        Learning <HighlightSpan>Looker</HighlightSpan> is a great choice!
      </Header>
    );
  }

  if (topic === TOPICS.TABLEAU) {
    return (
      <Header>
        Learning <HighlightSpan>Tableau</HighlightSpan> is a great choice!
      </Header>
    );
  }

  if (topic === TOPICS.POWER_BI) {
    return (
      <Header>
        Learning <HighlightSpan>PowerBI</HighlightSpan> is a great choice!
      </Header>
    );
  }

  if (topic === TOPICS.DATA_LITERACY) {
    return (
      <Header>
        Learning <HighlightSpan>about data</HighlightSpan> is a great choice!
      </Header>
    );
  }
  return null;
};

const getQuote = ({ topic, profile }: { topic: string; profile: string }) => {
  if (topic === TOPICS.PYTHON && profile === PROFILES.SALES_MARKETING.value) {
    return 'Did you know? 85% of growth professionals that know Python say it gives them a competitive edge.';
  }

  if (
    topic === TOPICS.PYTHON &&
    profile === PROFILES.PRODUCT_ENGINEERING.value
  ) {
    return 'Did you know? 95% of tech hiring managers prioritize Python skills when recruiting.';
  }

  if (topic === TOPICS.PYTHON && profile === PROFILES.MANAGER_EXEC.value) {
    return 'Did you know? 84% of executives that know Python said it has helped their careers.';
  }

  if (topic === TOPICS.PYTHON && profile === PROFILES.STUDENT.value) {
    return 'Did you know? Python is the #1 coding language to know according to 92% of hiring managers.';
  }

  if (topic === TOPICS.PYTHON && profile === PROFILES.OTHER.value) {
    return 'Did you know? Python is the #1 coding language to know according to 92% of hiring managers.';
  }

  if (topic === TOPICS.PYTHON && profile === PROFILES.OERATIONS_SUPPORT.value) {
    return 'Did you know? 82% of people in operations that know Python say it has helped them in their roles.';
  }

  const restTopics = [
    TOPICS.SHEETS,
    TOPICS.SQL,
    TOPICS.DATA_LITERACY,
    TOPICS.LOOKER,
    TOPICS.TABLEAU,
    TOPICS.POWER_BI,
  ];

  if (
    restTopics.includes(topic) &&
    profile === PROFILES.SALES_MARKETING.value
  ) {
    return 'Did you know? 91% of top growth professionals say that data literacy is essential for their success.';
  }

  if (
    restTopics.includes(topic) &&
    profile === PROFILES.PRODUCT_ENGINEERING.value
  ) {
    return 'Did you know? 92% of CEOs say that data skills are critical for delivering successful products.';
  }

  if (restTopics.includes(topic) && profile === PROFILES.MANAGER_EXEC.value) {
    return 'Did you know? 92% of executives that are confident in data tools say it helps their decision-making.';
  }

  if (restTopics.includes(topic) && profile === PROFILES.STUDENT.value) {
    return 'Did you know? 87% of those confident in data tools said this was an unfair advantage during job interviews.';
  }

  if (
    restTopics.includes(topic) &&
    profile === PROFILES.OERATIONS_SUPPORT.value
  ) {
    return 'Did you know? 92% of executives that are confident in data tools say it helps their decision-making.';
  }

  if (restTopics.includes(topic) && profile === PROFILES.OTHER.value) {
    return 'Did you know? 87% of those confident in data tools said this was an unfair advantage during job interviews.';
  }

  return '';
};

export default function PostAskProfile() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);
  const topic = state.context.topic ?? '';
  const profile = state.context.profile ?? '';

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_POST_ASK_PROFILE,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <SubHeader>Congrats 🎉</SubHeader>
        <HighlightedHeader topic={topic} />
        <MainImage
          src={'https://img.enkipro.com/98c5a6a638c9e3b92f64c86cb46b6370.png'}
        />
        <Label>{getQuote({ topic, profile })}</Label>
        <CTAButton
          onClick={() => send(EVENTS.CONTINUE_POST_ASK_PROFILE)}
          label="Continue"
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
