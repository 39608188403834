import { useParams } from 'react-router-dom';
import ActivityPage, {
  ACTIVITY_PAGE_TYPE,
} from '../../components/ActivityPage';
import { useTeamsActivity } from '../../hooks/use-hydra';

import {
  ActivitySubmission,
  createTeamsAIReview,
  getTeamsActivity,
  saveTeamsActivity,
  startTeamsMentorDiscussion,
} from '../../hydra';
import { useHistory } from 'react-router-dom';
import { getActivityUrl, getTeamsDashboardUrl } from '../../routing';
import { toast } from 'react-toastify';
import * as CrashReporter from '../../crash-reporter';
import useAuth from '../../hooks/use-auth';
import { ActivityContextQuestionAnswer } from '../../contexts/activity';

export default function Activity() {
  const { teamSlug, missionSlug, activitySlug } =
    useParams<{
      teamSlug: string;
      missionSlug: string;
      activitySlug: string;
    }>();
  const { getTokenSilently } = useAuth();
  const history = useHistory();

  const activityState = useTeamsActivity(teamSlug, missionSlug, activitySlug);

  const startDiscussion = async ({
    questionText,
    contentText,
  }: {
    questionText: string;
    contentText: string;
  }) => {
    const token = await getTokenSilently();

    try {
      await startTeamsMentorDiscussion(token, {
        teamSlug,
        missionSlug,
        activitySlug,
        question: {
          questionText,
          contentText,
        },
      });
    } catch (error) {
      CrashReporter.reportError(error as Error);
      toast.error(
        'Sorry, we were unable to start the discussion. Try reaching out to your mentor directly.'
      );
    }
  };

  const saveSubmission = async (submission: ActivitySubmission) => {
    const token = await getTokenSilently();

    await saveTeamsActivity(token, {
      teamSlug,
      submission,
    });
  };

  const goToNextActivity = async (): Promise<void> => {
    const { next_activity_slug, next_mission_slug } = activityState.value ?? {};

    toast.info('Moving on to the next activity...', {
      autoClose: 2000,
    });
    setTimeout(() => {
      if (next_activity_slug && next_mission_slug) {
        history.push(
          getActivityUrl(teamSlug, next_mission_slug, next_activity_slug)
        );
      } else {
        history.push(getTeamsDashboardUrl(teamSlug));
      }
    }, 2000);
  };

  const getActivity = async () => {
    const token = await getTokenSilently();
    return getTeamsActivity(token, {
      missionSlug,
      activitySlug,
      teamSlug,
    });
  };

  const createAIReview = async ({
    activitySlug,
    submissionId,
    questionSlugToAnswer,
  }: {
    activitySlug: string;
    submissionId: number;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  }) => {
    const token = await getTokenSilently();
    if (!submissionId) {
      throw new Error('No submission found');
    }
    return createTeamsAIReview(token, {
      activitySlug,
      submissionId,
      questionSlugToAnswer,
    });
  };

  return (
    <ActivityPage
      activityState={activityState}
      startDiscussion={startDiscussion}
      dashboardUrl={getTeamsDashboardUrl(teamSlug)}
      goToNextActivity={goToNextActivity}
      saveSubmission={saveSubmission}
      type={ACTIVITY_PAGE_TYPE.TEAMS}
      getActivity={getActivity}
      createAIReview={createAIReview}
    />
  );
}
