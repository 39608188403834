import React from 'react';
import { Route } from 'react-router-dom';
import useAuth from '../../hooks/use-auth';
import FullScreenLoader from '../Loader/FullScreenLoader';

const UnauthenthicatedRoute = ({
  path,
  children,
  ...rest
}: {
  path: string;
  children: React.ReactNode;
  exact?: boolean;
}) => {
  const {
    isAuthenticating,
    isAuthenticated,
    didAuthenticate,
    isInitialised,
    reAuthSilently,
  } = useAuth();

  const reAuth = React.useCallback(async () => {
    try {
      console.log('reauthenticating');
      await reAuthSilently();
    } catch (error) {
      // @ts-expect-error - weird auth0 error
      if (error?.code === 'login_required') {
        return;
      }
      console.error(error);
    }
  }, [reAuthSilently]);

  React.useEffect(() => {
    if (isAuthenticating) {
      return;
    }

    if (!isInitialised || isAuthenticated || didAuthenticate) {
      return;
    }

    reAuth();
  }, [
    isAuthenticating,
    isAuthenticated,
    reAuth,
    isInitialised,
    didAuthenticate,
  ]);

  if (!isInitialised || !didAuthenticate) {
    return <FullScreenLoader />;
  }

  return (
    <Route path={path} {...rest}>
      {children}
    </Route>
  );
};

export default UnauthenthicatedRoute;
