import React from 'react';
import {
  ACTIVITY_LEARN_SECTIONS,
  extractTextFromNode,
  Node,
} from '../../hooks/use-activity-lesson-scroll-state';
import { ActivityContext } from '../../contexts/activity';
import { H1, H2 } from '../elements/Text';
import {
  ContentSpacer,
  ReadableContainer,
  ReadableMarkdown,
  ScrollableFullScreen,
} from './Containers';

const H2WithClass = ((props: { node: Node }) => (
  <H2
    className={`${ACTIVITY_LEARN_SECTIONS.HEADER.getClassName(
      extractTextFromNode(props.node)
    )}`}
    {...props}
  />
)) as React.ComponentType;

export default function ActivityIntroLesson() {
  const [activity] = React.useContext(ActivityContext);

  const lesson = `${activity.exercise.lesson ?? ''}

${activity.exercise.description ?? ''}`;

  return (
    <ScrollableFullScreen
      className={ACTIVITY_LEARN_SECTIONS.CONTAINER.getClassName()}
    >
      <ContentSpacer>
        <ReadableContainer
          className={ACTIVITY_LEARN_SECTIONS.TITLE.getClassName()}
        >
          <H1>{activity.title}</H1>
        </ReadableContainer>
        <ReadableMarkdown
          md={lesson}
          components={{
            h1: H2WithClass,
            h2: H2WithClass,
          }}
        />
      </ContentSpacer>
    </ScrollableFullScreen>
  );
}
