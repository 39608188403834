import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  Header,
  CenteredContainer,
  PillButton,
  ProgressBar,
  ReadableContainer,
  CTAButton,
} from './common';
import styled from 'styled-components';
import { GOALS } from '../config';
import { LabelUppercase } from '../../elements/Text';
import * as Analytics from '../../../analytics';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
`;

export default function AskGoal() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_GOAL,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ProgressBar currentStep={2} />
        <LabelUppercase>Step 2</LabelUppercase>
        <Header>What is your goal?</Header>
        <ButtonsWrapper>
          {Object.entries(GOALS).map(([key, { value, label }]) => (
            <PillButton
              key={key}
              label={label}
              isSelected={state.context.goal === value}
              onClick={() => {
                send({
                  type: EVENTS.SELECT_GOAL,
                  goal: value,
                });
              }}
            />
          ))}
        </ButtonsWrapper>
        <CTAButton
          isDisabled={!state?.context?.goal}
          onClick={() => send(EVENTS.CONTINUE_ASK_GOAL)}
          label="Continue"
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
