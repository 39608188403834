import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { useProjectInfo } from '../hooks/use-hydra';
import { useHistory, useParams } from 'react-router-dom';
import { postProjectSubmission } from '../hydra';
import useAuth from '../hooks/use-auth';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {
  getTeamsDashboardUrl,
  getHomeUrl,
  getTeamsRetroSurveyUrl,
  getSubmitProjectPageTitle,
} from '../routing';
import PageWithAsyncData from '../components/PageWithAsyncData';
import PageWrapper from '../components/PageWrapper';
import Navbar from '../components/Navbar';
import { H1, H3, P } from '../components/elements/Text';
import Button from '../components/elements/Button';
import { Input, MultiLineInput } from '../components/elements/Input';
import { Helmet } from 'react-helmet-async';
import * as CrashReporter from '../crash-reporter';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const questions = [
  {
    label: 'Introduction',
    question:
      'Which project did you choose and why? How did it benefit your role?',
  },
  {
    label: 'Approach and Methodology',
    question:
      'How did you approach the problem? How did you explore the data, what data did you discover and how was it useful?',
  },
  {
    label: 'Issues',
    question: 'What problems did you run into? How did you tackle them?',
  },
  {
    label: 'Assistance',
    question:
      'Did you need help from your mentor or others? Who did you seek help from?',
  },
  {
    label: 'Conclusions',
    question:
      'How did this impact your role? What elements of your learning will you take forward?',
  },
];

const MIN_ANSWER_LENGTH = 150;

const DeleteIcon = styled.i`
  color: ${({ theme }) => theme.colors.textDanger};
  transform: translateY(1px);

  :hover {
    cursor: pointer;
  }
`;

// this handles a bug noticed on live deployments where learners would submit an answer with the "’" quote
// when decoding the formData on the server, the "’" is converted to an unescaped "/u0019" which subsequently breaks JSON parsing
// see https://enkilabs.slack.com/archives/C042F5UJG/p1676887609950019
const cleanInput = (input: string) => {
  return input.replace(/’/g, "'");
};

export default function SubmitProject() {
  const [isLoadingSubmission, setIsLoading] = React.useState(false);
  const { teamSlug } = useParams<{ teamSlug: string }>();
  const theme = useTheme();
  const { getTokenSilently } = useAuth();
  const history = useHistory();

  const {
    value: projectInfo,
    error: projectInfoError,
    loading: projectInfoLoading,
    retry: retryProjectInfo,
  } = useProjectInfo(teamSlug);

  const [answers, setAnswers] = React.useState(
    [...Array(questions.length)].map(() => '')
  );
  const areAnswersFilledIn = answers.map((a) => a.length >= MIN_ANSWER_LENGTH); // ~ 20 - 35 words

  const [files, setFiles] = React.useState<FileWithPath[]>([]);
  const [links, setLinks] = React.useState<string[]>([]);

  const onRemoveFile = React.useCallback(
    (i: number) => {
      setFiles(files.filter((_, idx) => idx !== i));
    },
    [files]
  );
  const onRemoveLink = React.useCallback(
    (i: number) => {
      setLinks(links.filter((_, idx) => idx !== i));
    },
    [links]
  );

  const onQuestionInput = React.useCallback(
    (value: string, i: number) =>
      setAnswers(answers.map((answer, idx) => (i === idx ? value : answer))),
    [answers]
  );

  const onAddLink = React.useCallback(
    (link: string) => {
      if (link) {
        setLinks([...new Set([link, ...links])]);
      }
    },
    [links]
  );

  const submitAnswers = React.useCallback(async () => {
    const token = await getTokenSilently();
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append(
      'survey',
      JSON.stringify(
        answers.map((answer, i) => ({
          answer: cleanInput(answer),
          question: cleanInput(questions[i].question),
        }))
      )
    );
    formData.append(
      'links',
      JSON.stringify(links.map((link) => cleanInput(link)))
    );

    try {
      setIsLoading(true);
      await postProjectSubmission(token, { formData, trackSlug: teamSlug });
      setIsLoading(false);
      toast.success('Successfully submitted the project!');
      if (projectInfo?.shouldAnswerRetroSurvey) {
        history.push(getTeamsRetroSurveyUrl(teamSlug));
      } else {
        history.push(getTeamsDashboardUrl(teamSlug));
      }
    } catch (err) {
      setIsLoading(false);
      CrashReporter.reportError(err as unknown as Error);
      toast.error(
        'Something went wrong. Please reach out to us to resolve the issue.'
      );
    }
  }, [files, answers, getTokenSilently, teamSlug, history, links, projectInfo]);

  const onSubmit = React.useCallback(() => {
    if (projectInfo?.shouldUploadFiles && !files.length) {
      if (window.confirm('You did not upload any files yet. Submit as is?')) {
        submitAnswers();
      }
    } else {
      submitAnswers();
    }
  }, [files, submitAnswers, projectInfo]);

  if (projectInfo && !projectInfo?.shouldSubmitProject) {
    history.push(getTeamsDashboardUrl(teamSlug));
  }

  return (
    <PageWithAsyncData
      className={''}
      isLoading={projectInfoLoading}
      error={projectInfoError}
      retry={retryProjectInfo}
    >
      {projectInfo && (
        <>
          <Helmet>
            <title>{getSubmitProjectPageTitle()}</title>
          </Helmet>
          <Navbar
            breadCrumbs={[
              {
                label: 'Home',
                url: getHomeUrl(),
              },
              // could add track's content title here
              {
                label: 'Submit your Project',
                url: null,
              },
            ]}
          />
          <PageWrapper
            style={{
              margin: '0 auto 52px',
              maxWidth: theme.dimensions.readableColumnWidth,
            }}
          >
            <H1>Project Submission</H1>
            {questions.map(({ label, question }, i) => (
              <>
                <H3>{label}</H3>
                <P>{question}</P>
                <MultiLineInput
                  errorMessage={
                    !areAnswersFilledIn[i]
                      ? `min ${MIN_ANSWER_LENGTH} characters`
                      : ``
                  }
                  placeholder={'Answer here...'}
                  value={answers[i]}
                  onChange={(e) => onQuestionInput(e.target.value, i)}
                />
              </>
            ))}
            {projectInfo.shouldUploadFiles ? (
              <>
                <UploadProjectFiles
                  setFiles={setFiles}
                  onRemoveFile={onRemoveFile}
                  files={files}
                />
                <AddExternalProjectlinks
                  onRemoveLink={onRemoveLink}
                  links={links}
                  onAddLink={onAddLink}
                />
              </>
            ) : (
              <ManualProjectFilesUpload />
            )}
            <div style={{ marginTop: '52px' }}>
              <Button
                onClick={onSubmit}
                isDisabled={!areAnswersFilledIn.every(Boolean)}
                label={'Complete Project'}
                type="secondary"
                isLoading={isLoadingSubmission}
              />
            </div>
          </PageWrapper>
        </>
      )}
    </PageWithAsyncData>
  );
}

const StyledDropzone = styled.div`
  margin-top: 32px;
  margin-bottom: 12px;
  opacity: 0.6;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.transparentBg};
  border-color: ${({ theme }) => theme.colors.transparentBg};
  border-width: 2px;
  padding: 32px 20px;
  transition: all 0.3s ease-in-out;

  text-align: center;

  &:hover {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.transparentBg};
    border-color: ${({ theme }) => theme.colors.transparentBg};
  }
  p {
    margin-top: 0;
    padding-bottom: 0;
  }
`;

function UploadProjectFiles({
  files,
  onRemoveFile,
  setFiles,
}: {
  files: FileWithPath[];
  onRemoveFile: (i: number) => void;
  setFiles: React.Dispatch<React.SetStateAction<FileWithPath[]>>;
}) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
  });
  return (
    <>
      <H3>Project Files</H3>
      <P>You can upload any project files securely with through us.</P>
      <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <P>Drag and drop your project files here, or click to select them.</P>
      </StyledDropzone>
      <P>{files.length ? 'Files' : "You haven't uploaded any files"}</P>
      <ol>
        {files.map((file, i) => (
          <li key={file.path}>
            {file.path} -{' '}
            <DeleteIcon
              className="fa-solid fa-x"
              onClick={() => onRemoveFile(i)}
            />
          </li>
        ))}
      </ol>
    </>
  );
}

function AddExternalProjectlinks({
  links,
  onRemoveLink,
  onAddLink,
}: {
  links: string[];
  onRemoveLink: (index: number) => void;
  onAddLink: (link: string) => void;
}) {
  const [newLink, setNewLink] = React.useState('');
  // simple URL regex validator https://regexr.com/39p0t
  const isLinkValid =
    /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/.test(
      newLink
    );
  return (
    <>
      <H3>External Links (optional)</H3>
      <P>
        Alternativley, if you have some deliverables hosted somewhere else, feel
        free to add their links directly.
      </P>
      <Input
        type="link"
        placeholder={
          'https://dataflix.looker.com/embed/explore/bike_data/bike_data?qid=bV0UdBHGRkuq987hL4SZAq&origin_space=14&toggle=vis'
        }
        value={newLink}
        onChange={(e) => setNewLink(e.target.value)}
        // @ts-expect-error Input is not typed
        onSubmit={() => onAddLink(newLink)}
        canSubmit={isLinkValid}
      />
      <P>{links.length ? 'Links' : "You haven't added any links"}</P>
      <ol>
        {links.map((link, i) => (
          <li key={link}>
            <a href={link} target="_">
              {link}
            </a>
            {' - '}
            <DeleteIcon
              className="fa-solid fa-x"
              onClick={() => onRemoveLink(i)}
            />
          </li>
        ))}
      </ol>
    </>
  );
}

function ManualProjectFilesUpload() {
  return (
    <>
      <H3>Project files</H3>
      <P>
        For this track, project submission is being done manually. You should've
        received info for how to do this. Reach out to your mentor if unsure!
      </P>
      <P>
        When you've, click the "Complete Project" button below to formally
        complete the Camp.
      </P>
    </>
  );
}
