import { useHistory, useParams } from 'react-router-dom';
import useAuth from '../../hooks/use-auth';
import { useAppliedProject } from '../../hooks/use-hydra';
import {
  ActivitySubmission,
  createAppliedProjectAIReview,
  getAppliedProject,
  saveAppliedProject,
} from '../../hydra';
import {
  ACTIVITY_PAGE_TYPE,
  ActivityLayout,
} from '../../components/ActivityPage';
import { projectsDashboardPath } from '../../routing';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PageWithAsyncData from '../../components/PageWithAsyncData';
import { Helmet } from 'react-helmet-async';
import { ActivityProvider } from '../../contexts/activity';
import Navbar, { NAVBAR_HEIGHT } from '../../components/Navbar';
import React from 'react';
import { AIWorkflow } from '../../contexts/ai-workflow';
import AIChat from '../../components/AIChat';

const StyledNavbar = styled(Navbar)`
  position: static;
`;

const StyledAIChat = styled(AIChat)`
  height: calc(100vh - 2 * ${NAVBAR_HEIGHT}px - 32px);
  bottom: calc(${NAVBAR_HEIGHT}px + 16px);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
`;

export default function AppliedProjectActivity() {
  const { getTokenSilently } = useAuth();
  const { projectSlug } =
    useParams<{
      projectSlug: string;
    }>();

  const projectState = useAppliedProject(projectSlug);

  const getActivity = async () => {
    const token = await getTokenSilently();
    return getAppliedProject(token, {
      projectSlug,
    });
  };
  const history = useHistory();

  const goBackToDashboard = async (): Promise<void> => {
    toast.info('Returning to dashboard...', {
      autoClose: 2000,
    });
    setTimeout(() => {
      history.push(projectsDashboardPath);
    }, 2000);
  };

  const breadCrumbs = [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Projects',
      url: projectsDashboardPath,
    },
    {
      label: projectState.value?.title || 'Project',
      url: null,
    },
  ];

  const saveSubmission = async (submission: ActivitySubmission) => {
    const token = await getTokenSilently();

    await saveAppliedProject(token, {
      submission,
    });
  };

  const createAIReview = async ({
    activitySlug,
    submissionId,
  }: {
    activitySlug: string;
    submissionId: number;
  }) => {
    const token = await getTokenSilently();
    return createAppliedProjectAIReview(token, {
      projectSlug: activitySlug,
      sessionId: submissionId,
    });
  };

  const { hasAIAccess } = React.useContext(AIWorkflow);

  const project = projectState.value;

  return (
    <PageWithAsyncData
      isLoading={projectState.loading}
      error={projectState.error}
      retry={projectState.retry}
      className={''}
    >
      {projectState.value && (
        <>
          <Helmet>
            <title>{projectState.value.title}</title>
          </Helmet>
          <ActivityProvider activity={projectState?.value}>
            <StyledNavbar breadCrumbs={breadCrumbs} />
            {hasAIAccess && project && (
              <div>
                <StyledAIChat
                  contentId={null}
                  missionId={null}
                  activityId={null}
                  projectId={project.exercise.id}
                  discussionContext={'project'}
                  shouldShowChatIcon={false}
                />
              </div>
            )}
            <ActivityLayout
              type={ACTIVITY_PAGE_TYPE.PROSUMER}
              goToNextActivity={goBackToDashboard}
              saveSubmission={saveSubmission}
              getActivity={getActivity}
              startDiscussion={async () => {}}
              createAIReview={createAIReview}
              dashboardUrl={projectsDashboardPath}
              activityState={projectState}
            />
          </ActivityProvider>
        </>
      )}
    </PageWithAsyncData>
  );
}
