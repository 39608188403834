import React from 'react';
import {
  ActivityContext,
  ActivityContextQuestionAnswer,
} from '../../contexts/activity';
import ActivityIntroLesson from './IntroLessonStep';
import ActivityQuestion from './Question';
import styled from 'styled-components';
import autoAnimate from '@formkit/auto-animate';
import { ACTIVITY_NAVIGATION_WIDTH } from './SideNavigation';
import { ActivityPayload } from '../../hydra';
import Review from './Review';
import { ActivityPageProps } from '.';

export enum ACTIVITY_STEP {
  LESSON = 'lesson',
  QUESTION = 'question',
  FEEDBACK = 'feedback',
}

const Wrapper = styled.div<{
  isNavigationOpen: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 0;

  width: calc(100vw - ${ACTIVITY_NAVIGATION_WIDTH}px);
`;

export default function ActivityContent({
  isNavigationOpen,
  getActivity,
  createAIReview,
  activityType,
}: {
  isNavigationOpen: boolean;
  getActivity: () => Promise<ActivityPayload>;
  createAIReview: (data: {
    activitySlug: string;
    submissionId: number;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  }) => Promise<void>;
  activityType: ActivityPageProps['type'];
}) {
  const [activity] = React.useContext(ActivityContext);
  const parent = React.useRef(null);

  const { type } = activity?.currentStep ?? {};

  React.useEffect(() => {
    parent.current &&
      autoAnimate(parent.current, {
        duration: 500,
        easing: 'ease-in-out',
      });
  }, [parent]);

  const questionIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity.currentStep?.questionIndex
      : -1;

  return (
    <Wrapper ref={parent} isNavigationOpen={isNavigationOpen}>
      {type === ACTIVITY_STEP.LESSON && <ActivityIntroLesson />}
      {type === ACTIVITY_STEP.QUESTION && (
        <ActivityQuestion key={`activity-question-${questionIndex}`} />
      )}
      {type === ACTIVITY_STEP.FEEDBACK && (
        <Review
          getActivity={getActivity}
          createAIReview={createAIReview}
          activityType={activityType}
        />
      )}
    </Wrapper>
  );
}
