import React from 'react';
import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import { useHistory, useLocation } from 'react-router-dom';
import {
  EVENTS,
  INITIAL_CONTEXT,
  stateMachine,
  STATES,
  STATES_TO_ROUTES,
} from './state-machine';
import useHistoryListener from '../../hooks/use-history-listener';
import actions, { ACTIONS, parameterisedActions } from './actions';
import guards from './guards';
import services, { parameterisedServices, SERVICES } from './services';
import useAuth from '../../hooks/use-auth';

export const SoloOnboardingContext = React.createContext({
  service: {} as InterpreterFrom<typeof stateMachine>,
});

export const SoloOnboardingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const history = useHistory();
  const location = useLocation();
  const currentRouteRef = React.useRef(location.pathname);
  const { handleAuthCallback, getTokenSilently, reAuthSilently, logout } =
    useAuth();

  const service = useInterpret(stateMachine, {
    context: INITIAL_CONTEXT,
    actions: {
      ...actions,
      [ACTIONS.LOGOUT_WITH_ERROR_TOAST]: parameterisedActions[
        ACTIONS.LOGOUT_WITH_ERROR_TOAST
      ]({
        logout,
      }),
    },
    guards,
    services: {
      ...services,
      [SERVICES.HANDLE_AUTH_CALLBACK]: parameterisedServices[
        SERVICES.HANDLE_AUTH_CALLBACK
      ]({
        handleAuthCallback,
        getTokenSilently,
      }),
    },
  });

  const onBack = React.useCallback(() => {
    const currentState = service.state.value as keyof typeof STATES;
    const backEvent = EVENTS[`BACK_${currentState}` as keyof typeof EVENTS];
    if (backEvent) {
      service.send(backEvent);
    }
  }, [service]);

  const onForward = React.useCallback(() => {
    const currentState = service.state.value as keyof typeof STATES;
    const forwardEvent =
      EVENTS[`CONTINUE_${currentState}` as keyof typeof EVENTS];
    if (forwardEvent) {
      service.send(forwardEvent);
    }
  }, [service]);

  useHistoryListener({
    onBack,
    onForward,
  });

  const onInit = React.useCallback(async () => {
    if (
      location.pathname !== `/join/${STATES_TO_ROUTES[STATES.AUTH_CALLBACK]}`
    ) {
      reAuthSilently()
        .then(() => (window.location.pathname = '/'))
        .catch(() => console.log('not logged in, continue onboarding'));
    }

    if (
      location.pathname === `/join/${STATES_TO_ROUTES[STATES.PAYMENT_CALLBACK]}`
    ) {
      service.send(EVENTS.RESUME_PAYMENT_CALLBACK);
      return;
    } else if (
      location.pathname === `/join/${STATES_TO_ROUTES[STATES.AUTH_CALLBACK]}`
    ) {
      service.send(EVENTS.RESUME_AUTH_CALLBACK);
      return;
    } else if (location.search.includes('topic=')) {
      service.send(EVENTS.SKIP_TO_PROFILE);
      return;
    } else {
      service.send(EVENTS.START_ONBOARDING);
      return;
    }
  }, [service, location.pathname, location.search, reAuthSilently]);

  const updateRoute = React.useCallback(
    (state: InterpreterFrom<typeof stateMachine>['state']) => {
      const route =
        STATES_TO_ROUTES[state.value as keyof typeof STATES_TO_ROUTES];
      const newRoute = `/join/${route}`;

      const currentUrl = new URL(window.location.href);
      const learnMorePartnerCode = currentUrl.searchParams.get('learnModeCode');

      if (currentRouteRef.current !== newRoute) {
        if (state.value === STATES.INITIAL && learnMorePartnerCode) {
          history.push(newRoute + '?utm_source=partnerCode');
        } else {
          history.push(newRoute);
        }

        currentRouteRef.current = newRoute;
      }
    },
    [history]
  );

  const onStateChange = React.useCallback(
    (state: InterpreterFrom<typeof stateMachine>['state']) => {
      if (state.value === STATES.INITIAL) {
        onInit();
        return;
      }

      if (state.value === STATES.END) {
        history.push('/');
        return;
      }

      if (state.changed) {
        updateRoute(state);
      }
    },
    [history, onInit, updateRoute]
  );

  React.useEffect(() => {
    const subscription = service.subscribe(onStateChange);
    return () => {
      subscription.unsubscribe();
    };
  }, [service, onStateChange]);

  return (
    <SoloOnboardingContext.Provider value={{ service }}>
      {children}
    </SoloOnboardingContext.Provider>
  );
};

export default SoloOnboardingContext;
