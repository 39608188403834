import React from 'react';
import { useHistory } from 'react-router-dom';
import useURLQuery from '../../hooks/use-url-query';
import { useGetProsumerProfile } from '../../hooks/use-hydra';
import { getProsumerActivityUrl, getProsumerDashboardUrl } from '../../routing';

export const CONTINUE_PROGRESS_QUERYPARAM = 'continueProgress';
export const CONTENT_ID_QUERYPARAM = 'contentId';

export default function ContinueContnetModuleProgress() {
  const history = useHistory();
  const urlQuery = useURLQuery();
  const { value: profileValue, loading: isLoadingProfile } =
    useGetProsumerProfile();
  const contentId = urlQuery.get(CONTENT_ID_QUERYPARAM);

  const content = (profileValue?.contentModules ?? []).find(
    (module) => module.content_id === parseInt(contentId ?? '')
  );

  const shouldContinueProgress =
    !!content &&
    urlQuery.get(CONTINUE_PROGRESS_QUERYPARAM) === 'true' &&
    !!profileValue &&
    content.is_joined;

  const shouldRemoveQueryParams = !profileValue && !isLoadingProfile;

  const removeQueryParams = React.useCallback(() => {
    const prevQuery = urlQuery.toString();
    urlQuery.delete(CONTINUE_PROGRESS_QUERYPARAM);
    urlQuery.delete(CONTENT_ID_QUERYPARAM);
    if (urlQuery.toString() !== prevQuery) {
      history.replace({
        search: urlQuery.toString(),
      });
    }
  }, [urlQuery, history]);

  React.useEffect(() => {
    if (shouldContinueProgress) {
      const nextActivitySlug = content?.next_activity_slug;
      const nextMissionSlug = content?.next_mission_slug;

      if (nextActivitySlug && nextMissionSlug) {
        history.push(getProsumerActivityUrl(nextMissionSlug, nextActivitySlug));
      } else {
        history.push(getProsumerDashboardUrl(content?.content_slug ?? ''));
      }
    } else if (shouldRemoveQueryParams) {
      removeQueryParams();
    }
  }, [
    history,
    content,
    shouldContinueProgress,
    shouldRemoveQueryParams,
    removeQueryParams,
  ]);

  return null;
}
