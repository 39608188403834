import React from 'react';
import styled from 'styled-components';
import { mixColors } from '../../../utils';
import Button from '../../elements/Button';
import { H2, H3, H4, H5, P, PExtraSmall, PSmall } from '../../elements/Text';
import { Input } from '../../elements/Input';

export const ReadableContainer = styled.div`
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};
  width: 100%;
  margin: auto;
  height: 100%;
  @media only screen and (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const SmallReadableSection = styled.div`
  max-width: 500px;
  width: 100%;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding-top: 72px;
  padding-bottom: 72px;
  flex-direction: column;
  gap: 32px;
  text-align: center;
  flex: 1;
`;

export const PillButton = styled(Button).attrs(({ theme }) => ({
  type: 'default',
  showIconBeforeLabel: true,
  backgroundColor: theme.colors.bgPage,
  color: theme.colors.textActive,
}))<{ isSelected: boolean | undefined }>`
  border-radius: 36px !important;
  text-align: center;
  border: ${({ isSelected, theme }) =>
    isSelected
      ? `1px solid ${theme.colors.primary} !important`
      : '1px solid rgba(161, 161, 170, 0.48)'};
  color: white;
  margin-top: 0;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.primary} !important;
      background-color: ${({ theme }) =>
        mixColors(theme.colors.bgPage, theme.colors.white, 0.075)} !important;
    }
  }
  @media screen and (max-width: 768px) {
    &:hover {
      background-color: transparent !important;
      ${({ isSelected }) =>
        isSelected
          ? `background-color: transparent !important;`
          : 'border: 1px solid rgba(161, 161, 170, 0.48) !important'}
    }
    &:active {
      border: 1px solid ${({ theme }) => theme.colors.primary} !important;
      background-color: ${({ theme }) =>
        mixColors(theme.colors.bgPage, theme.colors.white, 0.075)} !important;
    }
  }

  span {
    font-weight: 400 !important;
  }
`;

export const MainPillButton = styled(Button).attrs(({ theme }) => ({
  type: 'secondary',
  showIconBeforeLabel: true,
  backgroundColor: theme.colors.bgPage,
  color: theme.colors.textActive,
}))`
  border-radius: 100px;
  gap: 12px;
  border: 2px solid ${({ theme }) => theme.colors.navigation};
  padding: 16px 28px;
  margin-top: 0;
  i {
    font-size: 26px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const CTAButton = styled(Button).attrs(({ theme }) => ({
  type: 'primary',
  backgroundColor: theme.colors.bgPage,
}))`
  border-radius: 12px;
`;

export const BackgroundContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://img.enkipro.com/424520efcd76fbaa5b804b844d676705.png');
  background-size: cover;
  background-position: center;
  width: 100%;
`;

export const SubHeader = styled(H3)`
  margin-top: 0;
  padding-bottom: 0;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const FontAwesomeIcon = styled.i`
  color: ${({ theme }) => theme.colors.black};
`;
export const StarIcon = styled.i`
  color: ${({ theme }) => theme.colors.secondary};
`;
const BarWrapper = styled.div`
  position: absolute;
  width: 100vw;
  top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 88%;
`;
const StepCircle = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CurrentStepCircle = styled(StepCircle)`
  border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  color: ${({ theme }) => theme.colors.textActive};

  font-size: 16px;
`;
const FutureStepCircle = styled(StepCircle)`
  border: 2px solid ${({ theme }) => theme.colors.textPlaceholder};
  color: ${({ theme }) => theme.colors.textRegular};
  font-size: 16px;
`;
export const CompletedStepCircle = styled(StepCircle)`
  background-color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
`;

const ProgressRect = styled.div<{ isCompleted: boolean }>`
  flex-grow: 1;
  height: 4px;
  background-color: ${({ theme, isCompleted }) =>
    isCompleted ? theme.colors.primary : theme.colors.textPlaceholder};
  margin: 0;
`;

export const ProgressBar = ({ currentStep }: { currentStep: number }) => {
  return (
    <BarWrapper>
      <BarContainer>
        {[1, 2, 3, 4].map((i) => {
          if (i === currentStep) {
            return (
              <React.Fragment key={`progress-bar-step-${i}`}>
                <CurrentStepCircle>{i}</CurrentStepCircle>
                {i < 4 && <ProgressRect isCompleted={false} />}
              </React.Fragment>
            );
          } else if (i < currentStep) {
            return (
              <React.Fragment key={`progress-bar-step-${i}`}>
                <CompletedStepCircle>
                  <FontAwesomeIcon className={'fa-duotone fa-check'} />
                </CompletedStepCircle>
                {i < 4 && <ProgressRect isCompleted={true} />}
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={`progress-bar-step-${i}`}>
                <FutureStepCircle>{i}</FutureStepCircle>{' '}
                {i < 4 && <ProgressRect isCompleted={false} />}
              </React.Fragment>
            );
          }
        })}
      </BarContainer>
    </BarWrapper>
  );
};
export const Header = styled(H2)`
  margin-top: 0;
  padding-bottom: 0;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const Label = styled(P)<{ textAlign?: 'right' | 'left' | 'center' }>`
  margin-top: 0;
  padding-bottom: 0;
  color: ${({ theme }) => theme.colors.textActive};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
`;

export const LargeLabel = styled(H4)<{
  textAlign?: 'right' | 'left' | 'center';
}>`
  margin-top: 0;
  padding-bottom: 0;
  color: ${({ theme }) => theme.colors.textActive};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
`;

export const HighlightSpan = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
`;

export const MainImage = styled.img`
  margin: 0;
  width: 285px;
  height: 285px;
`;

export const QuoteContainer = styled.div`
  width: 600px;
  padding: 0;
`;

const QuoteParagraph = styled(H5)`
  margin: 0;
  color: ${({ theme }) => theme.colors.textActive};
  text-align: left;
  font-size: 22px;
  font-family: Georgia;
  padding: 4px 0;
`;

const ProfileImage = styled.img`
  width: 40px;
  border-radius: 100%;
  margin: 0;
  padding: 0;
`;

const Column = styled.div<{
  gap?: number;
  centerAlignOnMobile?: boolean;
  textAlign?: 'left' | 'right' | 'center' | 'inherit';
}>`
  display: flex;
  flex-direction: column;
  ${({ gap }) => gap && `gap: ${gap}px`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  @media only screen and (max-width: 600px) {
    ${({ centerAlignOnMobile }) =>
      centerAlignOnMobile &&
      `
      text-align: center;
      align-items: center;
    `};
  }
`;

const Row = styled.div<{ gap?: number; columnDirectionOnMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  ${({ gap }) => gap && `gap: ${gap}px`};

  @media only screen and (max-width: 600px) {
    ${({ columnDirectionOnMobile }) =>
      columnDirectionOnMobile &&
      `
      flex-direction: column;
      align-items: center;
    `};
  }
`;

const QuoteIndicator = styled.div`
  width: 8px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const QuoteAuthor = styled(PSmall)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textRegular};
  margin: 0;
  padding: 0;
`;

export const QuotePosition = styled(PExtraSmall)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textRegular};
  margin: 0;
  padding: 0;
`;

export const QuoteWithStars = ({
  text,
  author,
  profileImage,
}: {
  text: string;
  author: string;
  profileImage: string;
}) => {
  return (
    <Column gap={12}>
      <Row gap={12}>
        <QuoteIndicator />
        <QuoteParagraph> {text}</QuoteParagraph>
      </Row>
      <Row>
        {[0, 1, 2, 3, 4].map((i) => {
          return (
            <StarIcon className={`fa-solid fa-star`} key={`star-icon-${i}`} />
          );
        })}
      </Row>
      <Row gap={9} style={{ alignItems: 'center' }}>
        <ProfileImage src={profileImage} />
        <Column gap={4} style={{ alignItems: 'start' }}>
          <QuoteAuthor>{author}</QuoteAuthor>
        </Column>
      </Row>
    </Column>
  );
};
export const Quote = ({
  text,
  author,
  position,
  profileImage,
}: {
  text: string;
  author: string;
  position: string;
  profileImage: string;
}) => {
  return (
    <Column gap={12}>
      <Row gap={12}>
        <QuoteIndicator />
        <QuoteParagraph> {text}</QuoteParagraph>
      </Row>
      <Row gap={9} style={{ alignItems: 'center' }}>
        <ProfileImage src={profileImage} />
        <Column gap={4} style={{ alignItems: 'start' }}>
          <QuoteAuthor>{author}</QuoteAuthor>
          <QuotePosition>{position}</QuotePosition>
        </Column>
      </Row>
    </Column>
  );
};

export const StyledInput = styled(Input)`
  margin: 0;
  padding-bottom: 0;

  & > div:first-child {
    padding: 12px 16px;
    border-radius: 12px;
  }
`;

const AvatarImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin: 0;
  padding: 0;
  aspect-ratio: 1;
`;

const QuoteText = styled(H4)`
  padding: 0;
  margin: 0;
  font-family: Georgia;
  color: ${({ theme }) => theme.colors.textActive};
  font-weight: normal;
  line-height: normal;
`;
export function LargeQuote({
  avatarURL,
  quote,
  author,
  position,
}: {
  avatarURL: string;
  quote: string;

  author: string;
  position: string;
}) {
  return (
    <Row style={{ gap: 32 }} columnDirectionOnMobile={true}>
      <AvatarImage src={avatarURL} />
      <Column style={{ gap: 12 }} centerAlignOnMobile={true} textAlign={'left'}>
        <QuoteText>"{quote}"</QuoteText>
        <Column style={{ gap: 4 }}>
          <QuoteAuthor>{author}</QuoteAuthor>
          <QuotePosition>{position}</QuotePosition>
        </Column>
      </Column>
    </Row>
  );
}
