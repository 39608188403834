import { useActor } from '@xstate/react';
import Loader from '../../Loader';
import SoloOnboardingContext from '../context';
import React from 'react';
import * as Analytics from '../../../analytics';
import { CenteredContainer } from './common';
import { P } from '../../elements/Text';

export default function PaymentCallback() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_UPSELL_PAYMENT_CALLBACK,
    state.context
  );

  return <PaymentCallbackPage />;
}

export function PaymentCallbackPage() {
  return (
    <CenteredContainer>
      <Loader />
      <P>Processing your payment details. Don't close this page.</P>
    </CenteredContainer>
  );
}
