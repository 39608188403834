import React from 'react';
import { useMutation } from 'react-query';
import FullScreenAlert from '../components/Alert/FullScreenAlert';
import { H1, P } from '../components/elements/Text';
import FullScreenLoader from '../components/Loader/FullScreenLoader';
import { unsubscribeProsumerPreferences } from '../hydra';
import styled from 'styled-components';
import { MainImage } from '../components/SoloOnboarding/Screens/common';
import useURLQuery from '../hooks/use-url-query';
import PageWrapper from '../components/PageWrapper';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
export default function Unsubscribe() {
  const urlQuery = useURLQuery();
  const emailToken = urlQuery.get('emailToken') ?? '';

  const unsubscribeMutation = useMutation({
    mutationFn: async () => {
      await unsubscribeProsumerPreferences({
        emailToken: emailToken,
      });
    },
  });

  React.useEffect(() => {
    if (emailToken && unsubscribeMutation.isIdle) {
      unsubscribeMutation.mutate();
    }
  }, [emailToken, unsubscribeMutation]);

  if (!emailToken || unsubscribeMutation.error) {
    return <FullScreenAlert text="Sorry, this link looks broken." />;
  }

  if (unsubscribeMutation.isSuccess) {
    return (
      <PageWrapper style={{ height: '100vh' }}>
        <Content>
          <H1>Sorry to see you go</H1>
          <MainImage
            src={'https://img.enkipro.com/32e603fff1ec53245bda1bc688ab68f8.png'}
          />
          <P>We've unsubscribed you from our mailing list.</P>
        </Content>
      </PageWrapper>
    );
  }

  return <FullScreenLoader />;
}
