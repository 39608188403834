import PageWithAsyncData from '../components/PageWithAsyncData';
import Navbar from '../components/Navbar';
import { useProjectExamples } from '../hooks/use-hydra';
import ProjectExamplesPage from '../components/ProjectExamplesPage';
import { Helmet } from 'react-helmet-async';
import { getHomeUrl, getProjectExamplesPageTitle } from '../routing';

export default function ProjectExamples() {
  const projectExamples = useProjectExamples();

  return (
    <PageWithAsyncData
      error={projectExamples.error}
      isLoading={projectExamples.loading}
      retry={projectExamples.retry}
    >
      {projectExamples.value && (
        <>
          <Helmet>
            <title>{getProjectExamplesPageTitle()}</title>
          </Helmet>
          <Navbar
            breadCrumbs={[
              {
                label: 'Home',
                url: getHomeUrl(),
              },
            ]}
          />
          <ProjectExamplesPage examples={projectExamples.value.examples} />
        </>
      )}
    </PageWithAsyncData>
  );
}
