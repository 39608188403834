import theme from '../theme';
import { addBreadcrumb, BREADCRUMB_TYPE } from '../crash-reporter';

export function log(s: string) {
  console.log(
    `%c ${s}`,
    `color: ${theme.colors.primary}; background-color: ${theme.colors.bgPage};  font-family: "Inconsolata", monospace; `
  );
}

export function addActionBreadcrumb({
  context,
  actionType,
}: {
  context: string;
  actionType: string;
}) {
  addBreadcrumb({
    message: `Triggered context ${context} action ${actionType}`,
    type: BREADCRUMB_TYPE.STATE,
  });
}
