import styled from 'styled-components';
import EmbedWrapper from '../FullWidthWrapperWthControls';

export const SheetsIFrame = styled.iframe`
  height: ${(props) => props.height || 'min(800px, 70vh)'};
  width: ${(props) => props.width};
  background: #f6f6f7; /* looker's default background color*/
  border-radius: 8px;
  pointer-events: ${(props) => (props.isClickable ? 'auto' : 'none')};
`;

export default function GoogleSheetsEmbed({
  url,
  height = null,
  width = '100%',
  isClickable = true,
  isCopyable = true,
  id = 'google-sheets',
  showBottomBorder = true,
}) {
  if (!url) return null;
  return (
    <EmbedWrapper
      title="Google Sheets Embed"
      showBottomBorder={showBottomBorder}
      buttons={[
        ...(isCopyable
          ? [
              {
                label: 'Make a Copy',
                onClick: () => window.open(getGoogleSheetsCopyUrl(url), '_blank'),
              },
            ]
          : []),
      ]}
    >
      <SheetsIFrame
        id={id}
        src={getSheetsEmbedPreviewUrl(url)}
        title="Sheets Embed"
        frameborder="0"
        height={height}
        width={width}
        isClickable={isClickable}
      />
    </EmbedWrapper>
  );
}

export function getGoogleSheetsCopyUrl(url) {
  const parsedURL = new URL(url);
  if (parsedURL.pathname.includes('/edit')) {
    parsedURL.pathname = parsedURL.pathname.replace('/edit', '/copy');
  } else {
    parsedURL.pathname += '/copy';
  }
  return parsedURL.toString();
}

export function getSheetsEmbedPreviewUrl(url) {
  const parsedURL = new URL(url);
  if (parsedURL.pathname.includes('/edit')) {
    parsedURL.pathname = parsedURL.pathname.replace('/edit', '/htmlembed');
  } else {
    parsedURL.pathname += '/preview';
  }
  parsedURL.searchParams.set('widget', 'true');
  parsedURL.searchParams.set('headers', 'true');
  parsedURL.searchParams.set('chrome', 'true');
  return parsedURL.toString();
}
