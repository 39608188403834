import PageWrapper from '../PageWrapper';
import Loader from '.';

const FullScreenLoader = () => (
  <PageWrapper
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
  >
    <Loader />
  </PageWrapper>
);

export default FullScreenLoader;
