import styled from 'styled-components';
import Markdown from '../Markdown';

export const ReadableMarkdown = styled(Markdown)`
  > * {
    max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};
    margin-left: auto;
    margin-right: auto;
  }
  .full-width {
    max-width: ${({ theme }) => theme.dimensions.largeColumnWidth};
  }
  blockquote {
    transform: translateX(-18px);
  }
`;

export const ReadableContainer = styled.div`
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const ScrollableFullScreen = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const ContentSpacer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 32px 32px;
`;
