export function mixColors(color1: string, color2: string, ratio: number) {
  const r1 = parseInt(color1.substring(1, 3), 16);
  const g1 = parseInt(color1.substring(3, 5), 16);
  const b1 = parseInt(color1.substring(5, 7), 16);

  const r2 = parseInt(color2.substring(1, 3), 16);
  const g2 = parseInt(color2.substring(3, 5), 16);
  const b2 = parseInt(color2.substring(5, 7), 16);

  const r = Math.round(r1 * (1 - ratio) + r2 * ratio)
    .toString(16)
    .padStart(2, '0');
  const g = Math.round(g1 * (1 - ratio) + g2 * ratio)
    .toString(16)
    .padStart(2, '0');
  const b = Math.round(b1 * (1 - ratio) + b2 * ratio)
    .toString(16)
    .padStart(2, '0');

  return `#${r}${g}${b}`;
}

export function addOpacityToHex(hex: string, opacity: number) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
