import queryString from 'query-string';

export default function getDefaultOpenQuestionIndex(activity) {
  const noQuestions = activity.exercise.questions.length;

  const parsedQuery = queryString.parse(window.location.search);
  if (
    Number.isFinite(parseInt(parsedQuery.question)) &&
    parsedQuery.question < noQuestions
  ) {
    return parseInt(parsedQuery.question);
  }
  return 0;
}
