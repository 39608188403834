import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import { Login } from '../../Auth/Login';
import { CenteredContainer, ReadableContainer } from './common';
import * as Analytics from '../../../analytics';

export default function SignUp() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const email = state.context.email ?? '';

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_SIGNUP,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <Login
          email={email}
          title={'Last step! Sign up to access your Enki learning program'}
          subtitle={'Make sure to continue with the email you used before'}
          redirectUri={`${window.location.origin}/join/auth-callback`}
          canEditEmail={false}
          shouldShowSignupLink={false}
          goToConfirmEmail={() => {
            send(EVENTS.GO_TO_CONFIRM_EMAIL);
          }}
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
