import { ColumnQuestionWrapper } from './common';
import { H3, P, PExtraSmall } from '../../elements/Text';
import { MultiLineInput } from '../../elements/Input';
import Button from '../../elements/Button';

export default function InputQuestion({
  question: { title, subtitle, answer, isAnswered },
  index,
  onNext,
  onAnswerQuestion,
  isLastQuestion,
}) {
  return (
    <ColumnQuestionWrapper>
      <H3>{title}</H3>
      {subtitle && <P>{subtitle}</P>}
      <MultiLineInput
        placeholder={'Enter your answer here'}
        value={answer}
        onChange={(e) => onAnswerQuestion({ index, answer: e.target.value })}
      />
      <PExtraSmall>Enter ↵ to make a line break</PExtraSmall>
      <Button
        style={{ width: 'fit-content' }}
        label={isLastQuestion ? 'Submit' : 'Next'}
        isDisabled={!isAnswered}
        onClick={onNext}
        faIcon={isLastQuestion ? 'fas fa-check' : 'fas fa-arrow-right'}
      />
    </ColumnQuestionWrapper>
  );
}
