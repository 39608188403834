import { useParams } from 'react-router-dom';
import DashboardPage from '../../components/DashboardPage';
import useAuth from '../../hooks/use-auth';
import { usePreviewDashboard } from '../../hooks/use-hydra';
import { getPreviewActivityUrl } from '../../routing';

export default function Dashboard() {
  const { contentSlug } =
    useParams<{
      contentSlug: string;
    }>();

  const trackStats = usePreviewDashboard(contentSlug);
  const { user } = useAuth();

  const getActivityUrl = (
    mission: {
      slug: string;
    },
    activity: {
      slug: string;
    }
  ) => getPreviewActivityUrl(mission.slug, activity.slug);

  return (
    <DashboardPage
      user={user}
      statsState={trackStats}
      getActivityUrl={getActivityUrl}
      isPreview
    />
  );
}
