import styled, { useTheme } from 'styled-components';
import { PSmall } from './Text';

const BadgeWrapper = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 16px;
  border-radius: 200px;
  gap: 7px;
  align-items: center;

  padding: 8px 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;

const BadgeText = styled(PSmall)`
  font-weight: 700;
  text-align: center;
  margin-top: 0;
  padding-bottom: 0;
`;

const FontAwesomeIcon = styled.i`
  color: ${({ color }) => color};
  font-size: 12px;
`;

export default function Badge({
  isPrimary,
  colors = null,
  label,
  faIcon = null,
  className = '',
  style,
}) {
  const theme = useTheme();

  const badgeColors =
    colors ||
    (isPrimary
      ? {
          textColor: theme.colors.bgPage,
          backgroundColor: theme.colors.primary,
        }
      : {
          textColor: theme.colors.textSecondary,
          backgroundColor: theme.colors.transparentBg,
        });
  return (
    <BadgeWrapper
      style={style}
      className={className}
      color={badgeColors.textColor}
      backgroundColor={badgeColors.backgroundColor}
    >
      <BadgeText>{label}</BadgeText>
      {faIcon && (
        <FontAwesomeIcon className={faIcon} color={badgeColors.textColor} />
      )}
    </BadgeWrapper>
  );
}
