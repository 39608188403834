import React from 'react';
import {
  OnboardingContext,
  ONBOARDING_ACTION,
} from '../../../contexts/onboarding';
import TypeForm from '../Typeform';
import { updateOnboardingAnswer } from '../../../hydra';
import useAuth from '../../../hooks/use-auth';

export default function TrackTypeform({ onRefreshData, stepIndex }) {
  const { getTokenSilently } = useAuth();

  const [onboardingState, dispatch] = React.useContext(OnboardingContext);

  const { questions, currentIndex } = onboardingState.track.survey[stepIndex];

  const onAnswerQuestion = ({ answer, index }) => {
    dispatch({
      type: ONBOARDING_ACTION.TRACK.ANSWER_SURVEY_QUESTION,
      payload: {
        answer,
        questionIndex: index,
        stepIndex,
      },
    });
  };

  const onIncrementSurvey = () => {
    dispatch({
      type: ONBOARDING_ACTION.TRACK.INCREMENT_SURVEY_STEP,
      payload: {
        stepIndex,
      },
    });
  };

  const onDecrementSurvey = () => {
    dispatch({
      type: ONBOARDING_ACTION.TRACK.DECREMENT_SURVEY_STEP,
      payload: {
        stepIndex,
      },
    });
  };

  const onSubmitAnswers = async () => {
    const token = await getTokenSilently();

    await updateOnboardingAnswer(token, {
      trackId: onboardingState.track.trackId,
      surveyStep: {
        stepIndex,
        answers: onboardingState.track.survey[stepIndex].questions,
      },
    });
    // TODO: TEST THIS
    dispatch({
      type: ONBOARDING_ACTION.TRACK.SUBMIT_SURVEY_STEP,
      payload: {
        stepIndex,
      },
    });
  };
  const totalSteps = onboardingState.track.survey
    .map((step) => step.questions)
    .flat().length;

  console.log(onboardingState.track.survey);
  const completedSteps = onboardingState.track.survey
    .map((step) => step.questions)
    .flat()
    .filter((question) => question.isAnswered).length;

  return (
    <TypeForm
      onRefreshData={onRefreshData}
      questions={questions}
      currentIndex={currentIndex}
      onAnswerQuestion={onAnswerQuestion}
      onIncrementSurvey={onIncrementSurvey}
      onDecrementSurvey={onDecrementSurvey}
      onSubmitAnswers={onSubmitAnswers}
      totalSteps={totalSteps}
      currentStep={completedSteps}
    />
  );
}
