import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

day.extend(timezone);
day.extend(utc);

export function getTrackDurationLabel({ endedAt, startedAt }) {
  const now = day().startOf('day');

  // cycle bounds are returned as UTC dates timestamps
  // the cycle bounds should be the same, but in the learner's timezone
  // we also truncate the dates such that if it's "23:00" and cycle starts next day, then it still shows 1 day diff
  const startDate = day.utc(startedAt).tz(day.tz.guess(), true).startOf('day');
  const endDate = day.utc(endedAt).tz(day.tz.guess(), true).endOf('day');
  if (startDate.isAfter(day())) {
    return `Starting in ${startDate.diff(now, 'day')} days`;
  }
  if (endDate.isAfter(day())) {
    return `Ending in ${endDate.diff(now, 'day')} days`;
  }

  return `Ended ${now.diff(endDate, 'day')} days ago`;
}
