import styled from 'styled-components';
import { prop } from 'styled-tools';

const StyledIcon = styled.i`
  font-size: 24px;
  padding: 20px;
  transition: all 300ms ease-in-out;

  color: ${prop('theme.colors.text')};
  opacity: 0.6;

  &:not([disabled]):hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &:not([disabled]):active {
    opacity: 1;
    transition: opacity 0s;
  }
`;

export const CloseIcon = ({ onClick, className = '', style = {} }) => {
  return (
    <StyledIcon
      className={`fa-solid fa-xmark ${className}`}
      onClick={onClick}
      style={style}
    />
  );
};

export const ChevronDownIcon = ({ onClick, className = '', style = {} }) => {
  return (
    <StyledIcon
      className={`fa-solid fa-chevron-down ${className}`}
      onClick={onClick}
      style={style}
    />
  );
};
