import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  CenteredContainer,
  CTAButton,
  MainImage,
  ReadableContainer,
  Header,
} from './common';
import Markdown from '../../Markdown';
import * as Analytics from '../../../analytics';

export default function PartnerWelcome() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const { partnerWelcomeMessage } = state.context;

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_PARTNER_WELCOME,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>Welcome 👋</Header>
        <MainImage src="https://img.enkipro.com/5b4019c03ae992972d41edbaae381066.png" />
        <Markdown md={partnerWelcomeMessage ?? ''} />
        <CTAButton
          onClick={() => send(EVENTS.CONTINUE_PARTNER_WELCOME)}
          label="Continue"
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
