import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.dimensions.spacing.default.top};
  padding-bottom: ${({ theme }) => theme.dimensions.spacing.default.bottom};
  max-width: 1200px;
`;

const IFrame = styled.iframe`
  height: min(50vh, 800px);
  width: 100%;
  border-radius: 8px;
`;

export default function EmbedPlotlyChartStudio({ url }) {
  const directUrl = url.replace('/#/', '.embed'); // it works with the URL directly too, but this way it loads faster as it won't redirect during embedding

  return (
    <Wrapper>
      <IFrame src={directUrl}></IFrame>
    </Wrapper>
  );
}
