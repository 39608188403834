import React from 'react';
import styled from 'styled-components';
import TrackSlackOnboardingStep from './TrackSlackOnboarding';
import TypeForm from './TrackTypeform';
import { OnboardingContext } from '../../../contexts/onboarding';
import { CloseIcon } from '../../Icons';
import { toast } from 'react-toastify';

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default function TrackOnboardingContent({ onRefreshData, onClose }) {
  const [onboardingState] = React.useContext(OnboardingContext);

  const currentStep = [
    ...onboardingState.track.survey.map((step) => step.isCompleted),
    onboardingState.track.slack.isCompleted,
  ].findIndex((x) => !x);

  const screens = [
    ...onboardingState.track.survey.map((step, index) => ({
      component: <TypeForm onRefreshData={onRefreshData} stepIndex={index} />,
      name: step.name,
    })),
    {
      component: (
        <TrackSlackOnboardingStep
          onRefreshData={onRefreshData}
          onClose={onClose}
        />
      ),
      name: 'Connect to Slack',
    },
  ];

  React.useEffect(() => {
    if (currentStep === -1 && onboardingState.track.slack.isCompleted) {
      onClose();
      toast.success(
        "Onboarding complete! We'll be in touch with the kick-off call info."
      );
      onRefreshData();
    }
  }, [
    onRefreshData,
    currentStep,
    onClose,
    onboardingState.track.slack.isCompleted,
  ]);

  return (
    <>
      <Column>
        <CloseIcon
          onClick={onClose}
          style={{
            padding: 0,
            fontSize: '36px',
            position: 'absolute',
            right: 23,
            top: 23,
          }}
        />
      </Column>

      <ContentWrapper>
        {currentStep !== -1 && screens[currentStep].component}
      </ContentWrapper>
    </>
  );
}
