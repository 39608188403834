import { ColumnQuestionWrapper, SelectOption, OptionsWrapper } from './common';
import { H3, P, PSmall } from '../../elements/Text';
import Button from '../../elements/Button';

export default function MultiSelectQuestion({
  question: { id, title, subtitle, answer, isAnswered, options },
  index,
  onNext,
  onAnswerQuestion,
  isLastQuestion,
}) {
  const toggleItem = ({ itemIndex, isSelected }) => {
    onAnswerQuestion({
      index,
      answer: answer.map((item) => {
        if (item.index === itemIndex) {
          return {
            index: item.index,
            option: item.option,
            isSelected,
          };
        }
        return item;
      }),
    });
  };
  return (
    <ColumnQuestionWrapper key={id}>
      <H3>{title}</H3>
      {subtitle && <P>{subtitle}</P>}
      <PSmall>Choose as many as you like</PSmall>
      <OptionsWrapper style={{ marginTop: '16px' }}>
        {options.map((option, index) => {
          const step = answer.find((option) => option.index === index);

          return (
            <SelectOption
              key={`select-option-${option}`}
              isSelected={step.isSelected}
              index={index}
              onClick={() =>
                toggleItem({ itemIndex: index, isSelected: !step.isSelected })
              }
            >
              {option}
            </SelectOption>
          );
        })}
      </OptionsWrapper>
      <Button
        style={{ width: 'fit-content' }}
        label={isLastQuestion ? 'Submit' : 'Next'}
        isDisabled={!isAnswered}
        onClick={onNext}
        faIcon={isLastQuestion ? 'fas fa-check' : 'fas fa-arrow-right'}
      />
    </ColumnQuestionWrapper>
  );
}
