import styled from 'styled-components';

const Tag = styled.p<{ isSelected: boolean }>`
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primary : theme.colors.bgActive};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.black : theme.colors.text};
  font-size: 14px;
  cursor: pointer;
  padding: 4px 24px;
  border-radius: 50px;
  text-transform: lowercase;
  &:hover {
    background-color: ${({ isSelected, theme }) =>
      `color-mix(in srgb, ${
        isSelected ? theme.colors.primary : theme.colors.bgActive
      } 90%, black 10%);`};
  }
`;

const TagRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
`;
export default function MultiselectTags({
  selectedOptions,
  options,
  onChange,
  allowMultiple = false,
}: {
  selectedOptions: string[];
  options: string[];
  onChange: (selected: string[]) => void;
  allowMultiple?: boolean;
}) {
  const handleTagClick = (option: string) => {
    if (selectedOptions.includes(option)) {
      onChange(selectedOptions.filter((o) => o !== option));
    } else {
      onChange(allowMultiple ? [...selectedOptions, option] : [option]);
    }
  };

  return (
    <TagRow>
      {options.map((option, i) => (
        <Tag
          key={i}
          onClick={() => handleTagClick(option)}
          isSelected={selectedOptions.includes(option)}
        >
          # {option}
        </Tag>
      ))}
    </TagRow>
  );
}
