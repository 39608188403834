import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PageWrapper from './PageWrapper';
import ContentCard, { CardLabelUppercase, CardHeader } from './ContentCard';
import Badge from './elements/Badge';
import { H1, H2, P } from './elements/Text';
import Modal from './Modal/Modal';
import Markdown from './Markdown';
import { CloseIcon } from './Icons';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ProjectExamplesPage({ examples }) {
  const query = useQuery();
  const history = useHistory();

  const topicTags = [
    'All',
    ...new Set(examples.map((e) => e.contentTags).flat()),
  ];
  const companyTags = ['All', ...new Set(examples.map((e) => e.companyType))];
  const roleTags = ['All', ...new Set(examples.map((e) => e.roleType))];
  const audienceTags = ['All', ...new Set(examples.map((e) => e.audienceType))];

  // 1 is added by the UI ("All"), 1 would be the default one; makes sense to show the filter if there's more than 1 option to filter by
  const shouldShowAudience = audienceTags.length > 2;

  const [topicFilter, setTopicFilter] = React.useState(
    topicTags.find((tag) => query.get('topic') === tag) || 'All'
  );
  const [companyFilter, setCompanyFilter] = React.useState(
    companyTags.find((tag) => query.get('company') === tag) || 'All'
  );
  const [roleFilter, setRoleFilter] = React.useState(
    roleTags.find((tag) => query.get('role') === tag) || 'All'
  );
  const [audienceFilter, setAudienceFilter] = React.useState(
    audienceTags.find((tag) => query.get('audience') === tag) || 'All'
  );

  React.useEffect(() => {
    history.push({
      search: `?topic=${topicFilter}&company=${companyFilter}&role=${roleFilter}&audience=${audienceFilter}`,
    });
  }, [history, topicFilter, companyFilter, roleFilter, audienceFilter]);

  return (
    <PageWrapper>
      <H1>Project Examples</H1>
      <P>Use this page to help you with project ideas!</P>
      <Filters
        examples={examples}
        topicTags={topicTags}
        topicFilter={topicFilter}
        setTopicFilter={setTopicFilter}
        companyTags={companyTags}
        companyFilter={companyFilter}
        setCompanyFilter={setCompanyFilter}
        roleTags={roleTags}
        roleFilter={roleFilter}
        setRoleFilter={setRoleFilter}
        audienceTags={audienceTags}
        audienceFilter={audienceFilter}
        setAudienceFilter={setAudienceFilter}
        shouldShowAudience={shouldShowAudience}
      />
      <Examples
        examples={examples}
        topicFilter={topicFilter}
        companyFilter={companyFilter}
        roleFilter={roleFilter}
        audienceFilter={audienceFilter}
        shouldShowAudience={shouldShowAudience}
      />
    </PageWrapper>
  );
}

function Dropdown({ label, options, value, onChange }) {
  return (
    <div>
      <Badge label={label} style={{ marginBottom: '12px' }} />
      <select
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((t) => (
          <option key={`option-dropdown-${label}-${t}`} value={t}>
            {t}
          </option>
        ))}
      </select>
    </div>
  );
}

function Filters({
  topicFilter,
  setTopicFilter,
  companyFilter,
  setCompanyFilter,
  roleFilter,
  setRoleFilter,
  roleTags,
  companyTags,
  topicTags,
  audienceTags,
  audienceFilter,
  setAudienceFilter,
  shouldShowAudience,
}) {
  return (
    <div>
      <H2>Filters</H2>
      <Row>
        {shouldShowAudience && (
          <Dropdown
            label="Custom"
            options={audienceTags}
            value={audienceFilter}
            onChange={setAudienceFilter}
          />
        )}
        <Dropdown
          label="Topic"
          options={topicTags}
          value={topicFilter}
          onChange={setTopicFilter}
        />
        <Dropdown
          label="Company Type"
          options={companyTags}
          value={companyFilter}
          onChange={setCompanyFilter}
        />
        <Dropdown
          label="Role Type"
          options={roleTags}
          value={roleFilter}
          onChange={setRoleFilter}
        />
      </Row>
    </div>
  );
}

const ExamplesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 32px;
  margin: 32px 0px;
`;

function Examples({
  examples,
  topicFilter,
  companyFilter,
  roleFilter,
  audienceFilter,
  shouldShowAudience,
}) {
  const [filteredExamples, setFilteredExamples] = React.useState([]);

  React.useEffect(() => {
    setFilteredExamples(
      examples
        .filter((e) =>
          topicFilter === 'All' ? true : e.contentTags.includes(topicFilter)
        )
        .filter((e) =>
          companyFilter === 'All' ? true : e.companyType === companyFilter
        )
        .filter((e) =>
          roleFilter === 'All' ? true : e.roleType === roleFilter
        )
        .filter((e) =>
          audienceFilter === 'All' ? true : e.audienceType === audienceFilter
        )
    );
  }, [examples, topicFilter, companyFilter, roleFilter, audienceFilter]);

  return (
    <>
      <H2>Ideas</H2>
      <ExamplesGrid>
        {filteredExamples.map((example) => (
          <ExampleCard
            example={example}
            key={`example-card-${example.title}`}
            shouldShowAudience={shouldShowAudience}
          />
        ))}
      </ExamplesGrid>
    </>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledModal = styled(Modal)`
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};
  h1 {
    /* remove from h1 top margin the height of the close icon*/
    margin-top: calc(105px - 64px);
  }
`;

const Icon = styled(CloseIcon)`
  padding: 20px 0px;

  @media screen and (max-width: 768px) {
    padding: 20px 0;
  }
  padding: 32px 0;
`;

function ExampleModal({ example, onCloseModal }) {
  return (
    <StyledModal>
      <Icon onClick={onCloseModal} style={{ alignSelf: 'flex-end' }} />
      <Markdown md={example.description} />
      <div height="52px" style={{ marginBottom: '52px' }} />
    </StyledModal>
  );
}

function ExampleCard({ example, shouldShowAudience }) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <>
      {isExpanded && (
        <ExampleModal
          example={example}
          onCloseModal={() => setIsExpanded(false)}
        />
      )}
      <ContentCard
        buttonProps={[
          {
            label: 'See Example',
            type: 'default',
            onClick: () => setIsExpanded(!isExpanded),
          },
        ]}
      >
        <CardHeader>{example.title}</CardHeader>

        <CardLabelUppercase>Topic</CardLabelUppercase>
        <Row>
          {example.contentTags.map((tag) => (
            <Badge label={tag} key={`content-tag-${tag}-${example.title}`} />
          ))}
        </Row>
        <Row style={{ marginTop: '16px' }}>
          {shouldShowAudience && (
            <Column>
              <CardLabelUppercase>audience</CardLabelUppercase>
              <Row>
                <Badge label={example.audienceType} />
              </Row>
            </Column>
          )}
          <Column>
            <CardLabelUppercase>company</CardLabelUppercase>
            <Row>
              <Badge label={example.companyType} />
            </Row>
          </Column>

          <Column>
            <CardLabelUppercase>role</CardLabelUppercase>
            <Row>
              <Badge label={example.roleType} />
            </Row>
          </Column>
        </Row>
      </ContentCard>
    </>
  );
}
