import styled, { useTheme } from 'styled-components';
import { NAVBAR_HEIGHT } from '../../Navbar';
import Markdown from '../../Markdown';
import Button from '../../elements/Button';
import { QuestionProps } from '.';
import { PSmall } from '../../elements/Text';

export function getQuestionText(question: QuestionProps['question']) {
  const introLesson = question.intro_lesson_text;
  const instructions = question.text;

  const questionText = introLesson
    ? `${introLesson}\n\n---\n\n### Instructions\n\n${instructions}`
    : instructions;

  return questionText;
}

const FullScreen = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 2 * ${NAVBAR_HEIGHT}px);
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

const FullWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 32px 32px;
  width: 100%;
  height: calc(100vh - 2 * ${NAVBAR_HEIGHT}px);
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};
`;

const SplitScreen = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 2 * ${NAVBAR_HEIGHT}px);
`;

const LeftWrapper = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 64px;
  overflow-y: auto;
  height: calc(100vh - 2 * ${NAVBAR_HEIGHT}px);
`;

const RightWrapper = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  height: calc(100vh - 2 * ${NAVBAR_HEIGHT}px);
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  gap: 16px;
`;

export const ReadableMarkdown = styled(Markdown)`
  > * {
    max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};
  }
  .full-width {
    max-width: ${({ theme }) => theme.dimensions.largeColumnWidth};
  }
  blockquote {
    transform: translateX(-18px);
  }
`;

export const ReadableContainer = styled.div`
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};
`;

export function SplitScreenQuestion({
  children,
}: {
  children: [React.ReactNode, React.ReactNode];
}) {
  return (
    <SplitScreen>
      <LeftWrapper>{children[0]}</LeftWrapper>
      <RightWrapper>{children[1]}</RightWrapper>
    </SplitScreen>
  );
}

export function FullScreenQuestion({
  style,
  children,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <FullScreen>
      <FullWrapper style={style}>{children}</FullWrapper>
    </FullScreen>
  );
}

const StyledButton = styled(Button)`
  margin-top: 0;
`;

export const SubmitButton = ({
  onClick,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled: boolean;
}) => {
  const theme = useTheme();

  return (
    <StyledButton
      label="Submit"
      onClick={onClick}
      isDisabled={isDisabled}
      type="primary"
      color={isDisabled ? theme.colors.textRegular : theme.colors.buttonPrimary}
    />
  );
};

export const HintButton = ({ onClick }: { onClick: () => void }) => {
  const theme = useTheme();
  return (
    <StyledButton
      label="Hint"
      onClick={onClick}
      faIcon="fa-duotone fa-sparkles"
      showIconBeforeLabel
      color={theme.colors.buttonSecondary}
    />
  );
};

export const BottomSpacer = styled.div`
  min-height: 32px;
`;

const HeaderContainer = styled.div`
  padding: 20px;
  width: 100%;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  justify-content: space-between;
  overflow-x: scroll;
  gap: 20px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const HeaderText = styled(PSmall)`
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

export const Header = ({
  text,
  children,
}: {
  text: string;
  children?: React.ReactNode;
}) => (
  <HeaderContainer>
    <HeaderRow>
      <HeaderText>{text}</HeaderText>
      {children && <HeaderGroup>{children}</HeaderGroup>}
    </HeaderRow>
  </HeaderContainer>
);

export const EmptyContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bgActive};
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const HeaderAction = styled(PSmall)`
  margin-top: 0 !important;
  padding-bottom: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;

  color: ${({ theme }) => theme.colors.secondary};
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;
