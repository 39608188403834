import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import PageWithAsyncData from '../components/PageWithAsyncData';
import Markdown from '../components/Markdown';
import useAsyncRetryWithCrashReporter from '../hooks/use-async-retry-with-crash-reporter';
import useSQLRunner from '../components/SQLPlayground/use-sql-runner';
import SQLPlayground from '../components/SQLPlayground';

const PageContentWrapper = styled.div`
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh;
  flex: 1;
`;

const QuestionTextWrapper = styled.div`
  padding: 32px 16px;
`;

export default function Playground() {
  const {
    loading: isLoading,
    value: question,
    error,
    retry,
  } = useAsyncRetryWithCrashReporter(async () => {
    const { appUser, jsonUrl } = queryString.parse(document.location.search);
    console.log(`appUser=${appUser}`);
    if (!jsonUrl) {
      throw new Error(
        `Sorry, looks like you're missing the url for the playground data`
      );
    }
    const result = await fetch(jsonUrl).then((res) => res.json());
    return result;
  }, []);

  const emptyAnswer = {
    questionSlug: question?.slug,
    value: '',
    isCorrect: null,
    isRan: null,
    query: question?.query ?? '',
    result: { type: 'EMPTY' },
  };
  const [answer, setAnswer] = React.useState(emptyAnswer);

  const {
    isLoading: isLoadingSQL,
    schemas,
    error: errorSQL,
    runQuery,
    resetDb,
  } = useSQLRunner({
    id: 'playground',
    setupSQL: question?.setup_sql,
  });
  // initialStateActivityQuestionAnswer.PLAYGROUND

  const onReset = () => {
    setAnswer(emptyAnswer);
    resetDb();
  };

  const onChange = (query) => {
    setAnswer({
      ...answer,
      query,
    });
  };

  const onRun = (query) => {
    const result = runQuery(query);
    setAnswer({
      ...answer,
      isCorrect: false,
      isRan: true,
      result,
    });
  };

  return (
    <PageWithAsyncData error={error} isLoading={isLoading} retry={retry}>
      {question && (
        <PageContentWrapper>
          <QuestionTextWrapper>
            <Markdown md={question.text} />
          </QuestionTextWrapper>
          <SQLPlayground
            schemas={schemas}
            query={answer.query}
            onQueryChange={onChange}
            onRunQuery={onRun}
            onReset={onReset}
            isLoading={isLoadingSQL}
            result={answer.result}
            error={errorSQL}
          />
        </PageContentWrapper>
      )}
    </PageWithAsyncData>
  );
}
