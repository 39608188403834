import { ContextType, EventType } from './state-machine';

export enum GUARDS {
  DID_SELECT_LEARNING_GOAL = 'DID_SELECT_LEARNING_GOAL',
  DID_SELECT_LEARNING_GOAL_AND_TOPIC = 'DID_SELECT_LEARNING_GOAL_AND_TOPIC',
  DID_SELECT_TOPIC = 'DID_SELECT_TOPIC',
  DID_SELECT_TOPIC_AND_MODULE = 'DID_SELECT_TOPIC_AND_MODULE',
  DID_SELECT_GOAL = 'DID_SELECT_GOAL',
  DID_SELECT_MODULE_KNOWLEDGE = 'DID_SELECT_MODULE_KNOWLEDGE',
  DID_SELECT_TIME_EFFORT = 'DID_SELECT_TIME_EFFORT',
  DID_ADD_EMAIL = 'DID_ADD_EMAIL',
  HAS_PARTNER_SIGNUP_CODE = 'HAS_PARTNER_SIGNUP_CODE',
  IS_EMAIL_VALID_ERROR = 'IS_EMAIL_VALID_ERROR',
  IS_WRONG_EMAIL_ERROR = 'IS_WRONG_EMAIL_ERROR',
  SHOULD_SKIP_PAYMENT = 'SHOULD_SKIP_PAYMENT',
  IS_APP_USER = 'IS_APP_USER',
}

export default {
  [GUARDS.DID_SELECT_LEARNING_GOAL]: (context: ContextType) => {
    return !!context.learningGoal;
  },
  [GUARDS.DID_SELECT_LEARNING_GOAL_AND_TOPIC]: (context: ContextType) => {
    return !!context.learningGoal && !!context.topic;
  },
  [GUARDS.DID_SELECT_TOPIC]: (context: ContextType) => {
    return !!context.topic;
  },
  [GUARDS.DID_SELECT_TOPIC_AND_MODULE]: (context: ContextType) => {
    return !!context.topic && !!context.moduleSlug;
  },
  [GUARDS.DID_SELECT_GOAL]: (context: ContextType) => {
    return !!context.goal;
  },
  [GUARDS.DID_SELECT_MODULE_KNOWLEDGE]: (context: ContextType) => {
    return (context.moduleKnowledge ?? []).length > 0;
  },
  [GUARDS.DID_SELECT_TIME_EFFORT]: (context: ContextType) => {
    return !!context.timeEffort;
  },
  [GUARDS.DID_ADD_EMAIL]: (context: ContextType) => {
    return !!context.email;
  },
  [GUARDS.SHOULD_SKIP_PAYMENT]: (context: ContextType) => {
    return !!context.shouldSkipPayment;
  },
  [GUARDS.IS_APP_USER]: (context: ContextType) => {
    return !!context.isAppUser;
  },
  [GUARDS.HAS_PARTNER_SIGNUP_CODE]: (
    context: ContextType,
    event: EventType
  ) => {
    // @ts-expect-error it's hard to type these events...
    return !!context.partnerCode || !!event?.data?.partnerCode;
  },
  [GUARDS.IS_EMAIL_VALID_ERROR]: (_: ContextType, event: EventType) => {
    // @ts-expect-error didn't type the onError response event;
    const errorMessage = event?.data?.response?.data?.message;
    return (
      errorMessage ===
      'Sorry, this email is not available. Try logging in instead.'
    );
  },
  [GUARDS.IS_WRONG_EMAIL_ERROR]: (_: ContextType, event: EventType) => {
    // @ts-expect-error didn't type the onError response event;
    const errorMessage = event?.data?.response?.data?.message;
    return (
      errorMessage === 'Sorry, this looks like the wrong account.' ||
      errorMessage === 'Sorry, we could not find your account'
    );
  },
};
