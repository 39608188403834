export default function isPythonPlaygroundQuestionCorrect({
  code,
  output,
  verificationOutputRegexes,
  verificationInputRegexes,
}: {
  code: string;
  output: string;
  verificationOutputRegexes: string[];
  verificationInputRegexes: string[];
}) {
  // there's cases where the code has no output, but we stil verify it
  if (!code) {
    return false;
  }

  const didFailOutputVerification = verificationOutputRegexes.some((regex) => {
    return !new RegExp(regex, 'g').test(output);
  });

  if (didFailOutputVerification) {
    return false;
  }

  const didFailInputVerification = verificationInputRegexes.some((regex) => {
    return !new RegExp(regex, 'g').test(code);
  });

  if (didFailInputVerification) {
    return false;
  }

  return true;
}
