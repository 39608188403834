import styled from 'styled-components';
import { prop } from 'styled-tools';

export const H1 = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: ${prop('theme.colors.text')};
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
`;

export const H3 = styled.h3`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
`;

export const H4 = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 36px;
`;

export const H5 = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
`;

export const P = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.textRegular};
`;

export const PSmall = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.textRegular};
`;

export const PExtraSmall = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.textRegular};
`;

export const LabelUppercase = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.textRegular};
`;

export const CodeSmall = styled.p`
  font-family: Roboto Mono;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-top: 0;
  padding-bottom: 0;
`;
