import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  CenteredContainer,
  Header,
  MainPillButton,
  ButtonsWrapper,
  ReadableContainer,
  BackgroundContainer,
} from './common';
import { LEARNING_GOALS_TO_TOPICS, TOPICS_DATA } from '../config';
import * as Analytics from '../../../analytics';

export default function AskTopic() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const learningGoal = state.context.learningGoal;
  const topics = learningGoal
    ? LEARNING_GOALS_TO_TOPICS[learningGoal].topics
    : [];

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_TOPIC,
    state.context
  );

  return (
    <BackgroundContainer>
      <ReadableContainer>
        <CenteredContainer>
          <Header>What do you want to learn first?</Header>
          <ButtonsWrapper>
            {topics.map((topic) => (
              <MainPillButton
                iconUrl={TOPICS_DATA[topic].iconUrl}
                label={TOPICS_DATA[topic].label}
                // TODO: add support for images
                key={topic}
                onClick={() => {
                  send({ type: EVENTS.SELECT_TOPIC, topic });
                  send({ type: EVENTS.CONTINUE_ASK_TOPIC });
                }}
              />
            ))}
          </ButtonsWrapper>
        </CenteredContainer>
      </ReadableContainer>
    </BackgroundContainer>
  );
}
