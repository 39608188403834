export const LEARNING_GOALS = {
  LEARN_TO_CODE: 'LEARN_TO_CODE',
  DATA_SKILLS: 'DATA_SKILLS',
  BI_TOOLS: 'BI_TOOLS',
};

export const TOPICS = {
  PYTHON: 'PYTHON',
  SHEETS: 'SHEETS',
  SQL: 'SQL',
  DATA_LITERACY: 'DATA_LITERACY',
  LOOKER: 'LOOKER',
  TABLEAU: 'TABLEAU',
  POWER_BI: 'POWER_BI',
};

export const PROFILES = {
  SALES_MARKETING: {
    value: 'SALES_MARKETING',
    label: 'Sales / Marketing',
  },
  PRODUCT_ENGINEERING: {
    value: 'PRODUCT_ENGINEERING',
    label: 'Product / Engineering',
  },
  OERATIONS_SUPPORT: {
    value: 'OERATIONS_SUPPORT',
    label: 'Operations / Support',
  },
  MANAGER_EXEC: {
    value: 'MANAGER_EXEC',
    label: 'Manager / Exec',
  },
  STUDENT: {
    value: 'STUDENT',
    label: 'Student',
  },
  OTHER: {
    value: 'OTHER',
    label: 'Other',
  },
};

export const GOALS = {
  OPTIMIZE_SKILLS: {
    value: 'OPTIMIZE_SKILLS',
    label: 'Optimize your efficiency or skills',
  },
  ACCELERATE_CAREER: {
    value: 'ACCELERATE_CAREER',
    label: 'Accelerate your career',
  },
  FIND_A_JOB: {
    value: 'FIND_A_JOB',
    label: 'Find a job',
  },
  SWITCH_TECHNICAL: {
    value: 'SWITCH_TECHNICAL',
    label: 'Switch to a technical role',
  },
  BUILD_LEARNING_HABIT: {
    value: 'BUILD_LEARNING_HABIT',
    label: 'Build a learning habit',
  },
  EXPLORE_TEAMS: {
    value: 'EXPLORE_TEAMS',
    label: 'Explore if Enki is useful for your team',
  },
  OTHER: {
    value: 'OTHER',
    label: 'Other',
  },
};

export const TIME_EFFORT = {
  UNSURE: {
    value: 'UNSURE',
    label: 'Unsure',
  },
  MINUTES_5_10: {
    value: 'MINUTES_5_10',
    label: '5-10 minutes / day',
  },
  MINUTES_20_30: {
    value: 'MINUTES_20_30',
    label: '20-30 minutes / day',
  },
  MORE: {
    value: 'MORE',
    label: 'More',
  },
};

export const LEARNING_GOALS_DATA = {
  LEARN_TO_CODE: {
    label: 'Learning to code',
    subtitle: 'Python',
    faIcon: 'fa-solid fa-code',
    topic: TOPICS.PYTHON,
  },
  DATA_SKILLS: {
    label: 'Data skills',
    subtitle: 'SQL, Spreadsheets, Data Proficiency',
    faIcon: 'fa-solid fa-chart-tree-map',
    topic: null,
  },
  BI_TOOLS: {
    label: 'Business Intelligence tools',
    subtitle: 'Tableau, Power BI, Looker',
    faIcon: 'fa-solid fa-chart-bullet',
    topic: null,
  },
};

const COMPLETE_BEGINNER_PLACEMENT_OPTION = 'complete_beginner';

export const TOPICS_DATA = {
  [TOPICS.PYTHON]: {
    label: 'Python',
    iconUrl: '',
    placementTest: {
      header: 'Choose all that are true about your Python knowledge',
      options: [
        {
          text: 'Complete beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          isCompleteBeginner: true,
          moduleSlug: 'python-101',
        },
        {
          text: "I'm familiar with data types and variables",
          value: 'data_types_variables',
          isCompleteBeginner: false,
          moduleSlug: 'python-101',
        },
        {
          text: 'I can use conditional statements (if/else)',
          value: 'conditional_statements',
          isCompleteBeginner: false,
          moduleSlug: 'python-101',
        },
        {
          text: 'I know how to use loops (for/while)',
          value: 'loops',
          isCompleteBeginner: false,
          moduleSlug: 'python-101',
        },
        {
          text: 'I can define and use functions',
          value: 'functions',
          isCompleteBeginner: false,
          moduleSlug: 'python-101',
        },
        {
          text: "I'm comfortable with lists and dictionaries",
          value: 'lists_dictionaries',
          isCompleteBeginner: false,
          moduleSlug: 'python-101',
        },
      ],
    },
  },
  [TOPICS.SQL]: {
    label: 'SQL',
    iconUrl: 'https://img.enkipro.com/80a6a34160ca3ad5e31ccba0cdcb653b.png',
    placementTest: {
      header: 'Choose all that are true about your SQL knowledge',
      options: [
        {
          text: 'Complete beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          isCompleteBeginner: true,
          moduleSlug: 'sql-101',
        },
        {
          text: 'I can use WHERE to filter data',
          value: 'where_filter',
          isCompleteBeginner: false,
          moduleSlug: 'sql-101',
        },
        {
          text: 'I can perform JOINs',
          value: 'join_operations',
          isCompleteBeginner: false,
          moduleSlug: 'sql-101',
        },
        {
          text: 'I know how to use aggregate functions',
          value: 'aggregate_functions',
          isCompleteBeginner: false,
          moduleSlug: 'sql-101',
        },
        {
          text: "I'm familiar with window functions",
          value: 'window_functions',
          isCompleteBeginner: false,
          moduleSlug: 'sql-102',
        },
        {
          text: 'I can use subqueries',
          value: 'subqueries',
          isCompleteBeginner: false,
          moduleSlug: 'sql-102',
        },
        {
          text: "I'm familiar with self-joins",
          value: 'self_joins',
          isCompleteBeginner: false,
          moduleSlug: 'sql-102',
        },
      ],
    },
  },
  [TOPICS.SHEETS]: {
    label: 'Spreadsheets',
    iconUrl: 'https://img.enkipro.com/3b081c6024bde04a199fbbb0416a0e14.png',
    placementTest: {
      header: 'Choose all that are true about your Spreadsheets knowledge',
      options: [
        {
          text: 'Complete beginner!',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          isCompleteBeginner: true,
          moduleSlug: 'sheets-101',
        },
        {
          text: 'I know how to format my spreadsheet',
          value: 'format_spreadsheet',
          isCompleteBeginner: false,
          moduleSlug: 'sheets-101',
        },
        {
          text: "I'm confident with AVERAGE and SUM",
          value: 'average_sum',
          isCompleteBeginner: false,
          moduleSlug: 'sheets-101',
        },
        {
          text: 'I can effectively use IF functions',
          value: 'if_functions',
          isCompleteBeginner: false,
          moduleSlug: 'sheets-101',
        },
        {
          text: "I'm confident with Pivot Tables",
          value: 'pivot_tables',
          isCompleteBeginner: false,
          moduleSlug: 'sheets-102',
        },
        {
          text: 'I am using VLOOKUP, INDEX, and MATCH',
          value: 'vlookup_index_match',
          isCompleteBeginner: false,
          moduleSlug: 'sheets-102',
        },
        {
          text: 'I know how to record and use a macro',
          value: 'macro_use',
          isCompleteBeginner: false,
          moduleSlug: 'sheets-102',
        },
      ],
    },
  },
  [TOPICS.DATA_LITERACY]: {
    label: 'Data Literacy',
    iconUrl: 'https://img.enkipro.com/91c86556f4b8645ea64753c8301ddd95.png',
    placementTest: {
      header: 'Do you have any experience with data analysis?',
      options: [
        {
          text: 'No - complete beginner',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          isCompleteBeginner: true,
          moduleSlug: 'sheets-101',
        },
        {
          text: 'A little, but I only know the basics',
          value: 'know_basics',
          isCompleteBeginner: false,
          moduleSlug: 'data-101',
        },
        {
          text: 'Yes, but I want to know all the best practices',
          value: 'best_practices',
          isCompleteBeginner: false,
          moduleSlug: 'data-101',
        },
      ],
    },
  },
  [TOPICS.LOOKER]: {
    label: 'Looker',
    iconUrl: 'https://img.enkipro.com/071e973f2b87d1f37e6eb60c20692926.png',
    placementTest: {
      header: 'Have you worked with Looker before?',
      options: [
        {
          text: 'No - complete beginner',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          isCompleteBeginner: true,
          moduleSlug: 'looker-2-weeks',
        },
        {
          text: 'A little, but I only know the basics',
          value: 'know_basics',
          isCompleteBeginner: false,
          moduleSlug: 'looker-2-weeks',
        },
        {
          text: "I'm OK but want more practice or training",
          value: 'want_training',
          isCompleteBeginner: false,
          moduleSlug: 'looker-2-weeks',
        },
      ],
    },
  },
  [TOPICS.TABLEAU]: {
    label: 'Tableau',
    iconUrl: 'https://img.enkipro.com/d4807185a2a162aec22f868044b47585.png',
    placementTest: {
      header: 'Have you worked with Tableau before?',
      options: [
        {
          text: 'No - complete beginner',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          isCompleteBeginner: true,
          moduleSlug: 'tableau',
        },
        {
          text: 'A little, but I only know the basics',
          value: 'know_basics',
          isCompleteBeginner: false,
          moduleSlug: 'tableau',
        },
        {
          text: "I'm OK but want more practice or training",
          value: 'want_training',
          isCompleteBeginner: false,
          moduleSlug: 'tableau',
        },
      ],
    },
  },
  [TOPICS.POWER_BI]: {
    label: 'Power BI',
    iconUrl: 'https://img.enkipro.com/f470316005d0ee81790c12de3edb6820.png',
    placementTest: {
      header: 'Have you worked with Power BI before?',
      options: [
        {
          text: 'No - complete beginner',
          value: COMPLETE_BEGINNER_PLACEMENT_OPTION,
          isCompleteBeginner: true,
          moduleSlug: 'power-bi-101',
        },
        {
          text: 'A little, but I only know the basics',
          value: 'know_basics',
          isCompleteBeginner: false,
          moduleSlug: 'power-bi-101',
        },
        {
          text: "I'm OK but want more practice or training",
          value: 'want_training',
          isCompleteBeginner: false,
          moduleSlug: 'power-bi-101',
        },
      ],
    },
  },
};

export const LEARNING_GOALS_TO_TOPICS = {
  [LEARNING_GOALS.LEARN_TO_CODE]: {
    topics: [TOPICS.PYTHON],
  },
  [LEARNING_GOALS.DATA_SKILLS]: {
    topics: [TOPICS.SHEETS, TOPICS.SQL, TOPICS.DATA_LITERACY],
  },
  [LEARNING_GOALS.BI_TOOLS]: {
    topics: [TOPICS.LOOKER, TOPICS.TABLEAU, TOPICS.POWER_BI],
  },
};

export function updateModuleKnowledge({
  selectedOptions,
  optionValue,
}: {
  selectedOptions: string[];
  optionValue: string;
}) {
  const hasSelectedCompleteBeginner = selectedOptions.includes(
    COMPLETE_BEGINNER_PLACEMENT_OPTION
  );

  if (optionValue === COMPLETE_BEGINNER_PLACEMENT_OPTION) {
    return hasSelectedCompleteBeginner ? [] : [optionValue];
  }

  const optionsWithoutCompleteBeginner = selectedOptions.filter(
    (selectedOption) => selectedOption !== COMPLETE_BEGINNER_PLACEMENT_OPTION
  );

  if (selectedOptions.includes(optionValue)) {
    return optionsWithoutCompleteBeginner.filter(
      (selectedOption) => selectedOption !== optionValue
    );
  }

  return [...optionsWithoutCompleteBeginner, optionValue];
}

export function selectModuleFromModuleKnowledge({
  topic,
  moduleKnowledge,
}: {
  topic: string;
  moduleKnowledge: string[];
}) {
  const topicData = TOPICS_DATA[topic]?.placementTest?.options || [];

  const completeBeginnerOption = topicData.find(
    (option) =>
      option.isCompleteBeginner && moduleKnowledge.includes(option.value)
  );
  if (completeBeginnerOption) {
    return completeBeginnerOption.moduleSlug;
  }

  const moduleFrequency = new Map<string, number>();

  topicData.forEach((option) => {
    if (moduleKnowledge.includes(option.value)) {
      moduleFrequency.set(
        option.moduleSlug,
        (moduleFrequency.get(option.moduleSlug) || 0) + 1
      );
    }
  });

  // Determining the moduleSlug with the highest frequency
  let selectedModuleSlug = '';
  let maxFrequency = 0;

  moduleFrequency.forEach((frequency, moduleSlug) => {
    if (
      frequency > maxFrequency ||
      (frequency === maxFrequency && !selectedModuleSlug)
    ) {
      selectedModuleSlug = moduleSlug;
      maxFrequency = frequency;
    }
  });

  return selectedModuleSlug;
}
