import styled from 'styled-components';
import { CodeSmall, PSmall } from '../elements/Text';
import { SQLResult } from '../../contexts/activity/helpers/is-sql-playground-question-correct';

const SQLErrorText = styled.pre`
  font-family: 'Roboto Mono';
  font-weight: 500;
  line-height: 20px;
  font-size: 15px;
  color: #ffffff;
  .ace_cursor {
    color: #70ffbc;
  }
  background-color: transparent;
  margin-top: 0;
  padding-bottom: 0;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 4px 20px;
  min-width: 0;
  min-height: 0;
  overflow: auto;
  flex: 1;
  height: 100%;
`;

const Container = styled.div`
  max-width: 0;
  width: 100%;
`;

const CodePlaceholder = styled(CodeSmall)`
  color: ${({ theme }) => theme.colors.textPlaceholder};
`;

export default function SQLPlaygroundOutput({ output }: { output: SQLResult }) {
  if (output.type === 'EMPTY') {
    return (
      <Wrapper>
        <CodePlaceholder>Run a query to see the result</CodePlaceholder>
      </Wrapper>
    );
  }

  if (output.type === 'ERROR') {
    return (
      <Wrapper>
        <SQLErrorText>{output.value}</SQLErrorText>
      </Wrapper>
    );
  }

  if (!Array.isArray(output.value)) {
    return (
      <Wrapper>
        <PSmall>{output.value}</PSmall>
      </Wrapper>
    );
  }

  const [{ columns = [], values = [] } = {}] = output?.value ?? [{}];

  return (
    <Wrapper>
      <Container>
        <SQLPlaygroundOutputTable>
          <thead>
            <tr>
              {columns.map((col, i) => (
                <th key={i}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values.map((row, i) => (
              <tr key={i}>
                {row.map((value, i) => (
                  <td key={i}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </SQLPlaygroundOutputTable>
      </Container>
    </Wrapper>
  );
}

const SQLPlaygroundOutputTable = styled.table`
  background-color: transparent;
  margin-top: 8px;

  th {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */
    letter-spacing: 0.025em;
    color: ${(props) => props.theme.colors.textRegular};
    padding: 8px;
  }

  td {
    color: ${(props) => props.theme.colors.textRegular};
    font-family: Roboto Mono;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 8px;
  }

  thead {
    border-bottom: 1px solid ${(props) => props.theme.colors.divider};
  }
`;
