import { ACTIVITY_ACTION, ActivityContextType } from '..';
import updateQuestion from '../helpers/update-question';
export type ChangeCodeMultipleChoiceQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_CODE_MULTIPLE_CHOICE_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    code: string;
  };
};
export function multipleChoiceCodeChangeReducer(
  state: ActivityContextType,
  action: ChangeCodeMultipleChoiceQuestionActionType
) {
  const { question, code } = action.payload;

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      working_code: code,
      isRan: false,
    },
  });
}

export type ChangeAnswerMultipleChoiceQuestionActionType = {
  type: typeof ACTIVITY_ACTION.CHANGE_ANSWER_MULTIPLE_CHOICE_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    answer: string;
  };
};

export function MultipleChoiceAnswerChangeReducer(
  state: ActivityContextType,
  action: ChangeAnswerMultipleChoiceQuestionActionType
) {
  const { question, answer } = action.payload;
  const isCorrect = question.multiple_choice_answers?.correct_answer === answer;
  const answerState = {
    ...state.submission.questionSlugToAnswer[question.slug],
    value: answer,
  };

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      ...answerState,
      isAnswered: true,
      isCorrect,
    },
  });
}
