import React from 'react';
import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';
import Header from './Header';
import InputFooter from './InputFooter';
import Loader from '../Loader';
import { P, PSmall } from '../elements/Text';
import { STATES } from '.';
import Markdown from '../Markdown';
import { fromMarkdown } from 'mdast-util-from-markdown';
import { visit } from 'unist-util-visit';
import { useHistory } from 'react-router-dom';
import autoAnimate from '@formkit/auto-animate';
import { paymentPath } from '../../routing';

const CenteredContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 72px;
  text-align: center;
`;

const AIChatWrapperStyled = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 2;

  height: 90vh;
  width: min(
    ${prop('theme.dimensions.readableColumnWidth')},
    calc(100vw - 64px)
  );
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.navigation};

  & {
    opacity: 0;
    animation: fadeIn ease-in-out 0.3s;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 16px;
  overscroll-behavior: none;
`;

const FloatingChat = React.forwardRef(
  (
    {
      onClose,
      inputMessage,
      setInputMessage,
      connnectionState,
      chat,
      sendMessage,
      isReceivingMessage = false,
      shouldLock = false,
      dailyMessagesCount,
      className = '',
    },
    ref
  ) => {
    const history = useHistory();
    const parent = React.useRef(null);

    const onShowUpsell = () => {
      history.push(paymentPath);
    };

    React.useEffect(() => {
      parent.current &&
        autoAnimate(parent.current, {
          duration: 500,
          easing: 'ease-in-out',
        });
    }, [parent]);

    if (connnectionState === STATES.CONNECTING) {
      return (
        <AIChatWrapperStyled className={className}>
          <Header onClose={onClose} />
          <CenteredContainer>
            <Loader />
            <PSmall>Connecting you to the Enki AI…</PSmall>
          </CenteredContainer>
        </AIChatWrapperStyled>
      );
    }

    if (connnectionState === STATES.CLOSED) {
      return (
        <AIChatWrapperStyled className={className}>
          <Header onClose={onClose} />
          <CenteredContainer>
            <i
              style={{ marginBottom: '5px' }}
              className="fa-duotone fa-heart-crack fa-4x"
            ></i>
            <PSmall>
              Connection to <b>Enki AI</b> was lost.
            </PSmall>
          </CenteredContainer>
        </AIChatWrapperStyled>
      );
    }

    return (
      <AIChatWrapperStyled className={className}>
        <Header onClose={onClose} />
        {(chat?.messages ?? []).length === 0 ? (
          <CenteredContainer>
            <i
              style={{ marginBottom: '5px' }}
              className="fa-duotone fa-message-lines fa-4x"
            ></i>
            <P>
              <b>Ask Enki AI</b>
            </P>
            <PSmall>
              Try "Summarize this lesson", "Explain JOINs in simple terms", or
              "Best practices of Data Visualization"
            </PSmall>
          </CenteredContainer>
        ) : (
          <MessagesWrapper ref={parent}>
            {[...chat.messages].reverse().map((message, index) => (
              <AIChatMessage
                key={`chat-message-${message.id}-${
                  message.is_bot ? 'bot' : 'user'
                }`}
                message={message}
                isDisabled={isReceivingMessage}
                sendMessage={sendMessage}
                showTypingIndicator={
                  isReceivingMessage && message.is_bot && 0 === index
                }
                onShowUpsell={onShowUpsell}
              />
            ))}
            <div ref={ref} />
          </MessagesWrapper>
        )}

        <InputFooter
          input={inputMessage}
          onInputChange={setInputMessage}
          onSubmit={sendMessage}
          isDisabled={isReceivingMessage}
          shouldLock={shouldLock}
          dailyMessagesCount={dailyMessagesCount}
          onShowUpsell={onShowUpsell}
        />
      </AIChatWrapperStyled>
    );
  }
);

const TypingIndicator = () => {
  return (
    <p
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        gap: '8px',
      }}
    >
      <em>
        typing{' '}
        <i
          className="fas fa-ellipsis-h fa-fade"
          style={{ verticalAlign: 'bottom' }}
        ></i>
      </em>
    </p>
  );
};

export default FloatingChat;

const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;
`;

const MessageContent = styled(Markdown)`
  ${({ isBot, theme }) =>
    isBot &&
    css`
      color: ${theme.colors.text};
    `}

  ${({ isDisabled }) => isDisabled && 'pointer-events: none;'}

  max-width: calc(min(
    ${prop('theme.dimensions.readableColumnWidth')},
    calc(100vw - 64px)
  ) - 32px - 21px - 36px);
  /* chat width - left/right padding - avatar icon - gap */
`;

const MessageAvatar = styled.i.attrs((props) => ({
  className: `fa-duotone ${props.isBot ? 'fa-user-astronaut' : 'fa-user'}`,
}))`
  margin-top: 24px;

  font-size: 24px;

  ${({ isBot, theme }) =>
    isBot &&
    css`
      color: ${theme.colors.text};
    `}
`;

const ErrorLabel = styled(PSmall)`
  color: ${({ theme }) => theme.colors.textDanger};
`;

const MessageColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

// sometimes the llm model doesn't follow-up questions as markdown links
// this function tries to handle that and convert them to markdown links
function handleFollowupQuestions(content = '') {
  const DELIMITER = 'you can ask me:';
  const [mainContent = '', followUpQuestions = ''] = content.split(DELIMITER);
  const links = followUpQuestions
    ? followUpQuestions.split('\n').map((link, i) => {
        // regex to test for markdown links [text](url)
        const regex = /\[(.*?)\]\((.*?)\)/;
        const matches = link.match(regex);

        if (matches) {
          const [, text] = matches;
          return { text, url: `link-${i}` };
        }

        // remove "- " from the beginning of the line
        const text = link.replace(/^- /, '');
        return { text, url: `link-${i}` };
      })
    : [];

  const linksMarkdown =
    links.length > 0
      ? `
${DELIMITER}
${links.map(({ text, url }) => `- [${text}](${url})`).join('\n')}`
      : '';

  return `${mainContent}${linksMarkdown}`;
}

export function AIChatMessage({
  message,
  isDisabled,
  sendMessage,
  showTypingIndicator,
  onShowUpsell,
}) {
  const messageContentWithLinkifiedQuestions = handleFollowupQuestions(
    message.content
  );

  const ast = fromMarkdown(messageContentWithLinkifiedQuestions);

  const followUpUrlToLink = new Map();
  visit(ast, 'link', (node) => {
    if (!node.url.startsWith('http')) {
      followUpUrlToLink.set(node.url, node);
    }
  });

  return (
    <MessageRow>
      <MessageAvatar isBot={message.is_bot} />
      <MessageColumn>
        <MessageContent
          md={messageContentWithLinkifiedQuestions}
          isBot={message.is_bot}
          isDisabled={isDisabled}
          components={{
            a: ({ node, children }) => {
              const props = followUpUrlToLink.has(node?.properties?.href)
                ? {
                    onClick: (e) => {
                      e.preventDefault();
                      const linkText = concatNestedTextValues(node);
                      if (
                        linkText &&
                        linkText === 'Unlock Learn Mode' &&
                        onShowUpsell
                      ) {
                        onShowUpsell();
                        return;
                      }
                      if (linkText) {
                        sendMessage(linkText);
                      }
                    },
                    style: { cursor: 'pointer' },
                  }
                : {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    href: node.properties.href,
                  };
              return <a {...props}>{children}</a>;
            },
          }}
        />
        {showTypingIndicator && <TypingIndicator />}
        {message.is_error && (
          <ErrorLabel>Sorry, something went wrong.</ErrorLabel>
        )}
      </MessageColumn>
    </MessageRow>
  );
}

const concatNestedTextValues = (node) => {
  // condition to break the recursion
  if (!node) {
    return '';
  }

  if (Array.isArray(node.children)) {
    // map through the children, recursively calling the function for each child
    return node.children.map(concatNestedTextValues).join('');
  }

  // return the value of the current node
  return node.value || '';
};
