import styled from 'styled-components';
import { PSmall } from '../elements/Text';

const ReviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  background-color: #0000001a;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 20px;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const CommentIcon = styled.i.attrs({
  className: 'fa fa-comment-captions',
})`
  color: ${({ theme }) => theme.colors.textPrimaryInformation};
  font-size: 18px;
  margin: 0;
  padding: 0;
`;

const HeaderText = styled(PSmall)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textPrimaryInformation};
  margin: 0;
  padding: 0;
`;

const BodyWrapper = styled.div`
  padding: 16px 20px;
`;

const BodyText = styled(PSmall)`
  color: ${({ theme }) => theme.colors.textPrimaryInformation};
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
`;

export default function MentorReview({
  comment,
  rating,
}: {
  comment: string | null;
  rating?: number | null;
}) {
  return (
    <ReviewWrapper>
      <HeaderWrapper>
        <RowGroup>
          <CommentIcon />
          <HeaderText>Mentor's Review</HeaderText>
        </RowGroup>
        {
          // if rating is not provided, don't show the stars
          rating !== undefined && rating !== null && (
            <RatingStars rating={rating} />
          )
        }
      </HeaderWrapper>
      <BodyWrapper>
        <BodyText>{comment}</BodyText>
      </BodyWrapper>
    </ReviewWrapper>
  );
}

const StarsWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin: 0;
  padding: 0;
`;

const Star = styled.i.attrs({
  className: `fa fa-star`,
})<{ isActive?: boolean }>`
  color: ${({ theme }) => theme.colors.textPrimaryInformation};
  font-size: 14px;
  line-height: 20px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
`;

const RatingStars = ({ rating }: { rating: number }) => {
  const stars = Array.from({ length: 5 }, (_, i) => (
    <Star isActive={i < rating} />
  ));
  return <StarsWrapper>{stars}</StarsWrapper>;
};
