import { createRoot } from 'react-dom/client';
import Application from './pages/Application';
import './assets/css/theme.css';
import './assets/css/main.css';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<Application />);
}
