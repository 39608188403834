import styled from 'styled-components';
import { prop } from 'styled-tools';
import { PSmall } from '../../elements/Text';

const ColumnQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.dimensions.readableColumnWidth};
  justify-content: center;
  height: 100%;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 16px;
  gap: 8px;
`;

const SelectOptionWrapper = styled.div`
  width: 100%;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  appearance: none;
  padding: 8px;
  border: solid 1px !important;

  opacity: 0.8;
  border-color: ${prop('theme.colors.textRegular')};

  p {
    color: ${prop('theme.colors.textRegular')};
  }

  &:hover {
    opacity: 1;
  }

  cursor: pointer;
  display: block;
  outline: 0;
  text-decoration: none;
  ${({ isSelected, theme }) =>
    isSelected &&
    `
      border-color: ${theme.colors.primary} !important;
      p {
        color: ${theme.colors.primary} !important;
      }
      background-color: ${theme.colors.primaryBg};`}
`;

function SelectOption({ children, isSelected = false, index, onClick }) {
  return (
    <SelectOptionWrapper isSelected={isSelected} onClick={onClick}>
      <PSmall style={{ margin: 0, padding: 0 }}>{`${
        index + 1
      }. ${children}`}</PSmall>
    </SelectOptionWrapper>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export { SelectOption, Column, ColumnQuestionWrapper };
