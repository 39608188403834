import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ProgressBar } from 'react-step-progress-bar';
import { QUESTION_TYPES } from '../../../contexts/onboarding';
import Button from '../../elements/Button';
import InputQuestion from './InputQuestion';
import InputTimeTimezone from './InputTimeTimezone';
import MultiSelectQuestion from './MultiSelectQuestion';
import SingleSelectQuestion from './SingleSelectQuestion';
import useThrottledConsumer from '../../../hooks/use-throttled-consumer';
import * as CrashReporter from '../../../crash-reporter';
import { H3, P } from '../../elements/Text';

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  gap: 52px;
  padding-top: 52px;
`;

const AnimWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  flex: 1;
`;
export function Question({
  questions,
  onNext,
  onPrev,
  onAnswerQuestion,
  currentIndex,
}) {
  const question = questions[currentIndex];
  const isLastQuestion = currentIndex === questions.length - 1;

  const props = {
    index: currentIndex,
    question,
    onNext,
    onPrev,
    onAnswerQuestion,
    isLastQuestion,
  };

  if (question.type === QUESTION_TYPES.INPUT) {
    return <InputQuestion {...props} />;
  }
  if (question.type === QUESTION_TYPES.MULTI_SELECT) {
    return <MultiSelectQuestion {...props} />;
  }
  if (question.type === QUESTION_TYPES.SINGLE_SELECT) {
    return <SingleSelectQuestion {...props} />;
  }
  if (question.type === QUESTION_TYPES.INPUT_TIME_TIMEZONE) {
    return <InputTimeTimezone {...props} />;
  }
  return null;
}

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const FooterWrapper = styled.div`
  display: inline-block;
  position: relative;
  align-self: flex-end;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding-bottom: 23px;
`;

function Footer({ answeredCount, stepsCount, onGoBack, currentStep }) {
  const theme = useTheme();
  return (
    <FooterWrapper>
      <Button
        style={{ margin: 0 }}
        color={theme.colors.secondary}
        isDisabled={currentStep === 0}
        onClick={onGoBack}
        label={'Go Back'}
        faIcon={'fa fa-arrow-left'}
        showIconBeforeLabel
      />
      <ProgressWrapper>
        <P style={{ margin: 0 }}>
          {answeredCount} of {stepsCount} answered
        </P>
        <ProgressBar
          height={2}
          percent={(answeredCount / stepsCount) * 100}
          filledBackground={theme.colors.primary}
          unfilledBackground={theme.colors.textSecondary}
        />
      </ProgressWrapper>
    </FooterWrapper>
  );
}

export default function TypeForm({
  totalSteps,
  currentStep,
  onRefreshData = () => {},
  questions,
  currentIndex,
  onIncrementSurvey,
  onDecrementSurvey,
  onAnswerQuestion,
  onSubmitAnswers,
}) {
  const [submitError, setSubmitError] = React.useState(false);

  const isLastQuestion = currentIndex === questions.length - 1;
  const isFirstQuestion = currentIndex === 0;

  const consumeThrottled = useThrottledConsumer(1000);

  const submitAnswers = () =>
    consumeThrottled(async () => {
      await onSubmitAnswers()
        .catch((err) => {
          CrashReporter.reportError(err);
          setSubmitError(true);
        })
        .then(async () => {
          await onRefreshData();
        });
    });

  const onNext = () =>
    consumeThrottled(async () => {
      if (isLastQuestion) {
        await submitAnswers();
      } else {
        onIncrementSurvey();
      }
    });

  const onPrev = () =>
    consumeThrottled(() => {
      if (isFirstQuestion) {
        return;
      } else {
        onDecrementSurvey();
      }
    });

  if (submitError) {
    return (
      <Wrapper style={{ gap: '0' }}>
        <H3>Something went wrong.</H3>
        <P>Please reach out to us to get this fixed as soon as possible</P>
        <Button
          label="Get in touch"
          onClick={() => window.open('mailto:concierge@enki.com')}
          faIcon={'fas fa-envelope'}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <AnimWrapper>
        <Question
          questions={questions}
          currentIndex={currentIndex}
          onAnswerQuestion={onAnswerQuestion}
          onNext={onNext}
        />
      </AnimWrapper>
      <Footer
        currentStep={currentIndex}
        answeredCount={currentStep}
        stepsCount={totalSteps}
        onGoBack={onPrev}
      />
    </Wrapper>
  );
}
