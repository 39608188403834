import { Helmet } from 'react-helmet-async';
import PageWithAsyncData from '../components/PageWithAsyncData';
import { useProjectsDashboard } from '../hooks/use-hydra';
import {
  getAppliedProjectUrl,
  getHomeUrl,
  getProjectsPageTitle,
  projectsDashboardPath,
} from '../routing';
import Navbar from '../components/Navbar';
import PageWrapper from '../components/PageWrapper';
import styled from 'styled-components';
import { H2, P } from '../components/elements/Text';
import { AppliedProjectStat, joinAppliedProject } from '../hydra';
import { useHistory } from 'react-router-dom';
import Button from '../components/elements/Button';
import React from 'react';
import useAuth from '../hooks/use-auth';
import { toast } from 'react-toastify';
import { CardIcon } from '../components/ContentCard';
import MultiselectTags from '../components/MultiselectTags';

const ScrollablePageWrapper = styled.div`
  height: calc(100vh - 56px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;

const PageContentWrapper = styled(PageWrapper)`
  max-width: 1100px;
  margin: 0 auto 82px;
`;

export default function ProjectsDashboard() {
  const projectsQuery = useProjectsDashboard();

  return (
    <PageWithAsyncData
      isLoading={projectsQuery.loading}
      error={projectsQuery.error}
      retry={projectsQuery.retry}
      className={''}
    >
      <Helmet>
        <title>{getProjectsPageTitle()}</title>
      </Helmet>
      <Navbar
        breadCrumbs={[
          {
            label: 'Home',
            url: getHomeUrl(),
          },
          {
            label: 'Projects',
            url: projectsDashboardPath,
          },
        ]}
      />
      <Contents />
    </PageWithAsyncData>
  );
}

export function Contents() {
  const projectsDashboardState = useProjectsDashboard();
  const tags = [
    ...new Set(
      projectsDashboardState.value
        ?.map((ap) => ap.tags)
        .flat()
        .map((t) => t.toLowerCase())
    ),
  ];
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  return (
    <ScrollablePageWrapper>
      <PageContentWrapper>
        <H2>Projects</H2>
        <MultiselectTags
          selectedOptions={selectedTags}
          options={tags}
          onChange={setSelectedTags}
          allowMultiple
        />

        {projectsDashboardState.value && (
          <div>
            <AppliedProjectsGrid
              appliedProjects={projectsDashboardState.value.filter(
                (ap) =>
                  selectedTags.length === 0 ||
                  ap.tags.some((t) => selectedTags.includes(t.toLowerCase()))
              )}
            />
          </div>
        )}
      </PageContentWrapper>
    </ScrollablePageWrapper>
  );
}

const ProjectCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  opacity: 0.8;

  background-color: ${({ theme }) => theme.colors.bgDefault};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.bgActive};
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 1;
    transition: background-color 0s;
    transition: opacity 0s;
  }
`;

const ProjectCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
  flex-grow: 1;
`;

const ProjectTitle = styled(P)`
  margin-top: 0;
  flex-grow: 0;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
`;

const ProjectDescription = styled(P)`
  margin-top: 0.5em;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 2;
  flex-grow: 2;
  padding: 0;
`;

const GridContainer = styled.div`
  margin-top: 32px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));

  column-gap: 32px;
  row-gap: 32px;
`;

const CardButton = styled(Button)`
  width: 100%;
  border-radius: 0 0 8px 8px;
  background-color: ${({ theme }) => theme.colors.bgActive};
  color: white;
  padding: 12px 24px;
  margin: 0;
  flex-grow: 0;
`;

export function AppliedProjectsGrid({
  appliedProjects,
}: {
  appliedProjects: Array<AppliedProjectStat>;
}) {
  const history = useHistory();
  return (
    <GridContainer>
      {appliedProjects
        .filter((p) => p.is_joined)
        .map((ap) => {
          return (
            <ProjectCard
              key={ap.slug}
              project={ap}
              onCta={() => {
                history.push(getAppliedProjectUrl(ap.slug));
              }}
            />
          );
        })}
      {appliedProjects
        .filter((p) => !p.is_joined)
        .map((ap) => {
          return <UnenrolledProjectCard key={ap.slug} project={ap} />;
        })}
    </GridContainer>
  );
}

function UnenrolledProjectCard({ project }: { project: AppliedProjectStat }) {
  const [isJoining, setIsJoining] = React.useState(false);
  const { getTokenSilently } = useAuth();
  const history = useHistory();
  const onEnroll = async () => {
    setIsJoining(true);
    try {
      const token = await getTokenSilently();
      await joinAppliedProject(token, { projectSlug: project.slug });
      history.push(getAppliedProjectUrl(project.slug));
    } catch (err) {
      toast.error('Something went wrong');
    }
    setIsJoining(false);
  };
  return (
    <ProjectCard
      key={project.slug}
      project={project}
      onCta={onEnroll}
      btnIsLoading={isJoining}
    />
  );
}

const CategoryRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TopicLabel = styled(P)`
  font-size: 12px;
  margin-top: 10px;
  text-transform: uppercase;
`;

const CompletionIconWrapper = styled.i`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
`;

const SplitRow = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
`;
function ProjectCard({
  project,
  onCta,
  btnIsLoading = false,
}: {
  project: AppliedProjectStat;
  onCta: () => void;
  btnIsLoading?: boolean;
}) {
  const btnLabel = project.is_joined
    ? project.completedQuestions == project.question_count
      ? 'Review'
      : 'Continue'
    : 'Start';
  return (
    <ProjectCardWrapper>
      <ProjectCardContentWrapper>
        <SplitRow>
          <CategoryRow>
            <CardIcon
              url={project.image_url}
              title={`Project: ${project.name}`}
            />

            <TopicLabel>{project.topic} Project</TopicLabel>
          </CategoryRow>
          {project.completedQuestions == project.question_count && (
            <CompletionIconWrapper
              className="fa-solid fa-circle-check"
              title="Submitted"
            />
          )}
        </SplitRow>
        <ProjectTitle>{project.name}</ProjectTitle>
        <ProjectDescription>{project.description}</ProjectDescription>
        <ProjectDescription>
          {project.completedQuestions && `${project.completedQuestions}/`}
          {project.question_count} Exercises
        </ProjectDescription>
      </ProjectCardContentWrapper>
      <CardButton
        label={btnLabel}
        type="secondary"
        onClick={onCta}
        color={'#FFFFFF'}
        isLoading={btnIsLoading}
      />
    </ProjectCardWrapper>
  );
}
