import styled from 'styled-components';
import { PSmall } from '../elements/Text';
import Markdown from '../Markdown';

const ReviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  background-color: #0000001a;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 20px;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const CommentIcon = styled.i.attrs({
  className: 'fa fa-comment-captions',
})`
  color: ${({ theme }) => theme.colors.textPrimaryInformation};
  font-size: 18px;
  margin: 0;
  padding: 0;
`;

const HeaderText = styled(PSmall)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textPrimaryInformation};
  margin: 0;
  padding: 0;
`;

const BodyWrapper = styled.div`
  padding: 16px 20px;
`;

const BodyText = styled(Markdown)`
  color: ${({ theme }) => theme.colors.textPrimaryInformation};
  margin: 0;
  padding: 0;
`;

export default function TakeawaysContainer({ content }: { content: string }) {
  return (
    <ReviewWrapper>
      <HeaderWrapper>
        <RowGroup>
          <CommentIcon />
          <HeaderText>Key takeways:</HeaderText>
        </RowGroup>
      </HeaderWrapper>
      <BodyWrapper>
        <BodyText
          md={content}
          css={`
            p,
            a,
            li {
              font-size: 15px;
            }
            ol {
              margin: 0;
              padding-left: 12px;
            }
          `}
        />
      </BodyWrapper>
    </ReviewWrapper>
  );
}
