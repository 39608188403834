import styled from 'styled-components';
import { LeftSection, Logo, Nav, NavbarWrapper } from '.';

const CenteredSection = styled(LeftSection)`
  width: 100%;
  justify-content: center;
`;

export default function Navbar({ className }: { className?: string }) {
  return (
    <NavbarWrapper className={className}>
      <div className="inner">
        <Nav>
          <CenteredSection>
            <Logo />
          </CenteredSection>
        </Nav>
      </div>
    </NavbarWrapper>
  );
}
