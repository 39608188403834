import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.dimensions.spacing.default.top};
  padding-bottom: ${({ theme }) => theme.dimensions.spacing.default.bottom};
  position: relative;

  video {
    border-radius: 8px;
    padding-bottom: 0;
    margin-top: 0;
  }
`;

export default function VideoEmbed({ node }) {
  return (
    <Wrapper>
      <video
        controls
        playsInline
        muted
        preload="yes"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <source src={node.href}></source>
      </video>
    </Wrapper>
  );
}
