import React from 'react';
import activityDetector from 'activity-detector';
const createActivityDetector = activityDetector.default;

export default function useActivityDetector() {
  const [isIdle, setIsIdle] = React.useState(false);
  React.useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: 600000, // we consider you inactive after 10 minutes of no interaction
      ignoredEventsWhenIdle: [], // consider moving the mouse a valid event
    });
    activityDetector.on('idle', () => setIsIdle(true));
    activityDetector.on('active', () => setIsIdle(false));
    return () => activityDetector.stop();
  }, []);

  return isIdle;
}
