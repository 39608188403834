import React from 'react';
import styled from 'styled-components';
import EmbedWrapper from '../FullWidthWrapperWthControls';
// this needs to be here in order to load the Tableau API
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import tableau from 'tableau-api';

const TableauWrapper = styled.div`
  background-color: #f5f5f5; /* tableau's default background color*/
  border-radius: 8px;

  > iframe {
    border-radius: 8px;
  }
`;

const TABLEAU_DIRECT_LINK_OLD_REGEX =
  /https:\/\/public\.tableau\.com\/profile\/.*\/vizhome\/(.*)\/(.*)/;
const TABLEAU_DIRECT_LINK_NEW_REGEX =
  /https:\/\/public\.tableau\.com\/app\/profile\/.*\/viz\/(.*)\/(.*)/;
const TABLEAU_EMBED_REGEX = /https:\/\/public\.tableau\.com\/views.*/;

// taken from https://community.tableau.com/s/question/0D54T00000C6KGO/embedding-tableau-into-a-react-app
class TableauViz extends React.Component {
  componentDidMount() {
    this.initViz();
  }

  //Function call API
  initViz() {
    const url = this.getEmbedUrl();
    if (!url) {
      return;
    }

    const options = {
      height: '80vh',
      width: '100%',
    };
    const vizContainer = this.vizContainer;
    // eslint-disable-next-line no-unused-vars
    this.viz = new window.tableau.Viz(vizContainer, url, options);
  }

  getEmbedUrl() {
    const rawUrl = this.props.url;

    if (rawUrl.match(TABLEAU_EMBED_REGEX)) {
      return rawUrl;
    }
    if (rawUrl.match(TABLEAU_DIRECT_LINK_OLD_REGEX)) {
      const [, bookId, sheetName] = rawUrl.match(TABLEAU_DIRECT_LINK_OLD_REGEX);
      return `https://public.tableau.com/views/${bookId}/${sheetName}`;
    }

    if (rawUrl.match(TABLEAU_DIRECT_LINK_NEW_REGEX)) {
      const [, bookId, sheetName] = rawUrl.match(TABLEAU_DIRECT_LINK_NEW_REGEX);
      return `https://public.tableau.com/views/${bookId}/${sheetName}`;
    }
    return null;
  }

  resetViz() {
    // https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm
    this.viz.getWorkbook().showCustomViewAsync();
  }

  render() {
    if (!this.getEmbedUrl()) {
      return <p>Sorry this does not look like a valid Tableau Public link.</p>;
    }
    return (
      <EmbedWrapper
        showBottomBorder={this.props.showBottomBorder}
        title="Tableau Embed"
        buttons={[
          ...(this.props.withReset
            ? [
                {
                  label: 'Reset',
                  onClick: () => this.resetViz(),
                },
              ]
            : []),
          {
            label: 'Open in new tab',
            onClick: () => window.open(this.props.url, '_blank'),
          },
        ]}
      >
        <TableauWrapper
          ref={(div) => {
            this.vizContainer = div;
          }}
        />
      </EmbedWrapper>
    );
  }
}

export default TableauViz;
