import styled from 'styled-components';
import Button from '../components/elements/Button';
import { useHistory } from 'react-router-dom';
import { H2 } from '../components/elements/Text';
import { Helmet } from 'react-helmet-async';
import { getNotFoundPageTitle } from '../routing';

const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;

  h2 {
    margin-top: 0;
  }
`;

const NotFound = () => {
  const history = useHistory();

  const onGoHome = () => {
    history.push('/');
  };
  return (
    <>
      <Helmet>
        <title>{getNotFoundPageTitle()}</title>
      </Helmet>
      <CenteredDiv>
        <H2>404: Page not found. ¯\_(ツ)_/¯</H2>
        <Button
          label="Go Home"
          type="secondary"
          onClick={onGoHome}
          faIcon={'fas fa-fw fa-home'}
        />
      </CenteredDiv>
    </>
  );
};

export default NotFound;
