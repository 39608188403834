import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 52px;
  align-items: center;
`;

const Title = styled.p`
  margin-top: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const Action = styled.p`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;

  color: #70ffbc;

  :hover {
    cursor: pointer;
    color: #ffffff;
  }
`;
const Divider = styled.div`
  height: 2px;
  background: #2f3237;
`;
const Spacer = styled.div`
  height: 52px;
`;

export default function FullWidthWrapperWthControls({
  children,
  buttons,
  title,
  showBottomBorder = true,
}) {
  return (
    <Wrapper className="full-width">
      <Divider />
      <Header>
        <Title>{title}</Title>
        <ButtonsWrapper>
          {buttons.map((button) => (
            <Action
              key={`wrapper-controls-${title}-${button.label}`}
              onClick={button.onClick}
            >
              {button.label}
            </Action>
          ))}
        </ButtonsWrapper>
      </Header>
      {children}
      <Spacer />
      {showBottomBorder && <Divider />}
    </Wrapper>
  );
}
