import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { CenteredContainer, ReadableContainer } from './common';
import ConfirmEmail from '../../Auth/ConfirmEmail';
import * as Analytics from '../../../analytics';

export default function AuthConfirmEmail() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state] = useActor(onboardingService.service);

  const email = state.context.email ?? '';

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_SIGNUP_CONFIRM_EMAIL,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ConfirmEmail
          email={email}
          redirectUri={`${window.location.origin}/join/auth-callback`}
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
