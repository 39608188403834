import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  ButtonsWrapper,
  CenteredContainer,
  ReadableContainer,
  PillButton,
  Header,
  ProgressBar,
  CTAButton,
} from './common';
import { LEARNING_GOALS, TIME_EFFORT } from '../config';
import { LabelUppercase } from '../../elements/Text';
import * as Analytics from '../../../analytics';

function getTimeEffortQuestion(learningGoal: string) {
  if (learningGoal === LEARNING_GOALS.LEARN_TO_CODE) {
    return 'How much time can you dedicate to learning code?';
  }

  if (learningGoal === LEARNING_GOALS.BI_TOOLS) {
    return 'How much time can you dedicate to learning BI Tools?';
  }

  if (learningGoal === LEARNING_GOALS.DATA_SKILLS) {
    return 'How much time can you dedicate to improving data analysis skills?';
  }

  return 'How much time can you dedicate?';
}

export default function AskTimeEffort() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const learningGoal = state.context.learningGoal ?? '';

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ASK_TIME_EFFORT,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <ProgressBar currentStep={4} />
        <LabelUppercase>Step 4</LabelUppercase>
        <Header>{getTimeEffortQuestion(learningGoal)}</Header>
        <ButtonsWrapper>
          {Object.values(TIME_EFFORT).map((timeEffort) => (
            <PillButton
              key={timeEffort.value}
              isSelected={state.context.timeEffort === timeEffort.value}
              onClick={() =>
                send({
                  type: EVENTS.SELECT_TIME_EFFORT,
                  timeEffort: timeEffort.value,
                })
              }
              label={timeEffort.label}
            />
          ))}
        </ButtonsWrapper>
        <CTAButton
          isDisabled={!state?.context?.timeEffort}
          onClick={() => send(EVENTS.CONTINUE_ASK_TIME_EFFORT)}
          label="Continue"
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
