import styled from 'styled-components';
import 'react-step-progress-bar/styles.css';
import { OnboardingProvider } from '../../contexts/onboarding';
import TrackOnboarding from './Track/TrackOnboarding';
import FullScreenModal from '../Modal/Overlay';
import PageWrapper from '../PageWrapper';

const Page = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export default function OnboardingModal({
  onboardingTask,
  onClose,
  onRefreshData,
}) {
  const close = () => {
    if (window.confirm('Are you sure you want to dismiss your onboarding?')) {
      onClose();
    }
  };

  return (
    <OnboardingProvider onboardingTask={onboardingTask}>
      <FullScreenModal>
        <Page>
          <TrackOnboarding
            track={onboardingTask.track}
            onRefreshData={onRefreshData}
            onClose={close}
          />
        </Page>
      </FullScreenModal>
    </OnboardingProvider>
  );
}
