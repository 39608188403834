import { useActor } from '@xstate/react';
import React from 'react';
import { GOALS, LEARNING_GOALS, PROFILES } from '../config';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  CenteredContainer,
  CTAButton,
  Header,
  HighlightSpan,
  ReadableContainer,
  MainImage,
  LargeQuote,
} from './common';
import * as Analytics from '../../../analytics';

const getQuoteData = ({
  profile,
  learningGoal,
  goal,
}: {
  profile: string;
  learningGoal: string;
  goal: string;
}) => {
  if (goal === GOALS.EXPLORE_TEAMS.value) {
    return {
      text: 'It’s essential that our whole team is comfortable with our tooling and data. Without Enki we wouldn’t have been able to achieve this.',
      author: 'Matt',
      position: 'Head of Data @ Jackpocket',
      profileImage:
        'https://img.enkipro.com/14a6272632b66e8c12bdcf0655f77aea.jpeg',
    };
  }

  if (profile === PROFILES.SALES_MARKETING.value) {
    return {
      text: 'Enki worked so well for me because it was so customised to my needs.',
      author: 'Diane',
      position: 'Growth Manager @ Jackpocket',
      profileImage:
        'https://img.enkipro.com/abb9bbb5f4c7d045e1e202cbfd94d8d5.jpeg',
    };
  }

  const OPTIMIZE_GOALS = [
    GOALS.OPTIMIZE_SKILLS.value,
    GOALS.BUILD_LEARNING_HABIT.value,
    GOALS.OTHER.value,
  ];

  const CHANGE_CAREER_GOALS = [
    GOALS.ACCELERATE_CAREER.value,
    GOALS.SWITCH_TECHNICAL.value,
    GOALS.FIND_A_JOB.value,
  ];

  if (
    profile === PROFILES.PRODUCT_ENGINEERING.value &&
    OPTIMIZE_GOALS.includes(goal)
  ) {
    return {
      text: 'Enki unlocked a superpower for me. I’m now saving hours of time every week both on my end, and for my team.',
      author: 'Molly',
      position: 'Product Manager @ Kiavi',
      profileImage:
        'https://img.enkipro.com/4b9e5fec522e1b5d7175dd1bc1136a78.jpeg',
    };
  }

  if (
    profile === PROFILES.PRODUCT_ENGINEERING.value &&
    CHANGE_CAREER_GOALS.includes(goal)
  ) {
    return {
      text: 'Enki was the only online course that worked for me because it was customised to my needs.',
      author: 'Molly',
      position: 'Product Manager @ Kiavi',
      profileImage:
        'https://img.enkipro.com/4b9e5fec522e1b5d7175dd1bc1136a78.jpeg',
    };
  }

  if (profile === PROFILES.MANAGER_EXEC.value) {
    return {
      text: 'Enki unlocked a superpower for me. I’m now saving hours of time every week both on my end, and for my team.',
      author: 'Molly',
      position: 'Senior Manager @ Kiavi',
      profileImage:
        'https://img.enkipro.com/4b9e5fec522e1b5d7175dd1bc1136a78.jpeg',
    };
  }

  if (
    profile === PROFILES.STUDENT.value &&
    learningGoal === LEARNING_GOALS.DATA_SKILLS &&
    OPTIMIZE_GOALS.includes(goal)
  ) {
    return {
      text: 'Enki unlocked a superpower for me. I’m now saving hours of time every week both on my end, and for my team.',
      author: 'Molly',
      position: 'Product Manager @ Kiavi',
      profileImage:
        'https://img.enkipro.com/4b9e5fec522e1b5d7175dd1bc1136a78.jpeg',
    };
  }

  if (profile === PROFILES.STUDENT.value) {
    return {
      text: 'I wouldn’t have been able to land my current job without Enki.',
      author: 'Sarah',
      position: 'Developer @ Google',
      profileImage:
        'https://img.enkipro.com/4e6885916fe7bd996f11ef215118d841.jpeg',
    };
  }

  if (
    profile === PROFILES.OERATIONS_SUPPORT.value &&
    OPTIMIZE_GOALS.includes(goal)
  ) {
    return {
      text: 'Enki got me up to speed so quickly. A ton of time is now being saved.',
      author: 'Alex',
      position: 'Ops Manager @ Jackpocket ',
      profileImage:
        'https://img.enkipro.com/9609eb3c9750c44de95a1ddb6a2a53e5.jpeg',
    };
  }

  if (
    profile === PROFILES.OERATIONS_SUPPORT.value &&
    CHANGE_CAREER_GOALS.includes(goal)
  ) {
    return {
      text: 'I wouldn’t have been able to land my current job without Enki.',
      author: 'Sarah',
      position: 'Developer @ Google',
      profileImage:
        'https://img.enkipro.com/4e6885916fe7bd996f11ef215118d841.jpeg',
    };
  }

  return {
    text: 'I wouldn’t have been able to land my current job without Enki.',
    author: 'Sarah',
    position: 'Developer @ Google',
    profileImage:
      'https://img.enkipro.com/4e6885916fe7bd996f11ef215118d841.jpeg',
  };
};

export default function PostAskGoal() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  const { profile, goal, learningGoal } = state.context;

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_POST_ASK_GOAL,
    state.context
  );

  const { text, author, position, profileImage } = getQuoteData({
    profile: profile ?? '',
    goal: goal ?? '',
    learningGoal: learningGoal ?? '',
  });

  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>
          Our truly customized program is perfect for{' '}
          <HighlightSpan>your profile and goal!</HighlightSpan>
        </Header>
        <MainImage
          src={'https://img.enkipro.com/1ec92bba65c153ba1736618014dfd4b8.png'}
        />
        <LargeQuote
          avatarURL={profileImage}
          quote={text}
          position={position}
          author={author}
        />

        <CTAButton
          onClick={() => send(EVENTS.CONTINUE_POST_ASK_GOAL)}
          label="Continue"
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
