import { ReactNode } from 'react';
import { shouldEmbed } from './EmbeddedLinkRenderer';
import { Element } from 'react-markdown/lib/ast-to-react';
const ParagraphSkipImage = ({
  node,
  className,
  children,
  ...props
}: {
  node: Element;
  className?: string;
  children: ReactNode;
} & Partial<React.Component>) => {
  const hasImage = !!node?.children.find(
    (child) =>
      typeof child === 'object' &&
      child.type == 'element' &&
      child.tagName === 'img'
  );
  const hasEmbed = !!node?.children.find(
    (child) =>
      typeof child === 'object' &&
      child.type == 'element' &&
      child.tagName === 'a' &&
      shouldEmbed(child.properties)
  );

  if (hasImage || hasEmbed) {
    return <>{children}</>;
  }
  return (
    <p {...props} className={className}>
      {children}
    </p>
  );
};

export default ParagraphSkipImage;
