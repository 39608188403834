import { useEffect, useMemo, useState } from 'react';

export default function useThrottledConsumer(waitTime = 500) {
  const [canConsume, setCanConsume] = useState(true);

  useEffect(() => {
    if (!canConsume) {
      const id = setTimeout(() => setCanConsume(true), waitTime);
      return () => clearTimeout(id);
    }
  }, [canConsume, waitTime]);

  const consume = useMemo(
    () => (func) => {
      if (canConsume && typeof func === 'function') {
        setCanConsume(false);
        return func();
      }
    },
    [canConsume]
  );

  return consume;
}
