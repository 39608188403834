import { Redirect, useLocation } from 'react-router-dom';
import useAuth from '../hooks/use-auth';
import PageWrapper from '../components/PageWrapper';
import { toast } from 'react-toastify';
import ConfirmEmail from '../components/Auth/ConfirmEmail';

export default function ConfirmEmailPage() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const email = (location.state as { email: string })?.email ?? '';
  const returnTo =
    (location.state as { returnTo: string })?.returnTo ?? undefined;

  if (isAuthenticated) {
    toast.error('You are logged in already');
    return <Redirect to="/" />;
  }

  if (!email) {
    toast.error('Sorry, something went wrong');
    return <Redirect to="/auth" />;
  }

  return (
    <PageWrapper style={{ height: '100vh' }}>
      <ConfirmEmail
        email={email}
        redirectUri={`${window.location.origin}/auth-callback`}
        returnTo={returnTo}
      />
    </PageWrapper>
  );
}
