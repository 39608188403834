import { useActor } from '@xstate/react';
import React from 'react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import {
  CTAButton,
  CenteredContainer,
  Header,
  HighlightSpan,
  Label,
  MainImage,
  ReadableContainer,
} from './common';
import { TIME_EFFORT, TOPICS } from '../config';
import day from 'dayjs';
import * as Analytics from '../../../analytics';

const getLearningGoal = (topic: string) => {
  if ([TOPICS.DATA_LITERACY, TOPICS.SQL, TOPICS.SHEETS].includes(topic)) {
    return 'become advanced in data skills';
  }
  if ([TOPICS.PYTHON].includes(topic)) {
    return 'learn to code';
  }
  if ([TOPICS.POWER_BI, TOPICS.TABLEAU, TOPICS.LOOKER].includes(topic)) {
    return 'advance in your BI tool';
  }

  return 'learn';
};
const getGoalRelatedMessage = (topic: string) => {
  if ([TOPICS.DATA_LITERACY, TOPICS.SQL, TOPICS.SHEETS].includes(topic)) {
    return '87% of active Enki learners reported a “big increase in data confidence” after completing our program';
  }
  if ([TOPICS.PYTHON].includes(topic)) {
    return '95% of active Enki learners wrote their first coding program within 3 months';
  }
  if ([TOPICS.POWER_BI, TOPICS.TABLEAU, TOPICS.LOOKER].includes(topic)) {
    return '92% of active Enki learners became “confident with their BI tool” within 4 weeks';
  }

  return 'learn';
};

const getEndDate = (timeEffort: string) => {
  const today = day();
  let endDate;
  if (timeEffort === TIME_EFFORT.MINUTES_5_10.value) {
    endDate = today.add(8, 'weeks');
  } else if (timeEffort === TIME_EFFORT.MINUTES_20_30.value) {
    endDate = today.add(4, 'weeks');
  } else if (timeEffort === TIME_EFFORT.MORE.value) {
    endDate = today.add(2, 'weeks');
  } else {
    endDate = today.add(10, 'weeks');
  }

  let suffix = 'th';
  if ([1, 21, 31].includes(endDate?.date())) {
    suffix = 'st';
  }
  if ([2, 22].includes(endDate?.date())) {
    suffix = 'nd';
  }

  return endDate?.format(`MMMM D`) + suffix;
};

export default function Results() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_RESULTS,
    state.context
  );

  const { timeEffort } = state.context;

  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>
          Based on your answers, you can{' '}
          {getLearningGoal(state.context.topic || '')} by{' '}
          <HighlightSpan>
            {getEndDate(timeEffort ?? TIME_EFFORT.UNSURE.value)}
          </HighlightSpan>
        </Header>
        <Label>{getGoalRelatedMessage(state.context.topic || '')}</Label>
        <MainImage
          src={'https://img.enkipro.com/826ee25de482e60aa5f6269f8964e147.png'}
        />

        <Label>
          Your learning journey is unique, and our AI mentor provides
          personalized assistance to help you navigate challenges and accelerate
          progress.
        </Label>

        <CTAButton
          label={'Continue'}
          onClick={() => send(EVENTS.CONTINUE_RESULTS)}
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
