import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/use-auth';
import FullScreenLoader from '../Loader/FullScreenLoader';
const AuthenticatedRoute = ({
  path,
  children,
  ...rest
}: {
  path: string;
  children: React.ReactNode;
  exact?: boolean;
}) => {
  const {
    isAuthenticated,
    isAuthenticating,
    didAuthenticate,
    isInitialised,
    reAuthSilently,
  } = useAuth();

  const reAuth = React.useCallback(async () => {
    try {
      await reAuthSilently();
    } catch (error) {
      // @ts-expect-error - weird auth0 error
      if (error?.code === 'login_required') {
        return;
      }
      console.error(error);
    }
  }, [reAuthSilently]);

  React.useEffect(() => {
    if (isAuthenticating) {
      return;
    }
    if (!isInitialised || isAuthenticated || didAuthenticate) {
      return;
    }

    reAuth();
  }, [
    isAuthenticating,
    isAuthenticated,
    reAuthSilently,
    isInitialised,
    didAuthenticate,
    reAuth,
  ]);

  if (!isInitialised || !didAuthenticate) {
    return <FullScreenLoader />;
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/auth',
          state: { from: window.location.pathname + window.location.search },
        }}
      />
    );
  }

  return (
    <Route path={path} {...rest}>
      {children}
    </Route>
  );
};

export default AuthenticatedRoute;
