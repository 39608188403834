import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import { prop } from 'styled-tools';

import Button from '../elements/Button';
import Loader from '../Loader';
import EmbedWrapper from '../FullWidthWrapperWthControls';
import { PSmall } from '../elements/Text';

export const LookerIframe = styled.iframe`
  height: ${(props) => props.height || '70vh'};
  width: ${(props) => props.width};
  background: #f6f6f7; /* looker's default background color*/
  border-radius: 8px;
  pointer-events: ${(props) => (props.isClickable ? 'auto' : 'none')};
`;

const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  padding: 1em;
  position: sticky; // without this, the loader won't display on safari. not sure why it works as if position is relative..
  align-items: center;
`;

const NotSupportedWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
`;

const LoaderText = styled(PSmall)`
  margin: 0 16px;
`;

export async function isBrowserSupporting3rdPartyCookies(id) {
  let supported = null;

  return new Promise((resolve) => {
    const frame = document.createElement('iframe');
    frame.id = id;
    frame.src = 'https://enkidevs.github.io/biscuits-checker';
    frame.style.display = 'none';
    frame.style.position = 'fixed';

    function listen(event) {
      if (event.data === '3pc.supported') {
        supported = true;
        document.body.removeChild(frame);
        window.removeEventListener('message', listen);
        resolve(true);
      }
      if (event.data === '3pc.unsupported') {
        supported = false;
        document.body.removeChild(frame);
        window.removeEventListener('message', listen);
        resolve(false);
      }
    }

    window.addEventListener('message', listen, false);

    setTimeout(() => {
      if (supported === null) {
        document.body.removeChild(frame);
        window.removeEventListener('message', listen);
        resolve(false);
      }
    }, 3000);
    document.body.appendChild(frame);
  });
}

export default function LookerEmbed({
  withReset = false,
  url,
  height,
  width = '100%',
  isClickable = true,
  id = 'looker',
  showBottomBorder = true,
}) {
  const [cookieState, setCookieState] = React.useState('LOADING');

  React.useEffect(() => {
    async function checkCookiesAsyncWrapper() {
      const supported = await isBrowserSupporting3rdPartyCookies();
      setCookieState(supported ? 'SUPPORTED' : 'NOT_SUPPORTED');
    }
    checkCookiesAsyncWrapper();
  }, [id]);

  if (cookieState === 'LOADING') {
    return (
      <LoaderWrapper>
        <Loader />
        <LoaderText>Computing playground parameters</LoaderText>
      </LoaderWrapper>
    );
  }

  if (cookieState === 'NOT_SUPPORTED') {
    return (
      <NotSupportedWrapper>
        <Button
          style={{ margin: 0 }}
          type="secondary"
          onClick={() => window.open(url, '_blank')}
          label=" Open Looker Playground"
        />
        <LookerEmbedNotSupportedTooltip />
      </NotSupportedWrapper>
    );
  }

  return (
    <EmbedWrapper
      title="Looker Embed"
      showBottomBorder={showBottomBorder}
      buttons={[
        ...(withReset
          ? [
              {
                label: 'Reset',
                onClick: () => (document.getElementById(id).src = url),
              },
            ]
          : []),
        {
          label: 'Open in new tab',
          onClick: () => window.open(url, '_blank'),
        },
      ]}
    >
      <LookerIframe
        id={id}
        src={url}
        title="Looker Embed"
        frameborder="0"
        height={height}
        width={width}
        isClickable={isClickable}
      />
    </EmbedWrapper>
  );
}

const TooltipWrapper = styled.div`
  margin: 0;
  padding: 16px;
  background-color: ${prop('theme.colors.bgPage')};
  color: ${prop('theme.colors.text')};
  border-radius: 8px;
`;

export function LookerEmbedNotSupportedTooltip() {
  return (
    <div>
      <i
        className="looker-embed-tooltip-anchor fas fa-fw fa-info-circle"
        style={{ cursor: 'help', marginLeft: '0.5em' }}
      />
      <Tooltip anchorSelect=".looker-embed-tooltip-anchor" place="left" noArrow>
        <TooltipWrapper>
          <PSmall>
            Heads up! It seems that your browser doesn't let us display this
            Looker playground as it is blocking third party cookies.
          </PSmall>
          <PSmall>
            Instead, you can open the playground in a new tab using the button
            on the left.
          </PSmall>
          <PSmall>
            Alternatively, check your browser settings or try using a different
            one.
          </PSmall>
        </TooltipWrapper>
      </Tooltip>
    </div>
  );
}
