import auth0, { Auth0DecodedHash, WebAuth } from 'auth0-js';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Analytics from '../analytics';
import * as CrashReporter from '../crash-reporter';
import { UserPayload, verifyAuthUser } from '../hydra';
import dayjs from 'dayjs';
import { appUserUpsellPath } from '../routing';

type AuthContextType = {
  didAuthenticate: boolean;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isInitialised: boolean;
  user: UserPayload | null;
  logout: ({ redirectUri }: { redirectUri?: string }) => void;
  authWithGoogle: ({
    redirectUri,
    returnTo,
  }: {
    redirectUri?: string;
    returnTo?: string;
  }) => Promise<void>;
  getTokenSilently: () => Promise<string>;
  passwordlessLogin: ({
    email,
    code,
    redirectUri,
    returnTo,
  }: {
    email: string;
    code: string;
    redirectUri?: string;
    returnTo?: string;
  }) => Promise<void>;
  passwordlessStart: ({ email }: { email: string }) => Promise<void>;
  handleAuthCallback: () => Promise<Auth0DecodedHash | null>;
  getReturnToFromResult: (result: Auth0DecodedHash | null) => string;
  reAuthSilently: () => Promise<void>;
};

const initialState: AuthContextType = {
  isAuthenticated: false,
  isAuthenticating: false,
  isInitialised: false,
  didAuthenticate: false,
  user: null,
  logout: async () => {},
  authWithGoogle: async () => {},
  getTokenSilently: async () => '',
  passwordlessLogin: async () => {},
  passwordlessStart: async () => {},
  handleAuthCallback: async () => null,
  getReturnToFromResult: () => '',
  reAuthSilently: async () => {},
};

export const AuthContext = React.createContext<AuthContextType>(initialState);
const DEFAULT_REDIRECT_URI = window.location.origin;

const auth0Config = {
  domain: 'auth.enki.com',
  clientID: 'wdW9AB82PJ1xrI1gHSOkXGv3NjkdCl47',
  audience: 'https://desktop.enki.com',
  responseType: 'token',
  redirectUri: DEFAULT_REDIRECT_URI,
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth0Client = React.useRef<WebAuth | null>(null);
  const [user, setUser] = React.useState<null | UserPayload>(null);

  const [didAuthenticate, setDidAuthenticate] = React.useState(false);
  const [isInitialised, setIsInitialised] = React.useState(false);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);

  const history = useHistory();

  const authWithGoogle = React.useCallback(
    async ({
      redirectUri = DEFAULT_REDIRECT_URI,
      returnTo,
      ...rest
    }: {
      redirectUri?: string;
      returnTo?: string;
    } = {}) => {
      CrashReporter.addBreadcrumb({
        message: 'Auth - auth with google',
        metadata: {},
        type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
      });
      if (!auth0Client.current) {
        toast.error('Sorry, we are having trouble logging you in');
        return;
      }

      try {
        return auth0Client.current.authorize({
          connection: 'google-oauth2',
          responseType: 'token id_token',
          redirectUri,
          ...(returnTo ? { state: JSON.stringify({ returnTo }) } : {}),
          ...rest,
        });
      } catch (error) {
        console.error(error);
        toast.error('Sorry, we are having trouble logging you in');
      }
    },
    []
  );

  const logout = React.useCallback(
    ({ redirectUri = DEFAULT_REDIRECT_URI, ...rest } = {}): Promise<void> => {
      CrashReporter.addBreadcrumb({
        message: 'Auth - logout',
        metadata: {},
        type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
      });
      Analytics.removeUser();
      return new Promise((resolve, reject) => {
        if (!auth0Client.current) {
          toast.error('Sorry, we are having trouble logging you out');
          return reject(new Error('Sorry, you cannot logout right now'));
        }
        deleteTokenFromLocalStorage();
        auth0Client.current.logout({
          returnTo: redirectUri,
          ...rest,
        });
        resolve();
      });
    },
    [auth0Client]
  );

  const queryUser = React.useCallback(async (accessToken: string) => {
    CrashReporter.addBreadcrumb({
      message: 'Auth - query user',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });
    const user = await verifyAuthUser(accessToken);
    if (user.isAppUserOnly) {
      return { isAppUserOnly: user.isAppUserOnly };
    }

    CrashReporter.setUser(user);
    Analytics.setUser({
      id: user.id,
      email: user?.email ?? '',
      name: user?.name ?? '',
      isSignUp: user.isSignUp,
    });
    Analytics.trackEvent({
      event: Analytics.EVENTS.COMMON.AUTHENTHICATE,
      properties: {
        isSignUp: user.isSignUp,
      },
    });
    setUser(user);
    return { isAppUserOnly: false };
  }, []);

  const checkAuth0Session = React.useCallback((): Promise<Auth0DecodedHash> => {
    CrashReporter.addBreadcrumb({
      message: 'Auth - check session',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });
    return new Promise((resolve, reject) => {
      if (!auth0Client.current) {
        return reject('Sorry, we are having trouble logging you in');
      }
      auth0Client.current.checkSession(
        {
          responseType: 'token id_token',
          redirectUri: DEFAULT_REDIRECT_URI,
        },
        (err, authResult) => {
          if (err) {
            reject(err);
          }

          if (!authResult?.accessToken) {
            reject('No access token found');
          }

          resolve(authResult);
        }
      );
    });
  }, []);

  const parseAuth0Hash =
    React.useCallback((): Promise<Auth0DecodedHash | null> => {
      CrashReporter.addBreadcrumb({
        message: 'Auth - parse hash',
        metadata: {},
        type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
      });
      return new Promise((resolve, reject) => {
        if (!auth0Client.current) {
          return reject('Sorry, we are having trouble logging you in');
        }
        auth0Client.current.parseHash(
          { hash: window.location.hash },
          (err, authResult) => {
            if (err) {
              reject(err);
            }

            if (!authResult?.accessToken) {
              reject('No access token found');
            }

            resolve(authResult);
          }
        );
      });
    }, []);

  const handleAuthCallback = React.useCallback(async () => {
    setIsAuthenticating(false);
    setDidAuthenticate(false);
    CrashReporter.addBreadcrumb({
      message: 'Auth - handle auth callback',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });
    try {
      const authResult = await parseAuth0Hash();
      if (!authResult?.accessToken) {
        throw new Error('No access token found');
      }
      setTokenInLocalStorage(authResult.accessToken, authResult.expiresIn);
      const { isAppUserOnly } = await queryUser(authResult.accessToken);
      if (isAppUserOnly) {
        await logout({
          redirectUri: window.location.origin + appUserUpsellPath,
        });
      }
      history.replace({ hash: '' });
      setDidAuthenticate(true);
      setIsAuthenticating(false);
      return authResult;
    } catch (error) {
      deleteTokenFromLocalStorage();
      console.error(error);
      setDidAuthenticate(true);
      setIsAuthenticating(false);
      throw error;
    }
  }, [history, parseAuth0Hash, queryUser, logout]);

  const getReturnToFromResult = (result: Auth0DecodedHash | null) => {
    if (!result || !result?.state) {
      return '/';
    }

    try {
      const state = JSON.parse(result.state);
      return state?.returnTo ?? '/';
    } catch (error) {
      console.error(error);
      return '/';
    }
  };

  const reAuthSilently = React.useCallback(async () => {
    setDidAuthenticate(false);
    setIsAuthenticating(true);
    CrashReporter.addBreadcrumb({
      message: 'Auth - re-auth silently',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });
    let accessToken: string | null = getTokenFromLocalStorage();
    if (!accessToken) {
      try {
        const authResult = await checkAuth0Session();
        if (!authResult?.accessToken) {
          throw new Error('No access token found');
        }
        accessToken = authResult.accessToken;
        setTokenInLocalStorage(authResult.accessToken, authResult.expiresIn);
      } catch (error) {
        deleteTokenFromLocalStorage();
        setDidAuthenticate(true);
        setIsAuthenticating(false);
        throw error;
      }
    }

    try {
      await queryUser(accessToken);
      setDidAuthenticate(true);
      setIsAuthenticating(false);
    } catch (error) {
      deleteTokenFromLocalStorage();
      setIsAuthenticating(false);
      setDidAuthenticate(true);
      throw error;
    }
  }, [queryUser, checkAuth0Session]);

  const initAuth = React.useCallback(async () => {
    setIsInitialised(false);
    if (auth0Client.current) {
      return;
    }

    auth0Client.current = new auth0.WebAuth(auth0Config);
    setIsInitialised(true);
  }, []);

  const getTokenSilently = React.useCallback(async (): Promise<string> => {
    CrashReporter.addBreadcrumb({
      message: 'Auth - get token silently',
      metadata: {},
      type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
    });
    if (!auth0Client.current) {
      throw new Error('Sorry, we are having trouble logging you in');
    }

    const cachedToken = getTokenFromLocalStorage();
    if (cachedToken) {
      return cachedToken;
    }

    try {
      const authResult = await checkAuth0Session();
      if (!authResult?.accessToken) {
        throw new Error('No access token found');
      }
      setTokenInLocalStorage(authResult.accessToken, authResult.expiresIn);
      return authResult.accessToken;
    } catch (error) {
      deleteTokenFromLocalStorage();
      console.error(error);
      throw error;
    }
  }, [checkAuth0Session]);

  const passwordlessStart = React.useCallback(
    async ({ email, ...rest }: { email: string }): Promise<void> => {
      CrashReporter.addBreadcrumb({
        message: 'Auth - passwordless start',
        metadata: {},
        type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
      });
      return new Promise((resolve, reject) => {
        if (!auth0Client.current) {
          toast.error('Sorry, we are having trouble sending you a login link');
          return reject(new Error('Sorry, you cannot login right now'));
        }
        auth0Client.current.passwordlessStart(
          {
            email,
            connection: 'email',
            send: 'code',
            ...rest,
          },
          (err) => {
            if (err) {
              console.error(err);
              reject(err);
            } else {
              resolve();
            }
          }
        );
      });
    },
    []
  );

  const passwordlessLogin = React.useCallback(
    async ({
      email,
      code,
      redirectUri = DEFAULT_REDIRECT_URI,
      returnTo,
      ...rest
    }: {
      email: string;
      code: string;
      redirectUri?: string;
      returnTo?: string;
    }): Promise<void> => {
      CrashReporter.addBreadcrumb({
        message: 'Auth - passwordless login',
        metadata: {},
        type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
      });
      return new Promise((resolve, reject) => {
        if (!auth0Client.current) {
          toast.error('Sorry, we are having trouble logging you in');
          return reject(new Error('Sorry, you cannot login right now'));
        }
        auth0Client.current.passwordlessLogin(
          {
            email,
            connection: 'email',
            verificationCode: code,
            redirectUri,
            ...(returnTo ? { state: JSON.stringify({ returnTo }) } : {}),
            ...rest,
          },
          (err, authResult) => {
            if (err) {
              console.error(err);
              reject(err);
            } else {
              resolve(authResult);
            }
          }
        );
      });
    },
    []
  );

  React.useEffect(() => {
    initAuth();
  }, [initAuth]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: didAuthenticate && !!user,
        isInitialised,
        didAuthenticate,
        isAuthenticating,
        user,
        logout,
        authWithGoogle,
        getTokenSilently,
        passwordlessStart,
        passwordlessLogin,
        handleAuthCallback,
        getReturnToFromResult,
        reAuthSilently,
      }}
    >
      {isInitialised && children}
    </AuthContext.Provider>
  );
};

const TOKEN_KEY = 'auth_access_token';
const EXPIRATION_KEY = 'auth_access_token_expires_at';

const setTokenInLocalStorage = (token: string, expiresIn?: number) => {
  CrashReporter.addBreadcrumb({
    message: 'Auth - set token in local storage',
    metadata: {
      token,
      expiresIn,
    },
    type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
  });
  if (!expiresIn) {
    return;
  }
  const expirationTime = dayjs().add(expiresIn, 'seconds');
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(EXPIRATION_KEY, expirationTime.toString());
};

const deleteTokenFromLocalStorage = () => {
  CrashReporter.addBreadcrumb({
    message: 'Auth - delete token from local storage',
    metadata: {},
    type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
  });
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(EXPIRATION_KEY);
};

const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const expiresAt = localStorage.getItem(EXPIRATION_KEY);

  CrashReporter.addBreadcrumb({
    message: 'Auth - get token from local storage',
    metadata: {
      token,
      expiresAt,
    },
    type: CrashReporter.BREADCRUMB_TYPE.MANUAL,
  });

  if (!token || !expiresAt) {
    return null;
  }

  const expiresAtDate = dayjs(expiresAt);
  if (expiresAtDate.isBefore(dayjs().add(10, 'second'))) {
    deleteTokenFromLocalStorage();
    return null;
  }

  return token;
};
