export const authPath = '/auth';
export const teamsAuthPath = '/teams/auth';
export const authConfirmEmailPath = '/auth/confirm-email';
export const authCallbackPath = '/auth-callback';
export const soloOnboardingPath = '/join/:step?';
export const unsubscribePath = '/unsubscribe';

export const paymentPath = '/payment';
export const benefitsPath = '/benefits';
export const paymentCallbackPath = '/payment-callback';

export const projectsDashboardPath = '/projects';

export const appUserUpsellPath = '/app-user-signup';

export const teamDashboardPath = `/:teamSlug/dashboard`;
export const teamActivityPath = `/:teamSlug/missions/:missionSlug/activities/:activitySlug`;
export const teamSubmitProjectPath = '/:teamSlug/submit-project';
export const projectExamplesPath = '/project-examples';
export const retroSurveyPath = `/:teamSlug/retro-survey`;

export const previewDashboardPath = `/preview/:contentSlug/dashboard`;
export const previewActivityPath =
  '/preview/missions/:missionSlug/activities/:activitySlug';

export const previewProjectPath = '/preview/projects/:projectSlug';

export const soloDashboardPath = `/prosumer/:contentSlug/dashboard`;
export const soloActivityPath =
  '/prosumer/missions/:missionSlug/activities/:activitySlug';

export const soloProjectPath = `/projects/:projectSlug`;

export const embeddablePythonPlaygroundPath = '/public/python-playground';
export const embeddableSQLPlaygroundPath = '/public/sql-playground';
export function getTeamsDashboardUrl(teamSlug: string) {
  return `/${teamSlug}/dashboard`;
}
export function getTeamsRetroSurveyUrl(teamSlug: string) {
  return `/${teamSlug}/retro-survey`;
}

export function getPreviewDashboardUrl(contentSlug: string) {
  return `/preview/${contentSlug}/dashboard`;
}

export function getProsumerDashboardUrl(contentSlug: string) {
  return `/prosumer/${contentSlug}/dashboard`;
}

export function getActivityUrl(
  trackSlug: string,
  missionSlug: string,
  activitySlug: string
) {
  return `/${trackSlug}/missions/${missionSlug}/activities/${activitySlug}`;
}

export function getAppliedProjectUrl(projectSlug: string) {
  return `/projects/${projectSlug}`;
}

export function getPreviewActivityUrl(
  missionSlug: string,
  activitySlug: string
) {
  return `/preview/missions/${missionSlug}/activities/${activitySlug}`;
}

export function getProsumerActivityUrl(
  missionSlug: string,
  activitySlug: string
) {
  return `/prosumer/missions/${missionSlug}/activities/${activitySlug}`;
}

export function getCommunityUrl() {
  return `/community`;
}

export function getHomeUrl() {
  return `/`;
}

export const getActivityPageTitle = (activityTitle: string) =>
  `Enki | ${activityTitle}`;
export const getDashboardPageTitle = (contentTitle: string) =>
  `Enki | ${contentTitle}`;
export const getProjectExamplesPageTitle = () => `Enki | Project Examples`;
export const getHomePageTitle = () => `Enki | Learning Platform`;
export const getSubmitProjectPageTitle = () => `Enki | Submit Project`;
export const getRetroSurveyPageTitle = () => `Enki | Retro Survey`;
export const getNotFoundPageTitle = () => `Enki | 404 Not Found`;
export const getProjectsPageTitle = () => `Enki | Projects`;
