import 'ace-builds/src-noconflict/mode-sql';
// import 'ace-builds/src-noconflict/theme-one_dark';
import 'ace-builds/src-noconflict/ext-language_tools';

const SQLHighlightRules = window.ace.acequire(
  'ace/mode/text_highlight_rules'
).TextHighlightRules;

const Mode = window.ace.acequire('ace/mode/sql').Mode;

export class CustomHighlightRules extends SQLHighlightRules {
  constructor() {
    super();
    var keywords =
      'SELECT|INSERT|UPDATE|DELETE|FROM|WHERE|AND|OR|GROUP|BY|ORDER|LIMIT|OFFSET|HAVING|AS|CASE|' +
      'WHEN|ELSE|END|TYPE|LEFT|RIGHT|JOIN|ON|OUTER|DESC|ASC|UNION|CREATE|TABLE|PRIMARY|KEY|IF|' +
      'FOREIGN|NOT|REFERENCES|DEFAULT|NULL|INNER|CROSS|NATURAL|DATABASE|DROP|GRANT|DISTINCT|BETWEEN';

    var builtinConstants = 'TRUE|FALSE';

    var builtinFunctions =
      'AVG|COUNT|FIRST|LAST|MAX|MIN|SUM|UCASE|LCASE|MID|LEN|ROUND|RANK|NOW|FORMAT|' +
      'COALESCE|IFNULL|ISNULL|NVL|CAST|RTRIM|LTRIM|TRIM|UPPER|LOWER';

    var dataTypes =
      'INT|NUMERIC|DECIMAL|DATE|VARCHAR|CHAR|BIGINT|FLOAT|DOUBLE|BIT|BINARY|TEXT|SET|TIMESTAMP|' +
      'REAL|NUMBER|INTEGER';

    var keywordMapper = super.createKeywordMapper(
      {
        'support.function': builtinFunctions,
        keyword: keywords,
        'constant.language': builtinConstants,
        'storage.type': dataTypes,
      },
      'identifier',
      true
    );

    this.$rules = {
      start: [
        {
          token: 'comment',
          regex: '--.*$',
        },
        {
          token: 'comment',
          start: '/\\*',
          end: '\\*/',
        },
        {
          token: 'string', // " string
          regex: '".*?"',
        },
        {
          token: 'string', // ' string
          regex: "'.*?'",
        },
        {
          token: 'string', // ` string (apache drill)
          regex: '`.*?`',
        },
        {
          token: 'constant.numeric', // float
          regex: '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b',
        },
        {
          token: keywordMapper,
          regex: '[a-zA-Z_$][a-zA-Z0-9_$]*\\b',
        },
        {
          token: 'keyword.operator',
          regex: '\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|=',
        },
        {
          token: 'paren.lparen',
          regex: '[\\(]',
        },
        {
          token: 'paren.rparen',
          regex: '[\\)]',
        },
        {
          token: 'text',
          regex: '\\s+',
        },
      ],
    };
    this.normalizeRules();
  }
}

export default class Sqlite extends Mode {
  constructor() {
    super();
    this.HighlightRules = CustomHighlightRules;
  }
}
