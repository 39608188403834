import { ACTIVITY_ACTION, ActivityContextType } from '..';
import updateQuestion from '../helpers/update-question';

export type SetResultFreeTextSqlPlaygroundQuestionActionType = {
  type: typeof ACTIVITY_ACTION.SET_RESULT_FREE_TEXT_SQL_PLAYGROUND_QUESTION;
  payload: {
    question: ActivityContextType['exercise']['questions'][0];
    result: Record<string, unknown>;
  };
};
export default function sqlFreeTextPlaygroundResultChangeReducer(
  state: ActivityContextType,
  action: SetResultFreeTextSqlPlaygroundQuestionActionType
) {
  const { question, result } = action.payload;

  return updateQuestion({
    activity: state,
    question,
    answerPart: {
      result,
    },
  });
}
