import React from 'react';
import { QuestionProps } from '.';
import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import { BottomSpacer, FullScreenQuestion, getQuestionText } from './common';
import { H1 } from '../../elements/Text';
import SQLCodeEditor from '../../SQLPlayground/SQLCodeEditor';
import styled from 'styled-components';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import MentorReview from '../MentorComment';

const EditorContainer = styled.div`
  max-height: 40vh;
  background: ${(props) => props.theme.colors.bgDefault};
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 5px;
`;

export default function FreeTextSQLCodeQuestion({
  question,
  answer,
}: QuestionProps) {
  const [activity, dispatch] = React.useContext(ActivityContext);

  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const onFreeTextQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_QUESTION,
      payload: {
        question,
        value,
      },
    });
  };

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <FullScreenQuestion>
      <ReadableContainer>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}
      </ReadableContainer>
      <ReadableMarkdown md={getQuestionText(question)} />
      <ReadableContainer>
        <EditorContainer>
          <SQLCodeEditor
            query={answer?.value ?? ''}
            onQueryChange={onFreeTextQuestionTextChange}
            isLoading={false}
            error={undefined}
            schemas={[]}
          />
        </EditorContainer>
      </ReadableContainer>
      <BottomSpacer />
    </FullScreenQuestion>
  );
}
