import React from 'react';
import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useRive } from '@rive-app/react-canvas';
// @ts-expect-error - .riv files are not supported by typescript
import loaderAnim from '../../../assets/rive-animations/loader.riv';
import { useActor } from '@xstate/react';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import { CenteredContainer, ReadableContainer, Label } from './common';
import * as Analytics from '../../../analytics';

day.extend(utc);

export const PERSONALISATION_LOADER_QUERYPARAM = 'personalisationLoader';

export default function PersonalizationLoader() {
  const [showTitle, setShowTitle] = React.useState(false);
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_PERRSONALIZATION_LOADER,
    state.context
  );

  const { RiveComponent } = useRive({
    src: loaderAnim,
    autoplay: true,
    onPlay: () => {
      setShowTitle(true);
    },
    onStop: () => send(EVENTS.CONTINUE_PERSONALIZATION_LOADER),
  });
  return (
    <ReadableContainer>
      <CenteredContainer>
        <RiveComponent style={{ width: '286px', height: '92px' }} />
        {showTitle && (
          <Label style={{ opacity: 0.5 }}>
            Creating a fully personalized program for you
          </Label>
        )}
      </CenteredContainer>
    </ReadableContainer>
  );
}
