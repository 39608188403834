import React from 'react';
import {
  CenteredContainer,
  CTAButton,
  Header,
  Label,
  MainImage,
  ReadableContainer,
  SmallReadableSection,
} from './common';
import SoloOnboardingContext from '../context';
import { useActor } from '@xstate/react';
import * as Analytics from '../../../analytics';

export default function Error() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_ERROR,
    state.context
  );

  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>Oops! Something went wrong</Header>
        <MainImage
          src={'https://img.enkipro.com/32e603fff1ec53245bda1bc688ab68f8.png'}
        />
        <SmallReadableSection>
          <Label>
            {' '}
            We apologize for the inconvenience. Our support team is ready to
            assist you.
          </Label>
        </SmallReadableSection>
        <CTAButton
          label="Contact Us"
          onClick={() => window.open('mailto:concierge@enki.com')}
        />
      </CenteredContainer>
    </ReadableContainer>
  );
}
