import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// Define the fade-in and fade-out animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

// Apply the animations to a styled div
const AnimatedIconWrapper = styled.div<{ isFadingOut: boolean }>`
  animation: ${(props) => (props.isFadingOut ? fadeOut : fadeIn)} 1s forwards;
  opacity: ${(props) => (props.isFadingOut ? 0 : 1)};
  transition: opacity 1s ease-in-out;
`;

const CyclingIcons = ({
  icons,
  intervalTime = 4000,
}: {
  icons: React.ReactElement[];
  intervalTime?: number;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFadingOut(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % icons.length);
        setIsFadingOut(false);
      }, 1000); // matches duration of fadeOut animation
    }, intervalTime);

    return () => clearInterval(interval);
  }, [icons.length, intervalTime]);

  return (
    <AnimatedIconWrapper key={currentIndex} isFadingOut={isFadingOut}>
      {icons[currentIndex]}
    </AnimatedIconWrapper>
  );
};

export default CyclingIcons;
