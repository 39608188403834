import { ActivityContextQuestionAnswer } from '..';
import { LatestSubmissionPayload, QuestionPayload } from '../../../hydra';

export default function isSubmissionDifferent(
  submission: {
    feedback: string;
    currentSubmissionStatus: string;
    difficultyRating: number | null;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  },
  latestSavedSubmission: LatestSubmissionPayload,
  questions: Array<QuestionPayload>
) {
  const answerStates = latestSavedSubmission?.answer_states;
  if (!answerStates) {
    return true;
  }
  return (
    submission.difficultyRating !== latestSavedSubmission?.difficulty_rating ||
    submission.feedback !== latestSavedSubmission?.feedback ||
    !!questions.find((question) => {
      if (
        !answerStates[question.slug] ||
        !submission.questionSlugToAnswer[question.slug]
      ) {
        return true;
      }
      const answerIsDifferent =
        answerStates[question.slug].value !==
        submission.questionSlugToAnswer[question.slug].value;
      const codeIsDifferent =
        answerStates[question.slug].working_code !==
        submission.questionSlugToAnswer[question.slug].working_code;
      return answerIsDifferent || codeIsDifferent;
    })
  );
}
