import styled from 'styled-components';
import FullScreenLoader from './Loader/FullScreenLoader';
import FullScreenAlert from './Alert/FullScreenAlert';

const PageWithAsyncDataWrapper = styled.div`
  min-height: 100vh;

  animation: fadeIn 1s ease-in-out forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  background-color: ${({ theme }) => theme.colors.bgPage};
`;

export default function PageWithAsyncData({
  error,
  isLoading,
  retry,
  children,
  className,
}) {
  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (error) {
    // special handling for axios errors
    const text = error.response
      ? `${error.response.status} | ${error.response.data.message}`
      : error.message;

    return <FullScreenAlert onRetry={retry} text={text} />;
  }

  return (
    <PageWithAsyncDataWrapper className={className}>
      {children}
    </PageWithAsyncDataWrapper>
  );
}
