import { useParams } from 'react-router-dom';
import DashboardPage from '../../components/DashboardPage';
import useAuth from '../../hooks/use-auth';
import { useTeamsDashboard, useProjectInfo } from '../../hooks/use-hydra';
import { getActivityUrl as _getActivityUrl } from '../../routing';

export default function Dashboard() {
  const { teamSlug } =
    useParams<{
      teamSlug: string;
    }>();

  const dashboardData = useTeamsDashboard(teamSlug);
  const { user } = useAuth();
  const { value: projectInfo } = useProjectInfo(teamSlug);

  const getActivityUrl = (
    mission: {
      slug: string;
    },
    activity: {
      slug: string;
    }
  ) => _getActivityUrl(teamSlug, mission.slug, activity.slug);

  return (
    <DashboardPage
      user={user}
      statsState={dashboardData}
      getActivityUrl={getActivityUrl}
      projectInfo={projectInfo ?? undefined}
    />
  );
}
