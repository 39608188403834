import React from 'react';
import { createPortal } from 'react-dom';
import { useLockBodyScroll } from 'react-use';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  z-index: 3;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;

  background-color: ${prop('theme.colors.bgPage')};
`;

const AnimWrapper = styled.div`
  height: 100%;
  width: 100%;
  .fade-in {
    animation: fadeIn ease 1.5s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-out {
    animation: fadeOut ease 1.5s;
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  display: inline-block;
  width: 100%;
  height: 100%;
`;

// TODO: mobile layout

export const FadeInWrapper = ({ children }) => {
  const [animClass, setAnimClass] = React.useState('fade-in');

  React.useEffect(() => {
    setAnimClass('fade-in');
    return () => {
      setAnimClass('fade-out');
    };
  }, []);

  return (
    <AnimWrapper>
      <ContentWrapper className={animClass}>{children}</ContentWrapper>
    </AnimWrapper>
  );
};

const modalRoot = document.getElementById('root');

const Overlay = ({ children, shouldLockBody = true }) => {
  const modalMountRef = React.useRef(null);

  if (!modalMountRef.current) {
    modalMountRef.current = document.createElement('div');
  }

  React.useEffect(() => {
    if (modalRoot && modalMountRef.current) {
      modalRoot.prepend(modalMountRef.current);
    }
    return () => {
      if (modalRoot && modalMountRef.current) {
        modalRoot.removeChild(modalMountRef.current);
      }
    };
  }, []);

  useLockBodyScroll(shouldLockBody);

  return createPortal(
    <ModalWrapper>
      <FadeInWrapper>{children}</FadeInWrapper>
    </ModalWrapper>,
    modalMountRef.current
  );
};

export default Overlay;
