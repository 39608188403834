import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import styled, { useTheme } from 'styled-components';
import useAuth from '../../hooks/use-auth';
import Button from '../elements/Button';
import { Input } from '../elements/Input';
import { H2, LabelUppercase, P, PSmall } from '../elements/Text';
import * as CrashReporter from '../../crash-reporter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};

  h1 {
    margin-top: 0;
  }
  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid ${({ theme }) => theme.colors.transparentBg};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.text};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-direction: column;

  button {
    flex: 1;
    width: 100%;
  }
`;

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 4px;
`;

const Dash = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.divider};
`;

const Divider = () => (
  <DividerWrapper>
    <Dash />
    <LabelUppercase style={{ marginTop: 0 }}>OR</LabelUppercase>
    <Dash />
  </DividerWrapper>
);

const GoogleButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isLoading: boolean;
  isDisabled: boolean;
}) => {
  const theme = useTheme();
  return (
    <Button
      type="primary"
      isLoading={isLoading}
      label="Continue with Google"
      color={theme.colors.text}
      faIcon="fa-brands fa-google"
      showIconBeforeLabel
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
};

const Link = styled.a<{
  href: string;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin-top: 16px;
  /* identical to box height, or 133% */
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.textRegular};

  cursor: pointer;
`;

export function Login({
  canEditEmail = true,
  onEmailChange,
  email,
  title,
  subtitle,
  redirectUri,
  returnTo,
  goToConfirmEmail,
  shouldShowSignupLink = false,
}: {
  title: string;
  subtitle: string;
  redirectUri: string;
  returnTo?: string;
  email: string;
  goToConfirmEmail: () => void;
  canEditEmail?: boolean;
  onEmailChange?: (value: string) => void;
  shouldShowSignupLink?: boolean;
}) {
  const { passwordlessStart, authWithGoogle } = useAuth();

  const continueWithEmailMutation = useMutation({
    mutationFn: async () => {
      try {
        await passwordlessStart({
          email,
        });
        goToConfirmEmail();
      } catch (error) {
        const errorCode = (error as { code: string })?.code ?? '';
        if (errorCode === 'bad.email') {
          toast.error('Sorry, that email is not valid.');
        } else {
          toast.error('Sorry, something went wrong.');
          CrashReporter.reportError(error as Error);
        }
      }
    },
  });

  const authWithGoogleMutation = useMutation({
    mutationFn: async () => {
      try {
        await authWithGoogle({
          redirectUri,
          returnTo,
        });
      } catch (error) {
        const errorCode = (error as { code: string })?.code ?? '';

        if (errorCode === 'access_denied') {
          toast.error('Sorry, it seems that Google is not working right now.');
        } else if (errorCode === 'password_leaked') {
          toast.error(
            'Your password has been compromised. Please change it by clicking on "Forgot your password?".'
          );
        } else if (errorCode === 'too_many_attempts') {
          toast.error(
            'Slow down. Get in touch with us if you need help logging in'
          );
        } else {
          toast.error('Sorry, something went wrong.');
          CrashReporter.reportError(error as Error);
        }
      }
    },
  });

  return (
    <Wrapper>
      <H2>{title}</H2>
      <P>{subtitle}</P>
      <ContentsWrapper>
        <GoogleButton
          onClick={authWithGoogleMutation.mutate}
          isLoading={authWithGoogleMutation.isLoading}
          isDisabled={continueWithEmailMutation.isLoading}
        />
        <Divider />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => onEmailChange && onEmailChange(e.target.value)}
          isDisabled={
            !canEditEmail ||
            authWithGoogleMutation.isLoading ||
            continueWithEmailMutation.isLoading
          }
        />
        <Button
          label="Continue with Email"
          type="primary"
          onClick={continueWithEmailMutation.mutate}
          isDisabled={!email || authWithGoogleMutation.isLoading}
          isLoading={continueWithEmailMutation.isLoading}
        />
      </ContentsWrapper>
      {shouldShowSignupLink && (
        <PSmall>
          New to Enki? <Link href="/join">Sign up here</Link>.
        </PSmall>
      )}
    </Wrapper>
  );
}
