import React from 'react';
import styled from 'styled-components';
import Button from '../../elements/Button';
import {
  OnboardingContext,
  ONBOARDING_ACTION,
} from '../../../contexts/onboarding';
import useAuth from '../../../hooks/use-auth';
import { updateOnboardingSlackJoin, joinEnkiCommunity } from '../../../hydra';
import { prop } from 'styled-tools';
import Loader from '../../Loader';
import * as CrashReporter from '../../../crash-reporter';
import { H3, H5, P } from '../../elements/Text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};

  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: -82px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 32px;
`;

const Callout = styled(H5)`
  padding: 16px;
  margin-top: 0;
  background-color: ${prop('theme.colors.transparentBg')};
  border: 1px solid ${prop('theme.colors.transparentBg')};
  border-radius: 8px;
  margin-left: 32px;
`;

export default function SlackOnboarding() {
  const [onboardingState, dispatch] = React.useContext(OnboardingContext);
  const { getTokenSilently } = useAuth();
  const [submitError, setSubmitError] = React.useState(false);
  const [isInviting, setIsInviting] = React.useState(true);

  const [messageIndex, setMessageIndex] = React.useState(0);

  const [loadingMessages] = React.useState(
    [
      'Did you know? A pie chart is called "camembert" in French.',
      "Did you know? If you shuffle a pack of cards properly, the exact order of the cards you'll have in your hands has almost certainly never been seen in the history of the universe before.",
      'Did you know? In a group of 23 people, there’s a 50% chance two of them have the same birthday. It takes 75 people for the probability to increase to 99%.',
      'Did you know? If you fold a piece of paper in half 103 times it would be the thickness of the observable universe.',
      'Did you know? In the next 2 days the world will produce more data than we did from the beginning of human history up to 2003.',
    ].sort(() => 0.5 - Math.random())
  );
  React.useEffect(() => {
    const timer = setInterval(() => {
      setMessageIndex(
        messageIndex < loadingMessages.length - 1 ? messageIndex + 1 : 0
      );
    }, 8000); // update every 8 seconds
    return () => {
      clearInterval(timer);
    };
  }, [messageIndex, loadingMessages]); // Pass in empty array to run effect only once!

  const submitConfirmation = React.useCallback(
    async (token) => {
      await updateOnboardingSlackJoin(token, {
        trackId: onboardingState.track.trackId,
        didJoinSlack: true,
      }).catch((err) => {
        CrashReporter.reportError(err);
        setSubmitError(true);
      });
    },
    [onboardingState.track.trackId]
  );

  const joinCommunity = React.useCallback(async () => {
    const token = await getTokenSilently();

    await joinEnkiCommunity(token, { source: 'track-onboarding' })
      .catch((err) => {
        CrashReporter.reportError(err);
        setSubmitError(true);
      })
      .then(async () => {
        await submitConfirmation(token);
      })
      .then(async () => {
        setIsInviting(false);
      });
  }, [getTokenSilently, submitConfirmation]);

  React.useEffect(() => {
    joinCommunity();
  }, [joinCommunity]);

  const onConfirmSlackJoin = async () => {
    dispatch({
      type: ONBOARDING_ACTION.TRACK.CONFIRM_JOIN_SLACK,
    });
  };

  if (submitError) {
    return (
      <Wrapper>
        <H3>Something went wrong.</H3>
        <P>Please reach out to us to get this fixed as soon as possible</P>
        <Button
          style={{ width: 'fit-content' }}
          label="Get in touch"
          onClick={() => window.open('mailto:concierge@enki.com')}
          faIcon={'fas fa-envelope'}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isInviting ? (
        <>
          <H3>Almost there!</H3>
          <P>
            Hold tight! We're taking into account your preferences and getting
            everything set up for you. This could take up to 20 seconds...
          </P>
          <Row>
            <div style={{ height: '64px', width: '64px' }}>
              <Loader />
            </div>

            <Callout>{loadingMessages[messageIndex]}</Callout>
          </Row>
        </>
      ) : (
        <>
          <H3>Connect to Slack!</H3>
          <P>
            Everything is now ready!{' '}
            <span role="img" aria-label="hug">
              🎉
            </span>
          </P>
          <P>
            The final step is to{' '}
            <b>
              click the personalized invite link that our assistant enkibot
              emailed you
            </b>{' '}
            to join our Enki workspace on Slack.
          </P>
          <P>
            (If you're already in the{' '}
            <a
              href={'https://enkicamp.slack.com'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Enki workspace
            </a>
            , we upgraded your channels there instead of sending an invite.)
          </P>
          <Button
            label={'Finish & Close'}
            onClick={onConfirmSlackJoin}
            type="secondary"
          />
        </>
      )}
    </Wrapper>
  );
}
