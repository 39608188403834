import React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import useAuth from '../../hooks/use-auth';
import Button from '../elements/Button';
import { Input } from '../elements/Input';
import { H2, P } from '../elements/Text';
import * as CrashReporter from '../../crash-reporter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};

  h1 {
    margin-top: 0;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-direction: column;

  button {
    flex: 1;
    width: 100%;
  }
`;

export default function ConfirmEmail({
  email,
  redirectUri,
  returnTo,
}: {
  email: string;
  redirectUri: string;
  returnTo?: string;
}) {
  const { passwordlessLogin } = useAuth();
  const [passwordlessCode, setPasswordlessCode] = React.useState('');
  const submitCodeMutation = useMutation({
    mutationFn: async () => {
      try {
        await passwordlessLogin({
          email,
          code: passwordlessCode,
          redirectUri,
          returnTo,
        });
      } catch (error) {
        const errorCode = (error as { code: string })?.code ?? '';

        if (errorCode === 'access_denied') {
          toast.error('Sorry, this code is not valid.');
        } else {
          toast.error('Sorry, something went wrong.');
          CrashReporter.reportError(error as Error);
        }
      }
    },
  });

  return (
    <Wrapper>
      <H2>Check your email for a code</H2>
      <P>
        We sent a code to <b>{email}</b>. The code expires shortly, so please
        enter it soon.
      </P>
      <ContentsWrapper>
        <Input
          autoComplete="one-time-code"
          type="text"
          placeholder="Code"
          value={passwordlessCode}
          onChange={(e) => setPasswordlessCode(e.target.value)}
        />
        <Button
          label="Continue"
          type="primary"
          onClick={submitCodeMutation.mutate}
          isLoading={submitCodeMutation.isLoading}
        />
      </ContentsWrapper>
    </Wrapper>
  );
}
