import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react';

const {
  REACT_APP_ENV = 'development',
  REACT_APP_BUGSNAG_API_KEY = 'dummy',
  REACT_APP_VERSION = 'unspecified',
} = process.env;

const IS_DEV = REACT_APP_ENV === 'development';

if (!IS_DEV) {
  Bugsnag.start({
    apiKey: REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: REACT_APP_VERSION,
    releaseStage: REACT_APP_ENV,
  });
} else {
  console.log(`Skipping CrashReporter init as environment = ${REACT_APP_ENV}`);
}

export function setUser({
  id,
  email,
  name,
}: {
  id: number;
  email: string | null;
  name: string | null;
}) {
  if (!IS_DEV) {
    Bugsnag.setUser(id.toString(), email ?? '', name ?? '');
  } else {
    console.log(
      `Skipping CrashReporter setUser as environment = ${REACT_APP_ENV}`
    );
  }
}

export function reportError(error: Error) {
  if (!IS_DEV) {
    Bugsnag.notify(error);
  } else {
    console.error(error);
    console.log(
      `Skipping CrashReporter reportError as environment = ${REACT_APP_ENV}`
    );
  }
}

export enum BREADCRUMB_TYPE {
  LOG = 'log',
  NAVIGATION = 'navigation',
  REQUEST = 'request',
  PROCESS = 'process',
  STATE = 'state',
  USER = 'user',
  ERROR = 'error',
  MANUAL = 'manual',
}

export function addBreadcrumb({
  message,
  metadata = {},
  type = BREADCRUMB_TYPE.LOG,
}: {
  message: string;
  metadata?: Record<string, unknown>;
  type?: BREADCRUMB_TYPE;
}) {
  if (!IS_DEV) {
    Bugsnag.leaveBreadcrumb(message, metadata, type);
  } else {
    console.log(
      `Skipping CrashReporter addBreadcrumb ${message} as environment = ${REACT_APP_ENV}`
    );
  }
}

export const ErrorBoundary = IS_DEV
  ? ({ children }: { children: React.ReactNode }) => <>{children}</>
  : (
      Bugsnag.getPlugin('react') as BugsnagPluginReactResult
    ).createErrorBoundary(React);
