import styled from 'styled-components';

const AlertWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: ${({ theme, type }) => {
    if (type === 'success') {
      return theme?.colors?.bgDefault ?? '#383d55';
    }
    return theme?.colors?.text ?? '#ffffff';
  }};
  background-color: ${({ theme, type }) => {
    if (type === 'success') {
      return theme?.colors?.primary ?? '#70ffbc';
    }
    if (type === 'error') {
      return theme?.colors?.textDanger ?? '#E54073';
    }
    return theme?.colors?.bgPage ?? '#2e334d';
  }};
  padding: 10px;
  border-radius: 8px;

  > i.fa {
    margin: 5px;
  }
`;

const AlertText = styled.div`
  padding-left: 5px;
`;

export default function Alert({ type, text, ...props }) {
  return (
    <AlertWrapper type={type} {...props}>
      <AlertIcon type={type} /> <AlertText>{text}</AlertText>
    </AlertWrapper>
  );
}

function AlertIcon({ type }) {
  return <i className={typeToIcon(type)} />;
}

function typeToIcon(type) {
  switch (type) {
    case 'info':
      return 'info-circle';
    case 'error':
      return 'exclamation-triangle';
    case 'success':
      return 'check-circle';
    default:
      return 'info-circle';
  }
}
